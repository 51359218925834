// angular modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

// analytics
import { Angulartics2Module } from 'angulartics2';

import { BcAuthWebModule } from '@blancoservices/bc-auth-web';

// app modules
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './routing.module';
import { AuthModule } from './auth/auth.module';

import { BcSelectModule, IframeResizerModule,
  BcPhoneInputModule, BcCatchMessagesModule, BcMessagesModule, BcFocusModule, BcSpinnerModule } from './widgets';

import { AppComponent } from './app.component';
import { TranslationsModule } from './translations/translations.module';
import { CognitoInterceptor } from './core/common/cognito.interceptor';
import { SoftwareMfaModule } from './core/components/software-mfa/software-mfa.module';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,

    TranslationsModule,
    BcCatchMessagesModule,
    BcMessagesModule,
    BcFocusModule,
    BcSpinnerModule,

    BcSelectModule,
    BcPhoneInputModule,
    IframeResizerModule,
    BcAuthWebModule.forRoot(environment),

    AuthModule,

    AppRoutingModule,

    SoftwareMfaModule,

    ToastrModule.forRoot({
      timeOut: 7000,
      closeButton: true
    }),
    Angulartics2Module.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CognitoInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {  }
