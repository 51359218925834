<div class="container">
  <h1 class="h1-header h1-header_light text-center">
    <ng-container i18n="@@newOnboardingQuestion">How would you like to become a client?</ng-container>
  </h1>
  <div class="row content_row">
    <p class="login-text">
      <ng-container i18n="@@isUserExist">Do you already have a user? </ng-container>
      <a class="u-unstyled-btn bc-btn-link" routerLink='/login' i18n="@@logIn">Login</a>
    </p>
  </div>

  <div class="container">
    <div class="row content_row accounts_row">
      <div class="col-sm-3" *ngIf="individualAvailable" (click)="chooseTypeOnBlockClick('INDIVIDUAL')">
        <div class="account-type_block">
          <svg class="svg-icon">
            <use xlink:href="#np-icon"></use>
          </svg>
          <h3 class="account-type_header h2-header h2-header_charcoal h2-header_light" i18n="@@asAnIndividual">As an individual</h3>
          <button class="account-type_btn btn bc-btn bc-btn_custom" type="button" data-tests-id="individual-button-registration"
                  (click)="chooseType('INDIVIDUAL')" i18n>
            Start registration
          </button>
        </div>
      </div>
      <div class="col-sm-3" *ngIf="jointAvailable" (click)="chooseTypeOnBlockClick('JOINT')">
        <div class="account-type_block">
          <svg class="svg-icon">
            <use xlink:href="#npg-icon"></use>
          </svg>
          <h3 class="account-type_header h2-header h2-header_charcoal h2-header_light" i18n="@@asPartners">As partners</h3>
          <button class="account-type_btn btn bc-btn bc-btn_custom" type="button" data-tests-id="joint-button-registration"
                  (click)="chooseType('JOINT')" i18n>
            Start registration
          </button>
        </div>
      </div>
      <div class="col-sm-3" *ngIf="childAvailable" (click)="chooseTypeOnBlockClick('CHILD')">
        <div class="account-type_block">
          <svg class="svg-icon">
            <use xlink:href="#new-child-icon"></use>
          </svg>
          <h3 class="account-type_header h2-header h2-header_charcoal h2-header_light" i18n="@@forAChild">For a child</h3>
          <button class="account-type_btn btn bc-btn bc-btn_custom" type="button" data-tests-id="child-button-registration"
                  (click)="chooseType('CHILD')" i18n>
            Start registration
          </button>
        </div>
      </div>
      <div class="col-sm-3" *ngIf="businessAvailable" (click)="chooseTypeOnBlockClick('BUSINESS')">
        <div class="account-type_block">
          <svg class="svg-icon">
            <use xlink:href="#le-icon-big"></use>
          </svg>
          <h3 class="account-type_header h2-header h2-header_charcoal h2-header_light" i18n="@@asALE">As a legal entity</h3>
          <button class="account-type_btn btn bc-btn bc-btn_custom" type="button" data-tests-id="business-button-registration"
                  (click)="chooseType('BUSINESS')" i18n>
            Start registration
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
