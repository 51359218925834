import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SettingsService, UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class MfaSetupGuard  {

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = this.userService.user.value;
    const allowedMFAMethods = this.settingsService.settings.authentication?.allowedMFAMethods;

    if (!allowedMFAMethods?.length || allowedMFAMethods.includes(user.mfaMethod)) return Promise.resolve(true);

    this.router.navigate(['mfa-setup']);

    return Promise.resolve(false);
  }
}
