import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SignupService } from '../signup.service';

import { TitleService, TenantService } from '../../core/services';

import { RegistrationTypes, RegistrationSubTypes } from '../../shared/models/registrationType.enum';
import { PublicTenantInfo } from '../../core/services/tenant.service';


@Component({
  selector: 'bc-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent implements OnInit {
  tenant: string;
  INDIVIDUAL = false;
  JOINT = false;
  BUSINESS = false;
  allowedAccountTypes = [];

  get isSmallScreen() {
    const mobileWidth = 768;

    return window.innerWidth < mobileWidth;
  }

  get individualAvailable() {
    return this.allowedAccountTypes.includes(RegistrationTypes.INDIVIDUAL);
  }

  get childAvailable() {
    return this.allowedAccountTypes.includes(RegistrationSubTypes.CHILD);
  }

  get jointAvailable() {
    return this.allowedAccountTypes.includes(RegistrationTypes.JOINT);
  }

  get businessAvailable() {
    return this.allowedAccountTypes.includes(RegistrationTypes.BUSINESS);
  }

  constructor(
    private titleService: TitleService,
    private tenantService: TenantService,
    private signupService: SignupService,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleService.setTitle('register');
    this.tenantService.resolve().then((tenantData: PublicTenantInfo) => {
      this.checkTypes(tenantData.allowedRegistrationTypes);
      this.tenant = tenantData.tenantName;
    });
  }

  checkTypes(registrationTypes: Record<RegistrationTypes | RegistrationSubTypes, boolean>) {
    Object.keys(registrationTypes).map(key => {
      if (registrationTypes[key]) this.allowedAccountTypes.push(key);
    });

    if (this.allowedAccountTypes.length === 1) {
      this.chooseType(<RegistrationType> this.allowedAccountTypes[0]);
    }
  }

  chooseType(registrationType: RegistrationType | RegistrationSubType) {
    this.signupService.registrationType = registrationType;
    this.router.navigate(['signup']);
  }

  chooseTypeOnBlockClick(registrationType: RegistrationType) {
    if (this.isSmallScreen) this.chooseType(registrationType);
  }
}
