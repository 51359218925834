/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { TokenHelper, UsersGatewayService } from '@blancoservices/bc-auth-web';

import { RegistrationService } from './registration.service';
import { Logger, UserService } from '.';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {
  static savedCognitoSession: any;
  private static previousUrl = '';
  private static defaultMainPage = 'dashboard';

  constructor(
    private logger: Logger,
    private router: Router,
    private usersGateway: UsersGatewayService,
    private registrationService: RegistrationService,
    private userService: UserService
  ) { }


  static getSavedUserSession() {
    return UserLoginService.savedCognitoSession;
  }

  static setSavedUserSession(userSession: any) {
    UserLoginService.savedCognitoSession = userSession;
  }

  static setPreviousUrl(url: string): void {
    this.previousUrl = url;
  }

  static redirectUser(): string {
    let redirectUrl: string = this.previousUrl;

    if (redirectUrl.length === 0) {
      redirectUrl = this.defaultMainPage;
    }

    this.setPreviousUrl('');

    return redirectUrl;
  }

  static redirectClients(idTokenPayload, refreshToken) {
      const groups = idTokenPayload['cognito:groups'];

      if (groups && groups.includes('ClientsGroup')) {
        // eslint-disable-next-line no-magic-numbers
        const oneHourLater = (new Date(Date.now() + 3600000)).toUTCString();

        document.cookie = `rt=${refreshToken}; expires=${oneHourLater}; domain=${environment.redirectTo.clientPortal}`;
        document.cookie = `at=${TokenHelper.getAccessToken()}; expires=${oneHourLater}; domain=${environment.redirectTo.clientPortal}`;
        document.cookie = `n=${idTokenPayload['cognito:username']}; expires=${oneHourLater}; domain=${environment.redirectTo.clientPortal}`;

        fetch(`${environment.BASE_URL_BGC}/whitelabel`, {
          headers: {
            Authorization: TokenHelper.getAccessToken()
          }
        })
          .then(res => res.json())
          .catch(error => {
            localStorage.clear();
            window.location.assign(`https://portal${environment.redirectTo.clientPortal}/continue`);
          })
          .then( (res: any) => {
            let portalUrl: string = res.default['BCMD'];

            if (res.hasOwnProperty('domains') && res.domains.length) {
              const domainSettings: any = res.domains.find((domain: any) => domain.application === 'BCMD');
              if (domainSettings) portalUrl = domainSettings.domain;
            }

            localStorage.clear();
            window.location.assign(`https://${portalUrl}/continue`);
          });

        return true;
      }
  }

  logout(): Observable<any> {
    return this.usersGateway.globalSignOut()
      .pipe(
        catchError((response: HttpErrorResponse) => {
          this.logger.showError(response.error);

          return throwError(response);
        }),
        tap(() => this.clearDataAfterLogout())
      );
  }

  clearDataAfterLogout() {
    TokenHelper.clearLocalStorage();
    this.userService.clear();
    this.registrationService.rootClear();
    if (this.userService.username.getValue()) {
      this.userService.username.next('');
    }
  }
}
