export const findGetParameter = (parameterName: string): string => {
  let result: any,
      tmp: Array<any> = [];

  location.search
    .substr(1)
    .split('&')
    .forEach((item: any) => {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
};

export const capitalize = (value: string): string => {
  return value.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const cleanBankAccount = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9]/g, '');
};

import { Injectable } from '@angular/core';

export interface Attribute {
  Name: string;
  Value: string;
}

@Injectable()
export class Helpers {

  static roles = [
    {
      name: 'ROLE_SYSTEM_ADMIN',
      value: 'System Admin'
    },
    {
      name: 'ROLE_TENANT_ADMIN',
      value: 'Admin'
    },
    {
      name: 'ROLE_TENANT_USER',
      value: 'User'
    },
    {
      name: 'ROLE_TENANT_AGENT',
      value: 'Agent'
    }
  ];

  static parseAttributes(attributes: Array<Attribute>): any {
    const parsed = {};

    attributes.forEach((item) => {
      parsed[item.Name] = item.Value;
    });

    return parsed;
  }

  static parseOneAttribute(attribute: Attribute): any {
    return {[attribute.Name]: attribute.Value};
  }

  static parseRole(profile: string): string {
    let index = -1;
    let i = 0;
    const profilesNumber = 3;
    index = profile.indexOf(this.roles[i].name);

    while (index === -1 && i < profilesNumber) {
      i++;
      index = profile.indexOf(this.roles[i].name);
    }

    return (index === -1) ? 'not specified' : this.roles[i].value;
  }

  static parseRoleReverted(profile: string): string {
    return this.roles.find((role: any) => role.value === profile).name;
  }

  static cleanObj(obj: any) {
    const keyArr: Array<any> = Object.keys(obj),
      outputArray: any = {};

    keyArr.forEach((key: any) => {
      if (obj[key]) outputArray[key] = obj[key];
    });

    return outputArray;
  }

  static cleanNonAlphaNumericValues(value: string = ''): string {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  }

  static replacePageWithOffset(obj: any) {
    const keyArr: Array<any> = Object.keys(obj),
      outputArray: any = {};

    keyArr.forEach((key: any) => {
      if (key === 'page') {
        outputArray.offset = this.convertPageToOffset(obj.page, obj.max);
      } else if (obj[key]) outputArray[key] = obj[key];
    });

    return outputArray;
  }

  static convertPageToOffset(page: number, max: number) {
    return (page - 1) * max;
  }

  static parseAbbyDate = (date: string): [number, string] => {
    /* eslint-disable no-magic-numbers */
    const result = `${date.slice(0, 2)}-${date.slice(-4, -2)}-${date.slice(-2)}`;

    return [parseInt(date.slice(0, 2), 10), result];
    /* eslint-enable no-magic-numbers */
  };

  static capitalize = (value: string): string => {
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  static patchValues = (localeId: string, text: string, valuesArray: Array<{format: string; value: any}>): string => {
    const curlyBracesStringLength = 2;
    let propertyName: string = text.substring(text.indexOf('{{') + curlyBracesStringLength, text.indexOf('}}'));
    let stepper = 10;

    while (propertyName) {
      if (valuesArray[propertyName] && valuesArray[propertyName].value !== null) {
        let value = '';
        switch (valuesArray[propertyName].format) {
          case 'percentage':
            value = `${valuesArray[propertyName].value}%`;
            break;

          case 'currency':
            value = valuesArray[propertyName].value.toLocaleString(localeId, {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0
            });
            break;

          default:
            value = valuesArray[propertyName].value;
        }
        text = text.replace(`{{${propertyName}}}`, `<span>${value}</span>`);
      } else {
        text = text.replace(`{{${propertyName}}}`, '');
      }

      propertyName = text.substring(text.indexOf('{{') + curlyBracesStringLength, text.indexOf('}}'));
      stepper --;
      if (!stepper) {
        break;
      }
    }

    return text.replace(/\n/g, '<br>');
  };

  static parseStreet(streetValue: string) {
    if (!streetValue) return ['', ''];
    try {
      const [, line1String, houseNumber] = /(.+?)\s+?(\d*\s*[\d\D\s]{0,5})$/.exec(streetValue);

      return [houseNumber.trim(), line1String.trim()];
    } catch (e) {
      return ['', streetValue];
    }
  }

  static formatDate(date) {
    const d = new Date(date),
      year = d.getFullYear();
    let month = (d.getMonth() + 1).toString(),
      day = d.getDate().toString();

    // eslint-disable-next-line no-magic-numbers
    if (month.length < 2) {
      month = `0${month}`;
    }
    // eslint-disable-next-line no-magic-numbers
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  static mapLegalActivities(formattedLegalActivities: Array<string>, unformattedLegalActivities?: Array<string>): Array<LegalActivity> {
    if (!formattedLegalActivities || !formattedLegalActivities.length) return [];

    return formattedLegalActivities.map((activityCode: string, index: number) => ({
      activityCode,
      unformattedActivityCode: unformattedLegalActivities[index]
    } as LegalActivity));
  }
}
