import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SignupService } from '../signup.service';

import { TenantService } from '../../core/services';
import { PublicTenantInfo } from '../../core/services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeGuard  {
  allowedAccountTypes = [];

  constructor(private tenantService: TenantService,
    private router: Router,
    private signupService: SignupService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.tenantService.resolve().then((tenantData: PublicTenantInfo) => {
      Object.keys(tenantData.allowedRegistrationTypes).map(key => {
        if (tenantData.allowedRegistrationTypes[key]) this.allowedAccountTypes.push(key);
      });

      if (this.allowedAccountTypes.length === 1) {
        this.signupService.registrationType = <RegistrationType> this.allowedAccountTypes[0];

        return this.router.parseUrl('/signup');
      }

      return true;
    });
  }

}
