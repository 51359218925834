import { Injectable } from '@angular/core';
import { IntegrationsSettingsService } from './integrations-settings.service';
import { findGetParameter } from '../../shared/helpers/helpers';
import { GTMService } from './gtm.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(
    private settingsService: IntegrationsSettingsService,
    private gtmService: GTMService
  ) { }

  start() {
    const tenantId = findGetParameter('tenant_id');
    const storedTenantId = sessionStorage.getItem('tenant_id');

    if (storedTenantId || tenantId) {
      this.settingsService.get(tenantId || storedTenantId).subscribe((settings: any) => {
        this.startgGTM(settings.gtm);
      });
    }
  }

  private startgGTM(settings) {
    if (settings && settings.enabled) {
      this.gtmService.load(settings);
    }
  }
}
