import { Injectable, Inject } from '@angular/core';
import { LOCALES_MANAGER } from '../../translations/translation.injectors';
import { BcMessagesManager } from '../../widgets';
import { LOCALES } from '../../shared/models/locales.enum';

export enum ADDRESS_FORMAT { NL = 'nl', INTERNATIONAL = 'international' }

export interface Locale {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocalesService {

  private _locales: Array<Locale> = [
    { value: LOCALES.EN_US, name: 'English' },
    { value: LOCALES.NL_NL, name: 'Dutch' },
    { value: LOCALES.FR_FR, name: 'French' },
    { value: LOCALES.DE_DE, name: 'German' }
  ];

  constructor(@Inject(LOCALES_MANAGER) public bcTranslationsMessagesManager: BcMessagesManager) {}

  get locales() {
    return this._locales
      .map(locale => ({
        ...locale,
        name: this.bcTranslationsMessagesManager.translations[locale.value]
      }));
  }

  getAllowedLocales(allowedLocalesSettings: Array<string>) {
    return this.locales.filter(locale =>
      allowedLocalesSettings.some(c => c === locale.value)
    );
  }
}
