import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MasterGuard, GUARDS } from '../core/guards';

import { LoginComponent } from './login/login.component';
import { RestorePassComponent } from './restorepass/restorepass.component';
import { RestorePass2Component } from './restorepass/restorepass2.component';
import { ConfirmCodeComponent } from './confirm/confirm.component';
import { AccountTypeComponent } from './account-type/account-type.component';
import { ContinueComponent } from './continue/continue.component';

import { AccountTypeGuard } from './account-type/account-type.guard';

export const authRoutes: Routes = [
  { path: '', redirectTo: 'account-type', pathMatch: 'full' },
  {
    path: '', canActivate: [MasterGuard],
    children: [
      { path: 'login', component: LoginComponent},
      { path: 'confirm', component: ConfirmCodeComponent},
      { path: 'restorePassword/:username', component: RestorePass2Component},
      { path: 'restorePassword', component: RestorePassComponent},
      { path: 'continue', component: ContinueComponent},
      { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)},
      { path: 'invite', loadChildren: () => import('./user-invite/user-invite.module').then(m => m.UserInviteModule)},
      { path: 'account-type', component: AccountTypeComponent, canActivate: [AccountTypeGuard] },
    ],
    data: {
      guards: [
        GUARDS.TENANTGUARD,
        GUARDS.UNAUTHGUARD
      ]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }
