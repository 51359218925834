export class Part {
  /* eslint-disable */
  _id?: string;
  /* eslint-enable */
  naturalPerson: NaturalPerson | null;
  legalEntity: LegalEntity | null;
  parties: Array<Part | null>;
  documents: Array<Document | null>;
  files?: Array<File | null>;
  filled?: boolean;
  ownershipPercentage?: any;

  constructor() {
    this.naturalPerson = null;
    this.legalEntity = null;
    this.parties = [];
    this.ownershipPercentage = 0;
    this.documents = [];
    this.files = [];
  }

  addDocument(document: Document) {
    this.documents.push(document);
  }

  addPart(item: LegalEntity | NaturalPerson) {
    this.parties.push(new Part());
  }
}
