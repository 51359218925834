import { InjectionToken } from '@angular/core';
import { BcMessagesManager } from '../widgets';

export const COUNTRIES_MESSAGES_MANAGER = new InjectionToken<BcMessagesManager>('countries messages');
export const TRANSLATIONS_HELPER_MANAGER = new InjectionToken<BcMessagesManager>('translations helper');
export const LEI_STATUS_TRANSLATIONS_MANAGER = new InjectionToken<BcMessagesManager>('lei status helper');
export const DX_MESSAGES_MANAGER = new InjectionToken<BcMessagesManager>('dx messages');
export const BINCKBANK_TRANSLATIONS_MANAGER = new InjectionToken<BcMessagesManager>('binckbank messages');
export const BANK_ACCOUNT_TYPES_TRANSLATIONS_MANAGER = new InjectionToken<BcMessagesManager>('bank account types messages');
export const LOCALES_MANAGER = new InjectionToken<BcMessagesManager>('locales messages',
  { providedIn: 'root', factory: () => new BcMessagesManager() });
