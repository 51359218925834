import { NgModule } from '@angular/core';

import { BcDropdownDirective } from './bc.dropdown.directive';

@NgModule({
  imports: [],
  declarations: [
    BcDropdownDirective
  ],
  providers: [],
  exports: [
    BcDropdownDirective
  ]
})
export class BcDropdownModule { }
