import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Logger, TitleService } from '../../core/services';
import { RestorePassService } from './restorepass.service';
import { BcSpinnerService } from '../../widgets';
import { SignupService } from '../signup.service';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, EMPTY } from 'rxjs';
import { DeliveryMethod } from '../../shared/models/deliveryMethod.enum';

@Component({
  selector: 'bc-restorepass-component',
  templateUrl: 'restorepass.component.html',
  providers: [ RestorePassService ]
})
export class RestorePassComponent implements OnInit {
  currentYear: number;
  restorePasswordForm: UntypedFormGroup;

  constructor(
              private titleService: TitleService,
              private signupService: SignupService,
              public router: Router,
              private toastr: ToastrService,
              private usersGateway: UsersGatewayService,
              private bcSpinnerService: BcSpinnerService,
              private logger: Logger) {
    this.currentYear = new Date().getFullYear();
    this.restorePasswordForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    this.titleService.setTitle('login');
  }

  onSubmit(form: UntypedFormGroup) {
    this.bcSpinnerService.set(true);

    this.usersGateway.forgotPasswordInit({ userName: form.value.username})
    .pipe(catchError((response: HttpErrorResponse) => {

      const NOT_ALLOWED_STATUS = 403;
      if (response.status === NOT_ALLOWED_STATUS) {
        this.bcSpinnerService.set(false);
        this.restorePasswordForm.controls['username'].setErrors({cantResetPassword: true});

        return EMPTY;
      }

      const message = response.error.errorMessage;
      if (message.includes(`User password cannot be reset in the current state.`)) {
        return this.resendTemporaryCredentials(form);
      } else if (message.includes('Attempt limit exceeded, please wait and try again later.')) {
        this.bcSpinnerService.set(false);
        this.restorePasswordForm.controls['username'].setErrors({attemptsLimit: true});
      } else {
        this.bcSpinnerService.set(false);
        this.restorePasswordForm.controls['username'].setErrors({cantResetPassword: true});
        this.logger.showError(message);
      }

      return throwError(response);
    }))
    .subscribe((result: any) => {
      this.bcSpinnerService.set(false);

      const username = form.value.username;

      if (result?.message === 'Temporary password sent') {
        this.toastr.info($localize`:@@tempPasswordSent:A temporary password has been sent to your email address.`);
        this.router.navigate(['/login'], { queryParams: { username: username}});

        return;
      }

      sessionStorage.setItem('username', username);
      RestorePassService.codeDeliveryDetails = result.CodeDeliveryDetails || {};

      if (result.CodeDeliveryDetails?.DeliveryMedium === DeliveryMethod.Email) {
        this.toastr.info($localize`:@@newCodeSentEmail:A verification code has been sent to your email address.`);
      } else if (result.CodeDeliveryDetails?.DeliveryMedium === DeliveryMethod.Sms) {
        this.toastr.info($localize`:@@newCodeSentSMS:A verification code has been sent to your phone number.`);
      }

      this.router.navigate(['/restorePassword', username]);
    });
  }

  resendTemporaryCredentials(form: UntypedFormGroup) {
    sessionStorage.setItem('username', form.value.username);

    return this.signupService.signUp({
      email: form.value.username,
      roles: 'ROLE_TENANT_CLIENT'
    })
      .then(() => {
        this.bcSpinnerService.set(false);

        this.toastr.info($localize`:@@tempPasswordSent:A temporary password has been sent to your email address.`);
        this.router.navigate(['/login'], {
          queryParams: { userEmail: form.value.email }
        });
      })
      .catch((error) => {
        this.bcSpinnerService.set(false);

        this.logger.showError(error.error);
      });
  }
}
