import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService, RegistrationService, SettingsService, TenantService, SurveysService, Logger } from '../services';
import { ProcessorsService } from '../../custodian/processors.service';

import { AuthenticatedGuard } from './authenticated.guard';
import { UnauthenticatedGuard } from './unauthenticated.guard';
import { RegistrationStatusGuard } from './reg-status.guard';
import { RegIncompleteGuard } from './reg-incompleted.guard';
import { KycGuard } from './kyc.guard';
import { SurveyGuard } from './survey.guard';
import { SigningGuard } from './signing.guard';
import { CustodianGuard } from './custodian.guard';
import { ProcessorSurveyGuard } from './processor-survey.guard';
import { TenantGuard } from '../../auth/account-type/tenant.guard';
import { SettingsGuard } from './settings.guard';
import { MfaSetupGuard } from './mfa-setup.guard';
import { EnforcedMfaGuard } from './enforced-mfa.guard';


export enum GUARDS {
  TENANTGUARD = 'TenantGuard',
  AUTHGUARD = 'AuthenticatedGuard',
  UNAUTHGUARD = 'UnauthenticatedGuard',
  REGGUARD = 'RegStatusGuard',
  KYCGUARD = 'KycGuard',
  SURVEYGUARD = 'SurveyGuard',
  SIGNINGGUARD = 'SigningGuard',
  REGINCOMPLETE = 'RegIncompleteGuard',
  PROCESSOR = 'CustodianGuard',
  PROCESSOR_SURVEY = 'ProcessorSurveyGuard',
  SETTINGS = 'SettingsGuard',
  MFA_APP_SETUP = 'MfaSetupGuard',
  ENFORCED_MFA_SETUP = 'EnforcedMfaGuard'
}

type CustomGuard = AuthenticatedGuard
  | TenantGuard
  | UnauthenticatedGuard
  | RegistrationStatusGuard
  | RegIncompleteGuard
  | KycGuard
  | SurveyGuard
  | SigningGuard
  | CustodianGuard
  | ProcessorSurveyGuard
  | SettingsGuard
  | MfaSetupGuard
  | EnforcedMfaGuard;

@Injectable({
  providedIn: 'root'
})
export class MasterGuard  {
  private route: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  constructor(private custodianService: ProcessorsService,
              private registrationService: RegistrationService,
              private userService: UserService,
              private tenantService: TenantService,
              private settingsService: SettingsService,
              private surveysService: SurveysService,
              private logger: Logger,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    this.route = route;
    this.state = state;

    if (!route.data || !this.route.data.guards || !this.route.data.guards.length) return true;

    return this.executeGuards();
  }

  private executeGuards(guardIndex: number = 0): Promise<boolean> {
    return this.activateGuard(this.route.data.guards[guardIndex])
      .then((guardResult) => {
        if (guardResult === false) return false;

        return guardIndex < this.route.data.guards.length - 1 ? this.executeGuards(guardIndex + 1) : true;
      })
      .catch(() => {
        return false;
      });
  }

  private activateGuard(guardKey: string): Promise<boolean> {
    let guard: CustomGuard;
    switch (guardKey) {
      case GUARDS.TENANTGUARD:
        guard = new TenantGuard(this.tenantService, this.logger, this.router);
        break;
      case GUARDS.AUTHGUARD:
        guard = new AuthenticatedGuard(this.userService, this.router);
        break;
      case GUARDS.UNAUTHGUARD:
        guard = new UnauthenticatedGuard(this.router);
        break;
      case GUARDS.REGGUARD:
        guard = new RegistrationStatusGuard(this.registrationService, this.router);
        break;
      case GUARDS.REGINCOMPLETE:
        guard = new RegIncompleteGuard(this.registrationService, this.router);
        break;
      case GUARDS.KYCGUARD:
        guard = new KycGuard(this.registrationService, this.settingsService, this.router);
        break;
      case GUARDS.SURVEYGUARD:
        guard = new SurveyGuard(this.registrationService, this.surveysService, this.router);
        break;
      case GUARDS.SIGNINGGUARD:
        guard = new SigningGuard(this.registrationService, this.settingsService, this.router);
        break;
      case GUARDS.PROCESSOR:
        guard = new CustodianGuard(this.registrationService, this.custodianService, this.router);
        break;
      case GUARDS.PROCESSOR_SURVEY:
        guard = new ProcessorSurveyGuard(
          this.registrationService,
          this.custodianService,
          this.surveysService,
          this.router
        );
        break;
      case GUARDS.SETTINGS:
        guard = new SettingsGuard(this.settingsService, this.router);
        break;
      case GUARDS.MFA_APP_SETUP:
        guard = new MfaSetupGuard(this.userService, this.settingsService, this.router);
        break;
      case GUARDS.ENFORCED_MFA_SETUP:
        guard = new EnforcedMfaGuard(this.userService, this.settingsService, this.router);
        break;
      default:
    }

    return guard.canActivate(this.route, this.state);
  }
}
