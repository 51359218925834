import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcModalComponent } from './bc.modal.component';
import { NewConfirmationModalComponent } from './predefined/confirmation-new/bc.modal.confirmation.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    BcModalComponent,
    NewConfirmationModalComponent
  ],
  exports: [
    BcModalComponent,
    NewConfirmationModalComponent
  ]
})
export class BcModalModule {  }
