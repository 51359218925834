// angular modules
import { NgModule } from '@angular/core';
import { BcMessagesManager, BcCatchMessagesModule } from '../widgets';
import { CountriesTranslationsComponent } from './countries-translations/countries-translations.component';
import {
  COUNTRIES_MESSAGES_MANAGER,
  TRANSLATIONS_HELPER_MANAGER,
  DX_MESSAGES_MANAGER,
  LOCALES_MANAGER,
  LEI_STATUS_TRANSLATIONS_MANAGER,
  BINCKBANK_TRANSLATIONS_MANAGER,
  BANK_ACCOUNT_TYPES_TRANSLATIONS_MANAGER
} from './translation.injectors';
import { TranslationsComponent } from './translations.component';
import { DevexpressTranslationsComponent } from './devexpress-translations/devexpress-translations.component';

@NgModule({
  imports: [
    BcCatchMessagesModule
  ],
  declarations: [
    CountriesTranslationsComponent,
    DevexpressTranslationsComponent,
    TranslationsComponent
  ],
  exports: [
    CountriesTranslationsComponent,
    DevexpressTranslationsComponent,
    TranslationsComponent
  ],
  providers: [
    BcMessagesManager,
    [{ provide: COUNTRIES_MESSAGES_MANAGER, useClass: BcMessagesManager }],
    [{ provide: DX_MESSAGES_MANAGER, useClass: BcMessagesManager }],
    [{ provide: LOCALES_MANAGER, useClass: BcMessagesManager }],
    [{ provide: BINCKBANK_TRANSLATIONS_MANAGER, useClass: BcMessagesManager }],
    [{ provide: LEI_STATUS_TRANSLATIONS_MANAGER, useClass: BcMessagesManager }],
    [{ provide: TRANSLATIONS_HELPER_MANAGER, useClass: BcMessagesManager }],
    [{ provide: BANK_ACCOUNT_TYPES_TRANSLATIONS_MANAGER, useClass: BcMessagesManager }]
  ]
})
export class TranslationsModule {}
