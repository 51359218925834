<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="SUCCESS_PASSWORD_UPDATE" i18n="@@passwordChangeSuccessMessage">Now you can login with the new password</bc-message>

  <bc-message key="SUCCESS_TITLE" i18n="@@success">Success!</bc-message>
</bc-catch-messages>

<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n="@@resetPass">Reset password</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form [formGroup]="restorePasswordForm" class="auth-form" novalidate>

        <div class="field__item">
          <label class="field__label field__label_contacts"
                 for="verification-code" i18n="@@verificationCode">Verification code</label>
          <input #firstCursorField type="text"
                 class="form-control input_field"
                 name="verification-code"
                 id="verification-code"
                 placeholder="6 digits"
                 i18n-placeholder
                 formControlName="verificationCode">
          <bc-messages [element]="restorePasswordForm.get('verificationCode')"></bc-messages>
        </div>

        <span class="auth-form__separator"></span>

        <div class="field__item">
          <label class="field__label field__label_contacts"
                 for="new-password" i18n="@@newPassword">New password</label>
          <div class="input-group">
            <input type="password"
                   class="form-control input_field"
                   name="new-password"
                   id="new-password"
                   formControlName="newPassword"
                   bcShowPassword
                   [showPasswordState]="viewChangePass">
            <div class="input-group-addon" (click)="viewChangePass = !viewChangePass" tabindex="-1">
              <span class="glyphicon"
                    [ngClass]="viewChangePass == true ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"></span>
            </div>
          </div>
          <bc-messages [element]="restorePasswordForm.get('newPassword')"></bc-messages>
        </div>

        <div class="field__item">
          <label class="field__label field__label_contacts"
                 for="confirm-password" i18n="@@confirmNewPassword">Confirm new password</label>
          <div class="input-group">
            <input type="password"
                   class="form-control input_field"
                   name="confirm-password"
                   id="confirm-password"
                   formControlName="confirmPassword"
                   bcShowPassword
                   [showPasswordState]="viewChangeConfPass">
            <div class="input-group-addon" (click)="viewChangeConfPass = !viewChangeConfPass" tabindex="-1">
              <span class="glyphicon"
                    [ngClass]="viewChangeConfPass == true ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"></span>
            </div>
          </div>
          <bc-messages [element]="restorePasswordForm.get('confirmPassword')"></bc-messages>
        </div>

        <span class="auth-form__separator"></span>

        <div class="row auth-form__btn-row">
          <div class="col-sm-6 col-xs-4">
            <a i18n="@@back"
               class="btn bc-btn bc-btn_block bc-btn_default"
               routerLink='/login'>Back</a>
          </div>
          <div class="col-sm-6 col-xs-8">
            <button i18n="@@resetPass"
                    class="btn bc-btn bc-btn_block bc-btn_custom"
                    type="submit"
                    name="submit"
                    (click)="onSubmit(restorePasswordForm)"
                    [disabled]="restorePasswordForm.invalid">Reset password</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
