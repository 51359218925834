<div class="bc-new-modal modal-dialog modal-dialog_small">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n="@@registerAuthApp">Register Authentication application</h3>
    </div>

    <form (ngSubmit)="verifyMfa()" novalidate>

      <div class="modal-body" *ngIf="(qrCode$ | async) as qrCode; else: loadingBlock">

        <qrcode [qrdata]="qrCode" [width]="256" [level]="'M'"></qrcode>

        <p class="main-text" i18n="@@downloadAuthApp">Download an Authenticator app and scan the QR code. You will receive a number in your phone. Please  enter the number below</p>
        <div class="field__item">
          <label for="MFAcode" class="field__label" i18n="@@enterAuthCode">Enter authentication code</label>
          <input type="text" class="form-control" name="MFAcode" id="MFAcode" [formControl]="confirmMFACode">
          <bc-messages [element]="confirmMFACode"></bc-messages>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn bc-btn bc-btn_custom bc-btn_small" i18n="@@confirm" [disabled]="confirmMFACode.invalid" type="submit">Confirm</button>
      </div>
    </form>
  </div>
</div>
<ng-template #loadingBlock> <bc-spinner class="section-spinner"></bc-spinner> </ng-template>
