<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light text-center" i18n="@@registerAuthApp">Register Authentication application</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form (ngSubmit)="submit()" class="auth-form" novalidate>
        <ng-container *ngIf="(qrCode$ | async) as qrCode; else: loadingBlock">
          <p i18n="@@downloadAuthApp">Download an Authenticator app and scan the QR code. You will receive a number in your phone. Please  enter the number below</p>

          <qrcode [qrdata]="qrCode" [width]="256" [level]="'M'"></qrcode>

          <div class="field__item">
            <label for="MFAcode" class="field__label" i18n="@@enterAuthCode">Enter authentication code</label>
            <input type="text" class="form-control" name="MFAcode" id="MFAcode" [formControl]="confirmMFACode">
            <bc-messages [element]="confirmMFACode"></bc-messages>
          </div>
        </ng-container>

        <button class="btn bc-btn bc-btn_block bc-btn_custom" i18n="@@confirm" [disabled]="confirmMFACode.invalid" type="submit" i18n="@@confirm">Confirm</button>
      </form>
    </div>
  </div>
</div>

<ng-template #loadingBlock> <bc-spinner class="content-spinner"></bc-spinner> </ng-template>
