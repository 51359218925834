import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BcMessagesModule, BcMessagesManager,
  BcCatchMessagesModule, BcDropdownModule, BcSpinnerModule } from '../widgets';

import { BcModalModule } from '../widgets/bc.modal/bc.modal.module';

import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';

import { ErrorComponent } from './components/error-page/error-page.component';
import { ThankYouComponent } from './components/thankyou/thankyou.component';
import { AccountDeletedComponent } from './components/account-deleted/account-deleted.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { BcBugSnagModule } from './common/bugsnag/bugsnag.module';
import { DashboardStatusComponent } from './components/dashboard-status/dashboard-status.component';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { LoggedOutComponent } from './components/logged-out-page/logged-out-page.component';
import { RegOriginOfAssetsComponent } from './components/origin-of-assets/reg-origin-of-assets.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    BcBugSnagModule,
    BcModalModule,
    BcCatchMessagesModule,
    BcMessagesModule,
    BcDropdownModule,
    BcSpinnerModule,
    DxFileUploaderModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    ErrorComponent,
    LoggedOutComponent,
    ThankYouComponent,
    AccountDeletedComponent,
    ContactDetailsComponent,
    DocumentsUploadComponent,
    DashboardStatusComponent,
    RegOriginOfAssetsComponent
  ],
  providers: [
    Title,
    BcMessagesManager
  ],
  exports: [
    LayoutComponent,
    ContactDetailsComponent,
    DocumentsUploadComponent,
    DashboardStatusComponent,
    RegOriginOfAssetsComponent
  ]
})
export class CoreModule { }
