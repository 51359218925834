import { RegistrationSubTypes, RegistrationTypes } from '../../shared/models/registrationType.enum';

export const SaxoIndividualAndJointSections = {
  personalDetails: ['married', 'relationship'],
  professionalInformation: ['occupation', 'nameOfEmployer', 'position', 'companyActivity', 'financialSector', 'financialSectorCompanyName'],
  taxInformation: ['USCitizen', 'UStax', 'UStaxFIN'],
  financialInformation: ['netAnnualIncome', 'disposableCapital', 'investIn12Month'],
  originOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions', 'sourceOfWealthSubstantiation']
};

export const SaxoIndividualAndJointSectionsV3 = {
  personalDetails: ['married', 'relationship'],
  professionalInformation: ['occupation', 'nameOfEmployer', 'position', 'companyActivity', 'financialSector', 'financialSectorCompanyName'],
  taxInformation: ['USCitizen', 'UStax', 'UStaxFIN'],
  financialInformation: ['netAnnualIncome', 'disposableCapital', 'investIn12Month'],
  originOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions', 'sourceOfWealthSubstantiation'],
  originOfInvestableAssets: ['sourceOfWealthInvestableAssets', 'sourceOfWealthInvestableAssetsSubQuestions', 'sourceOfWealthInvestableAssetsSubstantiation'],
  permitToShare: ['authoriseSaxoToShare'],
};

export const SaxoChildSections = {
  ...SaxoIndividualAndJointSections,
  childTaxInformation: ['USCitizen', 'UStax', 'UStaxFIN'],
  originOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions'],
};

export const SaxoChildSectionsV3 = {
  ...SaxoIndividualAndJointSections,
  childTaxInformation: ['USCitizen', 'UStax', 'UStaxFIN'],
  originOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions'],
  originOfInvestableAssets: ['sourceOfWealthInvestableAssets', 'sourceOfWealthInvestableAssetsSubQuestions', 'sourceOfWealthInvestableAssetsSubstantiation'],
  permitToShare: ['authoriseSaxoToShare'],
};

export const SaxoBusinessSections = {
  companyDetails: ['description', 'numberOfRepresentatives', 'numberOfEmployees', 'countryActivities'],
  businessFinancialInformation: [ 'investIn12Month', [ ['subjectOfVAT', 'VAT'], 'netRevenue', 'equalCapital', 'disposableCapital' ]],
  businessFinancialInformation2: [
    [ 'fatcaStatus', 'fatcaNameOfEntity', 'fatcaNameOfSecurityMarket', 'fatcaISINOfShares', 'substantialOwnersInUS' ],
    [ 'interestInBenefitsUSTax', 'typeOfProvisions', 'typeOfProvisionsOther' ],
    [ 'crsStatus', 'crsOrganisationType' ]
  ],
  businessOriginOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions'],
  representatives: [ 'persons', [ 'partyId', 'hasSaxoAccount', 'saxoAccount', 'numberOfSharesDirect', 'numberOfSharesIndirect' ] ],
  businessAdditional: [ 'declarationOfBearer', 'declarationOfBearerNotify', 'declarationOfBearer30Days' ],
};

export const SaxoBusinessSectionsV2 = {
  companyDetails: ['description', 'numberOfRepresentatives', 'numberOfEmployees', 'countryActivities'],
  businessFinancialInformation: [ 'investIn12Month', [ ['subjectOfVAT', 'VAT'], 'netRevenue', 'equalCapital', 'disposableCapital' ]],
  businessFinancialInformation2: [
    [ 'fatcaStatus', 'fatcaNameOfEntity', 'fatcaNameOfSecurityMarket', 'fatcaISINOfShares', 'substantialOwnersInUS' ],
    [ 'crsStatus', 'crsOrganisationType' ]
  ],
  businessFinancialInformation3: [
    [ 'emirNature', 'emirFinancialSectorAssurance', 'emirFinancialSectorDepository', 'emirFinancialSectorCredit',
      'emirFinancialSectorInvestfirm', 'emirFinancialSectorInsurance', 'emirFinancialSectorAlternativeInvestFund',
      'emirFinancialSectorRetirementProvision', 'emirFinancialSectorReinsurance', 'emirFinancialSectorCollectiveInvestment' ],
    [ 'emirHedging', 'emirThresholds' ]
  ],
  businessOriginOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions'],
  representatives: [ 'persons', [ 'partyId', 'hasSaxoAccount', 'saxoAccount', 'numberOfSharesDirect', 'numberOfSharesIndirect' ] ],
  businessAdditional: [ 'declarationOfBearer', 'declarationOfBearerNotify', 'declarationOfBearer30Days' ],
};

export const SaxoBusinessSectionsV3 = {
  companyDetails: ['description', 'numberOfRepresentatives', 'numberOfEmployees', 'countryActivities'],
  businessFinancialInformation: [ 'investIn12Month', [ ['subjectOfVAT', 'VAT'], 'netRevenue', 'equalCapital', 'disposableCapital' ]],
  businessFinancialInformation2: [
    [ 'fatcaStatus', 'fatcaNameOfEntity', 'fatcaNameOfSecurityMarket', 'fatcaISINOfShares', 'substantialOwnersInUS' ],
    [ 'crsStatus', 'crsOrganisationType' ]
  ],
  businessFinancialInformation3: [
    [ 'emirNature', 'emirFinancialSectorAssurance', 'emirFinancialSectorDepository', 'emirFinancialSectorCredit',
      'emirFinancialSectorInvestfirm', 'emirFinancialSectorInsurance', 'emirFinancialSectorAlternativeInvestFund',
      'emirFinancialSectorRetirementProvision', 'emirFinancialSectorReinsurance', 'emirFinancialSectorCollectiveInvestment' ],
    [ 'emirHedging', 'emirThresholds' ]
  ],
  businessOriginOfAssets: ['sourceOfWealth', 'sourceOfWealthSubQuestions'],
  businessOriginOfInvestableAssets: ['sourceOfWealthInvestableAssets', 'sourceOfWealthInvestableAssetsSubQuestions', 'sourceOfWealthInvestableAssetsSubstantiation'],
  representatives: [ 'persons', [ 'partyId', 'hasSaxoAccount', 'saxoAccount', 'numberOfSharesDirect', 'numberOfSharesIndirect' ] ],
  businessAdditional: [ 'declarationOfBearer', 'declarationOfBearerNotify', 'declarationOfBearer30Days' ],
  permitToShare: ['authoriseSaxoToShare'],
};

export const SaxoPages = (processorId: string) => ([
  {
    id: 'childTaxInformation',
    text: $localize `:@@childTaxInformation:Child tax information`,
    route: `/processor/${processorId}/saxo/child-tax-information`,
  },
  {
    id: 'personalDetails',
    text: $localize `:@@personalDetails:Personal details`,
    route: `/processor/${processorId}/saxo/personal-details`,
  },
  {
    id: 'professionalInformation',
    text: $localize `:@@professionalInformation:Professional information`,
    route: `/processor/${processorId}/saxo/professional-information`,
  },
  {
    id: 'taxInformation',
    text: $localize `:@@taxInformation:Tax information`,
    route: `/processor/${processorId}/saxo/tax-information`,
  },
  {
    id: 'financialInformation',
    text: $localize `:@@financialInformation:Financial information`,
    route: `/processor/${processorId}/saxo/financial-information`,
  },
  {
    id: 'originOfAssets',
    text: $localize `:@@originOfAssets:Origin of assets`,
    route: `/processor/${processorId}/saxo/origin-of-assets`,
  },
  {
    id: 'originOfInvestableAssets',
    text: $localize `:@@originOfInvestableAssets:Origin of investable assets`,
    route: `/processor/${processorId}/saxo/origin-of-investable-assets`,
  },

  {
    id: 'companyDetails',
    text: $localize `:@@companyDetails:Company details`,
    route: `/processor/${processorId}/saxo/company-details`,
  },
  {
    id: 'businessFinancialInformation',
    text: $localize `:@@financialInformation:Financial information`,
    route: `/processor/${processorId}/saxo/business-financial-information`,
  },
  {
    id: 'businessFinancialInformation2',
    text: `FATCA & CRS`,
    route: `/processor/${processorId}/saxo/business-financial-information-2`,
  },
  {
    id: 'businessFinancialInformation3',
    text: `EMIR & UMR`,
    route: `/processor/${processorId}/saxo/business-financial-information-3`,
  },
  {
    id: 'businessOriginOfAssets',
    text: $localize `:@@originOfAssets:Origin of assets`,
    route: `/processor/${processorId}/saxo/business-origin-of-assets`,
  },
  {
    id: 'businessOriginOfInvestableAssets',
    text: $localize `:@@originOfInvestableAssets:Origin of investable assets`,
    route: `/processor/${processorId}/saxo/business-origin-of-investable-assets`,
  },
  {
    id: 'representatives',
    text: $localize `:@@representatives:Representatives`,
    route: `/processor/${processorId}/saxo/representatives`,
  },
  {
    id: 'businessAdditional',
    text: $localize `:@@additionalDetails:Additional details`,
    route: `/processor/${processorId}/saxo/business-additional`,
  },

  {
    id: 'permitToShare',
    text: $localize `:@@authoriseSaxoBank:Authorise Saxo Bank`,
    route: `/processor/${processorId}/saxo/permit-to-share`,
  },
]);

export const getSaxoStructureConfig = (registrationType: RegistrationTypes | RegistrationSubTypes, version: number) => {
  const mapper = {
    [RegistrationTypes.INDIVIDUAL]: () => version >= 3 ? SaxoIndividualAndJointSectionsV3 : SaxoIndividualAndJointSections,
    [RegistrationTypes.JOINT]: () => version >= 3 ? SaxoIndividualAndJointSectionsV3 : SaxoIndividualAndJointSections,
    [RegistrationSubTypes.CHILD]: () => version >= 3 ? SaxoChildSectionsV3 : SaxoChildSections,
    [RegistrationTypes.BUSINESS]: () => {
      if (version >= 3) return SaxoBusinessSectionsV3;

      return version >= 2 ? SaxoBusinessSectionsV2 : SaxoBusinessSections;
    },
    default: () => {
      console.error(`${registrationType} isn't implemented`);

      return {};
    }
  };

  return (mapper[registrationType] || mapper.default)();
};
