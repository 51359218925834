import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable({
  providedIn: 'root'
})
export class UnauthenticatedGuard  {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const at = TokenHelper.getAccessToken();
    if (at && at !== 'undefined' && TokenHelper.isAccesTokenValid()) {
        this.router.navigate(['/dashboard']);

        return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}
