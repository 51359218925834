import { RegistrationSubTypes, RegistrationTypes } from '../../shared/models/registrationType.enum';

export const BinckBankSharedFields = {
  statements: ['recievePhysicalStatements', 'collateralisedLendingFacility'],
  origins: ['originOfAssets'],
};

export const BinckBankBusinessFields = {
  ...BinckBankSharedFields,
  income: ['planningAmountInvest', 'annualRevenue', 'legalEntityWorth'],
};

export const BinckBankNonBusinessFields = {
  ...BinckBankSharedFields,
  income: ['planningAmountInvest', 'annualGross', 'freeAssets'],
};

export const BinckBankBusinessFieldsV6 = {
  ...BinckBankBusinessFields,
  businessAssets: ['primaryInvestmentCapitalSource_Business'],
  businessSourceOfWealth: ['primaryAssetsSource_Business'],
  financialHolding: ['isFinancialHoldingCompany', 'isStillGeneratingIncome', 'sector'],
  activeRegion: ['primaryBusinessContinent'],
  entities: ['isNonNLEntityInGroup', 'isEntityPartOfGroup'],
  characteristics: ['characteristicBusiness'],
  businessPolitics: ['isPoliticalFunction_Business', 'isPoliticalFunction_Business_Name', 'isPoliticalFunction_Business_Position', 'isPoliticalFunction_Business_Description']
};

export const BinckBankNonBusinessFieldsV6 = {
  ...BinckBankNonBusinessFields,
  assets: ['primaryInvestmentCapitalSource_NP'],
  sourceOfWealth: ['primaryAssetsSource_NP'],
  abroad: ['stayAbroadReason'],

  employmentStatus: ['employmentStatus', 'workSector'],
  politics: ['isPoliticalFunction_NP', 'isPoliticalFunction_NP_Name', 'isPoliticalFunction_NP_Position', 'isPoliticalFunction_NP_Description'],
  connection: ['netherlandsRelationship', 'netherlandsRelationshipOther', 'relationToAccountHolder'],
};

export const ConditionalBusinessSections = ['employmentStatus', 'politics', 'connection']; // These sections should be shown based on availability of first question
export const ConditionalIndividualSections = ['connection']; // These sections should be shown based on availability of first question

export const BinckBankPagesFields = {
  ...BinckBankBusinessFieldsV6,
  ...BinckBankNonBusinessFieldsV6,
  businessIncome: BinckBankBusinessFieldsV6.income,
  notBusinessIncome: BinckBankNonBusinessFieldsV6.income
};

export const getBinckBankStructureConfig = (registrationType: RegistrationTypes | RegistrationSubTypes, version: number) => {
  if (version >= 6) {
    return registrationType === RegistrationTypes.BUSINESS ? BinckBankBusinessFieldsV6 : BinckBankNonBusinessFieldsV6;
  }

  return registrationType === RegistrationTypes.BUSINESS ? BinckBankBusinessFields : BinckBankNonBusinessFields;
};

export const BinckBankPages = (processorId: string): Array<NavItem> => ([
  {
    id: 'income',
    text: $localize `:@@binckIncome:Financials`,
    route: `/processor/${processorId}/binckbank/income`,
  },
  {
    id: 'statements',
    text: $localize `:@@binckStatements:Additional details`,
    route: `/processor/${processorId}/binckbank/statements`,
  },
  {
    id: 'origins',
    text: $localize `:@@binckOrigins:Origin of assets`,
    route: `/processor/${processorId}/binckbank/origin-of-assets`,
  },

  //  Business routes v >= 6
  {
    id: 'businessAssets',
    text: $localize `:@@assets:Assets`,
    route: `/processor/${processorId}/binckbank/business-assets`,
  },
  {
    id: 'businessSourceOfWealth',
    text: $localize `:@@sourceOfWealth:Source of Wealth`,
    route: `/processor/${processorId}/binckbank/business-source-of-wealth`,
  },
  {
    id: 'financialHolding',
    text: $localize `:@@financialHolding:Financial holding`,
    route: `/processor/${processorId}/binckbank/financial-holding`,
  },
  {
    id: 'activeRegion',
    text: $localize `:@@activeRegion:Active region`,
    route: `/processor/${processorId}/binckbank/active-region`,
  },
  {
    id: 'entities',
    text: $localize `:@@entities:Entities`,
    route: `/processor/${processorId}/binckbank/entities`,
  },
  {
    id: 'characteristics',
    text: $localize `:@@characteristics:Characteristics`,
    route: `/processor/${processorId}/binckbank/characteristics`,
  },
  {
    id: 'businessPolitics',
    text: $localize `:@@politics:Politics`,
    route: `/processor/${processorId}/binckbank/business-politics`,
  },

  //  Individual and Joined routes v >= 6
  {
    id: 'employmentStatus',
    text: $localize `:@@employmentStatus:Employment status`,
    route: `/processor/${processorId}/binckbank/employment-status`,
  },
  {
    id: 'assets',
    text: $localize `:@@assets:Assets`,
    route: `/processor/${processorId}/binckbank/assets`,
  },
  {
    id: 'sourceOfWealth',
    text: $localize `:@@sourceOfWealth:Source of Wealth`,
    route: `/processor/${processorId}/binckbank/source-of-wealth`,
  },
  {
    id: 'politics',
    text: $localize `:@@politics:Politics`,
    route: `/processor/${processorId}/binckbank/politics`,
  },
  {
    id: 'abroad',
    text: $localize `:@@abroad:Abroad`,
    route: `/processor/${processorId}/binckbank/abroad`,
  },
  {
    id: 'connection',
    text: $localize `:@@connection:Connection`,
    route: `/processor/${processorId}/binckbank/connection`,
  }
]);
