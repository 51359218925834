import { Injectable } from '@angular/core';

import { BehaviorSubject, throwError } from 'rxjs';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User>;
  username: BehaviorSubject<string>;

  constructor(private usersGateway: UsersGatewayService) {
    this.user = new BehaviorSubject<User>(null);
    this.username = new BehaviorSubject<string>(null);
  }

  get(): Promise<User> {
    if (this.user.value && this.user.value.username) {
      return Promise.resolve(this.user.value);
    }

    return this.updateUser();
  }

  get isIntegratedPortalClient() {
    return this.user.value && this.user.value.channel === 'integrated_portal';
  }

  isUserTenantClient() {
    const user = this.user.value;

    return user && user.roles.includes('ROLE_TENANT_CLIENT');
  }

  clear() {
    this.user.next(null);
  }

  updateUser(): Promise<User> {
    return this.getParameters()
      .then((result: any) => {
        this.user.next({
          username: result.username || result.email,
          email: result.email,
          emailVerified: result.email_verified === 'true',
          name: {
            full: `${result.given_name} ${(result.middle_name ? `${<string> result.middle_name  } ` : '')}${result.family_name || ''}`,
            given: result.given_name,
            family: result.family_name,
            middle: result.middle_name || null
          },
          phoneNumber: result.phone_number,
          phoneNumberVerified: result.phone_number_verified === 'true',
          locale: result.locale,
          region: result.region,
          tenantId: result.profile.includes('ROLE_TENANT_CLIENT') ? '' : result.preferred_username,
          partyId: result.profile.includes('ROLE_TENANT_CLIENT') ? result.preferred_username : '',
          roles: result.profile,
          sub: result.sub,
          channel: result['custom:prospect_channel'],
          mfaMethod: result.MFADetails?.PreferredMfaSetting
        });

        return this.user.value;
      });
  }

  private getParameters() {
    return new Promise((resolve, reject) => {
      this.usersGateway.getCurrentUser()
        .pipe(catchError((response: HttpErrorResponse) => {
          reject('cognito user not found');

          return throwError(response);
        }))
        .subscribe((user: any) => {

        const cognitoUser: any = user;

        if (cognitoUser) {
          resolve({...cognitoUser, roles: [cognitoUser.profile]});
        } else {
          reject('cognito user not found');
        }
      });
    });
  }
}
