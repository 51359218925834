import { NgModule } from '@angular/core';

import { BcSpinnerComponent } from './bc.spinner.component';


@NgModule({
  imports: [  ],
  declarations: [
    BcSpinnerComponent,
  ],
  exports: [
    BcSpinnerComponent
  ]
})
export class BcSpinnerModule {  }
