import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PostcodeService {

  postCodeUrl = 'https://postcode-api.apiwise.nl/v2/';

  constructor(private http: HttpClient) {  }

  get(postCode: string, num: string): Promise<any> {
    const headers = new HttpHeaders({ 'X-Api-Key': environment.POSTCODE_X_API_KEY });
    const options = { headers: headers };

    return this.http.get(`${this.postCodeUrl}addresses/?postcode=${postCode}&number=${num}`, options)
      .toPromise();
  }
}
