import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, pluck, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BusinessDataSearchItem, KvkSearchResult, KboSearchResult } from './bc-business-search/search-result.model';
import { environment } from '@env/environment';

declare interface AsyncPlugin {
  getList(query: string): Observable<any>;
  next(): Observable<any>;
}

export enum BusinessDataProviders {
  Default = 'direct',
  CreditSafe = 'creditsafe'
}

export enum ConfiguredCountries {
  NL = 'NL',
  BE = 'BE'
}

@Injectable({
  providedIn: 'root'
})
export class LegalEntityIdentification implements AsyncPlugin {
  countryCode: string;
  provider: BusinessDataProviders;

  query: string;
  queryLength = 10;
  private lastPage: number;

  constructor(private http: HttpClient) {}

  setCountryCode = code => (this.countryCode = code);
  setDataProvider = provider => (this.provider = provider);

  getList(query: string): Observable<Array<BusinessDataSearchItem>> {
    this.query = query;

    return this.request(query);
  }

  next(): Observable<Array<BusinessDataSearchItem>> {
    return this.request(this.query);
  }

  request(query: string): Observable<Array<BusinessDataSearchItem>> {
    if (this.provider === BusinessDataProviders.CreditSafe) return this.requestOthers(query);

    const defaultRequestMapper = {
      [ConfiguredCountries.NL]: () => this.requestKVKDetails(query, 0),
      [ConfiguredCountries.BE]: () => this.requestKBODetails(query),
      default: () => this.requestOthers(query)
    };

    return (defaultRequestMapper[this.countryCode] || defaultRequestMapper['default'])();
  }

  getParsedKvkNumber(unformattedKvkNumber: string): string {
    const kvkNumberMaxLength = 10;

    return unformattedKvkNumber?.length > kvkNumberMaxLength ? unformattedKvkNumber.replace(/\./g, '') : unformattedKvkNumber;
  }

  private requestOthers(query: string): Observable<Array<BusinessDataSearchItem>> {
    return this.http.get(`${environment.BASE_URL_BTP}search?country=${this.countryCode.toUpperCase()}&searchKey=${query}`).pipe(
      map((res) => res['items'])
    );
  }

  private requestKBODetails(query: string): Observable<Array<BusinessDataSearchItem>> {
    query.length > this.queryLength ? query.replace(/\./g, '') : query;

    return this.http.get(`${environment.BASE_URL_BTP}search?country=BE&enterpriseNumber=${query}`).pipe(
      map((response: KboSearchResult) => (response.success ? [response] : []))
    );
  }

  private requestKVKDetails(query: string, startPage: number): Observable<Array<BusinessDataSearchItem>> {
    let params = new HttpParams();
    params = params.append('q', query);
    params = params.append('startPage', startPage.toString());

    return this.http.get(`${environment.BASE_URL_BTP}search?country=NL&searchKey=${query}`, { params }).pipe(
      tap((response: KvkSearchResult) => (this.lastPage = response?.startPage)),
      pluck('items')
    );
  }
}
