import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { TokenHelper } from '@blancoservices/bc-auth-web';

import { Logger, UserLoginService, UserService } from '../services';
import { BcSpinnerService } from '../../widgets';

import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

const toBeSignedApis = [
  environment.BASE_URL_BCOS,
  environment.BASE_URL_BKYC,
  environment.BASE_URL_BCUS,
  environment.BASE_URL_BAMS,
  environment.BASE_URL_BSUR,
  `${environment.BASE_URL_BGC}/legalForms`,
  `${environment.BASE_URL_BGC}/legalActivities`,
  `${environment.BASE_URL_BTP}abbyy`,
  `${environment.BASE_URL_BTP}search`
];
const openApis = [
  {
    url: environment.BASE_URL_BGC,
    method: 'GET'
  },
  {
    url: `${environment.BASE_URL_BCOS}/tenants/settings`,
    method: 'GET'
  },
  {
    url: `${environment.BASE_URL_BCOS}/users`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?invite_id`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?login`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?mfa`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?forgot`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?getHash`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users/createProspectUser`,
    method: 'POST'
  }
];

const skipTokenValidationApis = [
    {
      url: `${environment.BASE_URL_BAMS}/users/secured?refreshToken`,
      method: 'POST'
    },
];

@Injectable()
export class CognitoInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public userService: UserService,
    private userLoginService: UserLoginService,
    private spinner: BcSpinnerService,
    private logger: Logger
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldBeSigned =
      !openApis.some((api) => request.url.startsWith(api.url) && request.method === api.method) &&
      toBeSignedApis.some((url) => request.url.startsWith(url));

    const token = TokenHelper.getAccessToken(),
          tenantId = sessionStorage.getItem('tenant_id') || '',
          username = sessionStorage.getItem('username') || '';

    if (shouldBeSigned) {
      const shouldSkipTokenValidated = skipTokenValidationApis
        .some((api) => request.url.startsWith(api.url) && request.method === api.method);
      const validated = (shouldSkipTokenValidated || TokenHelper.isAccesTokenValid());

      if (token && validated) {
        request = request.clone({
          setHeaders: {
            Authorization: token,
            'x-api-key': tenantId
          }
        });

        return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
      } else {
        // expired token
        if (token) {
          this.userLoginService.clearDataAfterLogout();
          this.spinner.set(false);
          this.logger.showError(new Error('Session expired.'));
        }

        return throwError(new HttpErrorResponse({status: 401}))
                .pipe(catchError(this.catcherr.bind(this)));
      }
    }

    if (!(token || tenantId || username)) {
      return throwError(new HttpErrorResponse({
                status: 401,
                error: { errorMessage: 'You are not allowed to make this call'}
              })).pipe(catchError(this.catcherr.bind(this)));
    }

    if (openApis.some((api) => request.url.startsWith(api.url))) {
      request = request.clone({
        setHeaders: {
          Authorization: TokenHelper.getAccessToken() || '',
          'x-api-key': tenantId,
          'x-user-key': username
        }
      });
    }

    return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
  }

  private catcherr(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      // eslint-disable-next-line no-magic-numbers
      if (err.status === 401) {
        TokenHelper.clearLocalStorage();
        this.router.navigate(['/login']);

        return EMPTY;
      }

      return throwError(err);
    }
  }
}
