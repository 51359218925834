import { Injectable } from '@angular/core';
import { GatewayService } from './gateway.service';

import { environment } from '@env/environment';
export const baseUrlBSUR = environment.BASE_URL_BSUR;

export enum SurveyType {
  PRODUCT = 'PRODUCT',
  PROCESSOR = 'PROCESSOR'
}


@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(private gatewayService: GatewayService) { }

  startRegistrationSurvey(registrationId: string) {
    const params = { action: 'url', registration_id: registrationId };

    return this.gatewayService.post(`${baseUrlBSUR}surveys/bcos/start`, params);
  }

  getSurveyHistory(id: string, surveyType: SurveyType) {
    const params = { baseId: id, idType: surveyType };

    return this.gatewayService.get(`${baseUrlBSUR}surveys`, params);
  }

  getSurveySettings(registrationId: string) {
    return this.gatewayService.get(`${baseUrlBSUR}surveys/${registrationId}`);
  }
}
