import { Injectable } from '@angular/core';

import { UsersGatewayService } from '@blancoservices/bc-auth-web';

import { RegistrationService } from '../core/services';

import { RegistrationSubTypes, RegistrationTypes } from '../shared/models/registrationType.enum';

@Injectable()
export class SignupService {
  registrationType: RegistrationType | RegistrationSubType;

  constructor(private registrationService: RegistrationService,
              private usersGateway: UsersGatewayService) { }

  createRegistration(formValues: SignUpObject, consentText) {
    const signUpInfo: SignUpObject = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phone: formValues.phone,
      email: formValues.email,
      username: formValues.username,
      roles: 'ROLE_TENANT_CLIENT',
      consentText: consentText,
      locale: formValues.locale,
      region: formValues.region,
      consentFlag: true,
      registration: {
        tenant_id: this.registrationService.tenantId,
        channel: 'WEB_FRONTEND',
        type: this.registrationType === RegistrationSubTypes.CHILD ?
          RegistrationTypes.INDIVIDUAL : <RegistrationType> this.registrationType
      }
    };

    if (this.registrationType === RegistrationSubTypes.CHILD) {
      signUpInfo.registration.subType = RegistrationSubTypes.CHILD;
    }

    return this.signUp(signUpInfo);
  }

  signUp(user: any) {
    return this.usersGateway.signUp('', user).toPromise();
  }
}
