import { Injectable, LOCALE_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BcLocalizedNumbersService {
  private decimalDelimeter: string;
  private thousandsDelimeter: string;

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    /* eslint-disable no-magic-numbers */
    this.decimalDelimeter = (0.1).toLocaleString(this.localeId).substring(1, 2);
    this.thousandsDelimeter = (1000).toLocaleString(this.localeId).substring(1, 2);
    /* eslint-enable no-magic-numbers */
  }

  getValue(num: string, delimiter = this.decimalDelimeter) {
    const parsedNum = this.parseValue(num, delimiter);

    if (parsedNum.integer === '') {
      if (typeof parsedNum.decimal !== 'string') {
        return null;
      }

      parsedNum.integer = '0';
    }

    const value = parsedNum.integer += `.${(parsedNum.decimal || 0)}`;

    return +value;
  }

  parseValue(num: string, delimiter: string = this.decimalDelimeter) {
    if (!num) {
      return {
        integer: '',
        decimal: null
      };
    }

    let integerPart: string;
    let decimalsPart: string;
    const indexOfDecimals = num.indexOf(delimiter);

    if (indexOfDecimals !== -1) {
      const decimalsIdx = 2;
      decimalsPart = num
        .substring(indexOfDecimals + 1)
        .replace(/[^\d]/g, '')
        .substr(0, decimalsIdx);

      integerPart = num.substring(0, indexOfDecimals);
    } else {
      integerPart = num;
    }

    const preparedInteger = integerPart.replace(/[^\d]/g, '');

    return {
      integer: preparedInteger,
      decimal: decimalsPart
    };
  }

  formatValue(num: string | number, fractionDigits: number = 0) {
    if (typeof num === 'number') {
      return num.toLocaleString(this.localeId, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      });
    }

    const parsedNum = this.parseValue(num);

    if (parsedNum.integer === '') {
      if (typeof parsedNum.decimal !== 'string') {
        return '';
      }

      parsedNum.integer = '0';
    }

    let value = (+parsedNum.integer).toLocaleString(this.localeId);

    if (parsedNum.decimal === undefined && fractionDigits > 0) {
      parsedNum.decimal = '0'.repeat(fractionDigits);
    }

    if (typeof parsedNum.decimal === 'string') {
      value = `${value}${this.decimalDelimeter}${parsedNum.decimal}`;
    }

    return value;
  }

  convertValueToPercentView(num: string | number, fractionDigits: number = 0) {
    const percentMultiplier = 100;
    const sourceValue = typeof num === 'string' ? this.getValue(num, '.') : num;
    const value = (new (<any> window).BigNumber(sourceValue)).times(percentMultiplier).toNumber();

    return this.formatValue(value, fractionDigits);
  }

  fetchValueFromPercentView(num: string | number, fractionDigits: number = 4) {
    const percentDivider = 100;
    const sourceValue = this.getValue(`${num}`);


    if (!sourceValue) return 0;

    return (new (<any> window).BigNumber(sourceValue)).dividedBy(percentDivider).round(fractionDigits).toNumber();
  }
}
