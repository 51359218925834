<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n>Forgot your password?</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form [formGroup]="restorePasswordForm" class="auth-form" novalidate>
        <div class="field__item">
          <label class="field__label field__label_contacts"
                 for="username" i18n="@@username">Username</label>
          <input type="text"
                 class="form-control input_field"
                 name="username"
                 id="username" bcLowercase
                 formControlName="username">
          <bc-messages [element]="restorePasswordForm.get('username')"></bc-messages>
        </div>

        <div class="row auth-form__btn-row">
          <div class="col-sm-6 col-xs-4">
            <a i18n="@@back"
               class="btn bc-btn bc-btn_block bc-btn_default"
               routerLink='/login'>Back</a>
          </div>
          <div class="col-sm-6 col-xs-8">
            <button i18n="@@resetPass"
                    class="btn bc-btn bc-btn_block bc-btn_custom"
                    type="submit"
                    name="submit"
                    (click)="onSubmit(restorePasswordForm)"
                    [disabled]="restorePasswordForm.invalid">Reset password</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
