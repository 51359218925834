export const environment = {
  production: true,
  ENV: 'prd',
  VERSION: 'v4.122.6',

  BASE_URL_BCOS: 'https://api.blanco.cloud/bcos',
  BASE_URL_BKYC: 'https://api.blanco.cloud/bkyc',
  BASE_URL_BPARTY: 'https://api.blanco.cloud/bcmd-party',
  BASE_URL_BCUS: 'https://api.blanco.cloud/custodians',
  BASE_URL_BAMS: 'https://api.blanco.cloud/access',
  BASE_URL_BGC: 'https://api.blanco.cloud/bgc',
  BASE_URL_BTP: 'https://api.blanco.cloud/btp/',
  BASE_URL_BSUR: 'https://api.blanco.cloud/bsur/',
  THEME_URL: 'https://assets.blanco.cloud/themes',

  BASE_URL_WSS: 'wss://pvpiq0qad2.execute-api.eu-central-1.amazonaws.com/prd',

  POSTCODE_X_API_KEY: 'TJMzrIw1fk2XyeAms0pN86tZC6n2Y7J9RkGmS879',

  BUGSNAG_API: '0adf26b3d9db4b357ab11ffc9e2d95ce',

  redirectTo: {
    clientPortal: '.blanco.cloud'
  },
};
