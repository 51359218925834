export class NaturalPerson {
  partyId?: string;
  sex?: Sex;
  initials?: string;
  preferredName?: string;
  givenNames?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  relation?: string;
  dateOfBirth?: string;
  countryOfBirth?: string;
  placeOfBirth?: string;
  nationality?: string;
  region?: string;
  locale?: string;
  personalNumber?: string;
  taxResidencies?: Array<any>;
  /* eslint-disable */
  USPerson?: boolean;
  /* eslint-disable */
  bankAccount?: string;
  address?: Address;
  secondaryAddresses?: Array<Address>;
  signer?: boolean;
  ubo?: boolean;
  accountHolderName?: string;
  insider?: boolean;
  companyName?: string;
  ownMoreThan1Pshare?: boolean;
  shareCompanyName?: string;
  preAcceptDocumentDetails?: any;
  ownershipPercentage?: any;
  bankAccountType?: BankAccountTypes;

  politicallyExposed?: boolean;
  politicallyExposedRole?: string;
  relatedToPoliticallyExposed?: boolean;
  relatedToPoliticallyExposedName?: string;
  relatedToPoliticallyExposedRole?: string;
  relatedToPoliticallyExposedConnection?: string;

  constructor(rootLevel: boolean, np?: NaturalPerson) {
    np ? this.update(rootLevel, np) : this.clear(rootLevel);
  }

  get fullName(): string {
    return this.preferredName ? `${this.preferredName} ${this.lastName || ''}` : `${this.lastName || ''}`;
  }

  clear(rootLevel: boolean) {
    this.sex = '';
    this.initials = '';
    this.preferredName = '';
    this.givenNames = '';
    this.lastName = '';
    this.phone = '';
    this.email = '';
    this.dateOfBirth = null;
    this.countryOfBirth = '';
    this.placeOfBirth = '';
    this.nationality = '';
    this.region = '';
    this.locale = '';
    this.personalNumber = '';
    this.taxResidencies = [];
    this.USPerson = null;
    this.insider = null;
    this.ownMoreThan1Pshare = null;
    this.relation = null;

    if (rootLevel) this.bankAccount = '';

    this.address = {
      line1: '',
      line2: '',
      postalCode: '',
      city: '',
      country: ''
    };
    this.secondaryAddresses = [];
    this.signer = false;
    this.ubo = false;

    this.politicallyExposed = null;
    this.politicallyExposedRole = '';
    this.relatedToPoliticallyExposed = null;
    this.relatedToPoliticallyExposedName = '';
    this.relatedToPoliticallyExposedRole = '';
    this.relatedToPoliticallyExposedConnection = '';
  }

  update(rootLevel: boolean, values: any) {
    this.sex = values.sex || this.sex || '';
    this.initials = values.initials || this.initials || '';
    this.preferredName = values.preferredName || this.preferredName || '';
    this.givenNames = values.givenNames || this.givenNames || '';
    this.lastName = values.lastName || this.lastName || '';
    this.phone = values.phone || this.phone || '';
    this.email = values.email || this.email || '';
    this.dateOfBirth = values.dateOfBirth || this.dateOfBirth || null;
    this.countryOfBirth = values.countryOfBirth || this.countryOfBirth || '';
    this.placeOfBirth = values.placeOfBirth || this.placeOfBirth || '';
    this.nationality = values.nationality || this.nationality || '';
    this.region = values.region || this.region || '';
    this.locale = values.locale || this.locale || '';
    this.personalNumber = values.personalNumber || this.personalNumber || '';
    this.ownershipPercentage = values.ownershipPercentage || this.ownershipPercentage || 0;

    this.taxResidencies = values.taxResidencies || this.taxResidencies || [];
    this.accountHolderName = values.accountHolderName || this.accountHolderName;

    this.USPerson = this.isNotEmpty(values.USPerson) ? values.USPerson : this.resetNotBool(this.USPerson);
    this.insider = this.isNotEmpty(values.insider) ? values.insider : this.resetNotBool(this.insider);
    this.ownMoreThan1Pshare = this.isNotEmpty(values.ownMoreThan1Pshare) ? values.ownMoreThan1Pshare : this.resetNotBool(this.ownMoreThan1Pshare);

    this.companyName = values.companyName || this.companyName;
    this.shareCompanyName = values.shareCompanyName || this.shareCompanyName;
    this.preAcceptDocumentDetails = values.preAcceptDocumentDetails || this.preAcceptDocumentDetails;

    if (rootLevel) {
      this.bankAccount = values.bankAccount || this.bankAccount || '';
      this.bankAccountType = values.bankAccountType || this.bankAccountType || '';
    }

    if (!this.address) {
      this.address = {
        line1: '',
        line2: '',
        postalCode: '',
        city: '',
        country: ''
      };
    }

    if (values.address) {
      this.address = {
        line1: values.address.line1 || this.address.line1 || '',
        line2: values.address.line2 || this.address.line2 || '',
        postalCode: values.address.postalCode || this.address.postalCode || '',
        city: values.address.city || this.address.city || '',
        country: values.address.country || this.address.country || '',
      };
    }
    this.secondaryAddresses = values.secondaryAddresses || [];

    if (values.hasOwnProperty('relation')) this.relation = values.relation;

    this.signer = values.signer !== null && values.signer !== undefined ? values.signer :
      this.signer != null && this.isNotEmpty(this.signer) ? this.signer : false;

    this.ubo = values.ubo !== null && values.ubo !== undefined ? values.ubo :
      this.ubo != null && this.isNotEmpty(this.ubo) ? this.ubo : false;

    this.politicallyExposed = this.isNotEmpty(values.politicallyExposed) ? values.politicallyExposed : this.resetNotBool(this.politicallyExposed);
    this.politicallyExposedRole = values.politicallyExposedRole || this.politicallyExposedRole || '';
    this.relatedToPoliticallyExposed = this.isNotEmpty(values.relatedToPoliticallyExposed) ? values.relatedToPoliticallyExposed : this.resetNotBool(this.relatedToPoliticallyExposed);
    this.relatedToPoliticallyExposedName = values.relatedToPoliticallyExposedName || this.relatedToPoliticallyExposedName || '';
    this.relatedToPoliticallyExposedRole = values.relatedToPoliticallyExposedRole || this.relatedToPoliticallyExposedRole || '';
    this.relatedToPoliticallyExposedConnection = values.relatedToPoliticallyExposedConnection || this.relatedToPoliticallyExposedConnection || '';
  }

  isNotEmpty(value) {
    return value !== undefined;
  }

  resetNotBool(value) {
    return typeof value === 'boolean' ? value : null;
  }
}
