import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BcCatchMessagesModule, BcMessagesManager, BcMessagesModule,
  ShowPasswordModule, BcLowercaseModule } from '../../widgets';

import { RestorePassComponent } from './restorepass.component';
import { RestorePass2Component } from './restorepass2.component';

import { RestorePassService } from './restorepass.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    BcCatchMessagesModule,
    BcMessagesModule,
    BcLowercaseModule,
    ShowPasswordModule
  ],
  declarations: [
    RestorePassComponent,
    RestorePass2Component
  ],
  providers: [
    BcMessagesManager,
    RestorePassService
  ]
})
export class RestorePasswordModule { }
