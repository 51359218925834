export class LegalEntity {
  chamberOfCommerceNo?: string;
  name: string;
  locale?: string;
  region?: string;
  phone?: string;
  email: string;
  incorporationDate: string;
  legalForm: string;
  activeLegalActivityClassification: string;
  globalLegalActivities?: Array<any>;
  legalFormText?: string;
  legalActivitiesFull?: Array<any>;
  taxIdentificationNumber: string;
  taxResidencies: Array<any>;
  legalEntityIdentifier?: string;
  legalEntityIdentifierStatus?: string;
  legalEntityIdentifierExpirationDate?: string;
  /* eslint-disable */
  USPerson?: boolean;
  /* eslint-disable */
  bankAccount?: string;
  address?: Address;
  secondaryAddresses?: Array<Address>;
  accountHolderName?: string;
  ownMoreThan1Pshare?: boolean;
  shareCompanyName?: string;
  ownershipPercentage?: any;
  bankAccountType?: BankAccountTypes;
  companyId?: string;

  constructor(rootLevel: boolean, le?: LegalEntity) {
    le ? this.update(rootLevel, le) : this.clear(rootLevel);
  }

  clearField = (field, value?) => this[field] = value || null;

  clear(rootLevel: boolean) {
    this.chamberOfCommerceNo = '';
    this.name = '';
    this.phone = '';
    this.locale = '';
    this.region = '';
    this.incorporationDate = null;
    this.legalForm = '';
    this.activeLegalActivityClassification = '';
    this.globalLegalActivities = [];
    this.legalFormText = null;
    this.legalActivitiesFull = [];
    this.email = '';
    this.taxIdentificationNumber = '';
    this.taxResidencies = [];
    this.ownershipPercentage = 0;
    if(rootLevel) {
      this.legalEntityIdentifier = null;
      this.legalEntityIdentifierStatus = null;
      this.legalEntityIdentifierExpirationDate = null;
      this.bankAccount = '';
    }

    this.USPerson = null;
    this.ownMoreThan1Pshare = null;
    this.address = {
      line1: '',
      line2: '',
      postalCode: '',
      city: '',
      country: ''
    };
    this.secondaryAddresses = [];
  }

  update(rootLevel: boolean, values?: any) {
    this.chamberOfCommerceNo = this.isNotEmpty(values.chamberOfCommerceNo) ? values.chamberOfCommerceNo : this.chamberOfCommerceNo || '';
    this.name = values.name || this.name || '';
    this.phone = values.phone || this.phone || '';
    this.email = values.email || this.email || '';
    this.locale = values.locale || this.locale || '';
    this.region = values.region || this.region || '';
    this.legalForm = values.legalForm || null;
    this.legalFormText = values.legalFormText || null;
    this.ownershipPercentage = values.ownershipPercentage || this.ownershipPercentage || 0;
    this.incorporationDate = values.incorporationDate || this.incorporationDate || null;
    this.activeLegalActivityClassification = values.activeLegalActivityClassification || this.activeLegalActivityClassification || 'NACE'; // temporary set NACE as main classification
    this.globalLegalActivities = values.globalLegalActivities ? [...Array.from(new Set(values.globalLegalActivities))] : this.globalLegalActivities;
    this.taxIdentificationNumber = this.isNotEmpty(values.taxIdentificationNumber) ? values.taxIdentificationNumber : this.taxIdentificationNumber || '';

    this.taxResidencies = values.taxResidencies || this.taxResidencies || [];

    if (rootLevel) {
      this.legalEntityIdentifier = values.legalEntityIdentifier || this.legalEntityIdentifier || '';
      this.legalEntityIdentifierStatus = values.legalEntityIdentifierStatus || this.legalEntityIdentifierStatus || '';
      this.legalEntityIdentifierExpirationDate = values.legalEntityIdentifierExpirationDate || this.legalEntityIdentifierExpirationDate || null;

      this.bankAccount = values.bankAccount || this.bankAccount || '';
      this.accountHolderName = values.accountHolderName || this.accountHolderName || '';
      this.bankAccountType = values.bankAccountType || this.bankAccountType || '';
    }

    this.USPerson = this.isNotEmpty(values.USPerson) ? values.USPerson : this.resetNotBool(this.USPerson);
    this.ownMoreThan1Pshare = this.isNotEmpty(values.ownMoreThan1Pshare) ? values.ownMoreThan1Pshare : this.resetNotBool(this.ownMoreThan1Pshare);

    this.shareCompanyName = values.shareCompanyName || this.shareCompanyName;

    if (!this.address) {
      this.address = {
        line1: '',
        line2: '',
        postalCode: '',
        city: '',
        country: ''
      };
    }

    if (values.address) {
      this.address = {
        line1: this.isNotEmpty(values.address.line1) ? values.address.line1 : this.address.line1 || '',
        line2: this.isNotEmpty(values.address.line2) ? values.address.line2 : this.address.line2 || '',
        postalCode: this.isNotEmpty(values.address.postalCode) ? values.address.postalCode : this.address.postalCode || '',
        city: this.isNotEmpty(values.address.city) ? values.address.city : this.address.city || '',
        country: this.isNotEmpty(values.address.country) ? values.address.country : this.address.country || ''
      };
    }
    this.secondaryAddresses = values.secondaryAddresses || [];

    if (values.companyId) {
      this.companyId = values.companyId;
    }
  }

  isNotEmpty(value) {
    return value !== undefined;
  }

  resetNotBool(value) {
    return typeof value === 'boolean' ? value : null;
  }
}
