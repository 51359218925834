import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ShowPasswordModule, BcFocusModule, BcCatchMessagesModule, BcMessagesManager,
  BcMessagesModule, BcPhoneInputModule, BcLowercaseModule, BcSelectModule, BcSpinnerModule } from '../widgets';

import { RestorePasswordModule } from './restorepass/restorepass.module';

import { LoginComponent } from './login/login.component';
import { ConfirmCodeComponent } from './confirm/confirm.component';
import { AccountTypeComponent } from './account-type/account-type.component';

import { AuthRoutingModule } from './auth-routing.module';

import { SignupService } from './signup.service';
import { SignupGuard } from './signup/signup.guard';
import { ContinueComponent } from './continue/continue.component';
import { SoftwareMfaModule } from '../core/components/software-mfa/software-mfa.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    BcSpinnerModule,
    BcMessagesModule,
    ShowPasswordModule,
    BcFocusModule,
    BcPhoneInputModule,
    BcSelectModule,
    BcCatchMessagesModule,
    BcLowercaseModule,

    RestorePasswordModule,
    SoftwareMfaModule,
    AuthRoutingModule
  ],
  declarations: [
    LoginComponent,
    ConfirmCodeComponent,
    AccountTypeComponent,
    ContinueComponent,
  ],
  providers: [
    SignupService,
    SignupGuard,
    BcMessagesManager
  ]
})
export class AuthModule {  }
