<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n="@@registrationComplete">Registration is complete</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <div class="success-page__wrap text-center">
        <svg class="success-page__icon svg-icon" width="100" height="100">
          <use xlink:href="#registration-complete"></use>
        </svg>

        <h2 class="success-page__header h2-header h2-header_charcoal">
          <ng-container i18n="@@greatNews">Great news!</ng-container>
          <br>
          <ng-container i18n="@@informationReceived">We have received the information successfully.</ng-container>
        </h2>
        <p class="success-page__text main-text main-text_warm-grey"
           i18n="frontend registration">Now we'll get to work. We will get back to you in case we need additional information.</p>

        <!--TODO: [yana] do we need redirect link here?-->
        <ng-container *ngIf="redirectLink">
          <p class="cocno main-text main-text_warm-grey" i18n="frontend registration">Redirect in {{ timerValue |
            async }} seconds</p>
          <a href="{{redirectLink}}" class="success-page_button btn bc-btn bc-btn_custom" target="_parent" i18n="@@ok">Ok</a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
