import { Injectable } from '@angular/core';

export interface Country {
  name: string;
  value: string;
  code: number;
}

@Injectable({
  providedIn: 'root'
})
export class Countries {
  private list: Array<Country> =
    [
      { value: 'AF', name: 'Afghanistan', code: 93 },
      { value: 'AL', name: 'Albania', code: 355 },
      { value: 'DZ', name: 'Algeria', code: 213 },
      { value: 'AS', name: 'American Samoa', code: 1684 },
      { value: 'AD', name: 'Andorra', code: 376 },
      { value: 'AO', name: 'Angola', code: 244 },
      { value: 'AI', name: 'Anguilla', code: 1264 },
      { value: 'AQ', name: 'Antarctica', code: 672 },
      { value: 'AG', name: 'Antigua and Barbuda', code: 1268 },
      { value: 'AR', name: 'Argentina', code: 54 },
      { value: 'AM', name: 'Armenia', code: 374 },
      { value: 'AW', name: 'Aruba', code: 297 },
      { value: 'AU', name: 'Australia', code: 61 },
      { value: 'AT', name: 'Austria', code: 43 },
      { value: 'AZ', name: 'Azerbaijan', code: 994 },
      { value: 'BS', name: 'Bahamas', code: 1242 },
      { value: 'BH', name: 'Bahrain', code: 973 },
      { value: 'BD', name: 'Bangladesh', code: 880 },
      { value: 'BB', name: 'Barbados', code: 1246 },
      { value: 'BY', name: 'Belarus', code: 375 },
      { value: 'BE', name: 'Belgium', code: 32 },
      { value: 'BZ', name: 'Belize', code: 501 },
      { value: 'BJ', name: 'Benin', code: 229 },
      { value: 'BM', name: 'Bermuda', code: 1441 },
      { value: 'BT', name: 'Bhutan', code: 975 },
      { value: 'BO', name: 'Bolivia', code: 591 },
      { value: 'BA', name: 'Bosnia and Herzegovina', code: 387 },
      { value: 'BW', name: 'Botswana', code: 267 },
      { value: 'BV', name: 'Bouvet Island', code: 47 },
      { value: 'BR', name: 'Brazil', code: 55 },
      { value: 'IO', name: 'British Indian Ocean Territory', code: 246 },
      { value: 'BN', name: 'Brunei Darussalam', code: 673 },
      { value: 'BG', name: 'Bulgaria', code: 359 },
      { value: 'BF', name: 'Burkina Faso', code: 226 },
      { value: 'BI', name: 'Burundi', code: 257 },
      { value: 'KH', name: 'Cambodia', code: 855 },
      { value: 'CM', name: 'Cameroon', code: 237 },
      { value: 'CA', name: 'Canada', code: 1 },
      { value: 'CV', name: 'Cape Verde', code: 238 },
      { value: 'KY', name: 'Cayman Islands', code: 1345 },
      { value: 'CF', name: 'Central African Republic', code: 236 },
      { value: 'TD', name: 'Chad', code: 235 },
      { value: 'CL', name: 'Chile', code: 56 },
      { value: 'CN', name: 'China', code: 86 },
      { value: 'CX', name: 'Christmas Island', code: 61 },
      { value: 'CC', name: 'Cocos (Keeling) Islands', code: 61 },
      { value: 'CO', name: 'Colombia', code: 57 },
      { value: 'KM', name: 'Comoros', code: 269 },
      { value: 'CG', name: 'Congo', code: 243 },
      { value: 'CD', name: 'Congo, the Democratic Republic of the', code: 242 },
      { value: 'CK', name: 'Cook Islands', code: 682 },
      { value: 'CR', name: 'Costa Rica', code: 506 },
      { value: 'CI', name: 'Cote D\'Ivoire', code: 225 },
      { value: 'HR', name: 'Croatia', code: 385 },
      { value: 'CU', name: 'Cuba', code: 53 },
      { value: 'CY', name: 'Cyprus', code: 357 },
      { value: 'CZ', name: 'Czech Republic', code: 420 },
      { value: 'DK', name: 'Denmark', code: 45 },
      { value: 'DJ', name: 'Djibouti', code: 253 },
      { value: 'DM', name: 'Dominica', code: 1767 },
      { value: 'DO', name: 'Dominican Republic', code: 1809 },
      { value: 'EC', name: 'Ecuador', code: 593 },
      { value: 'EG', name: 'Egypt', code: 20 },
      { value: 'SV', name: 'El Salvador', code: 503 },
      { value: 'GQ', name: 'Equatorial Guinea', code: 240 },
      { value: 'ER', name: 'Eritrea', code: 291 },
      { value: 'EE', name: 'Estonia', code: 372 },
      { value: 'ET', name: 'Ethiopia', code: 251 },
      { value: 'FK', name: 'Falkland Islands (Malvinas)', code: 500 },
      { value: 'FO', name: 'Faroe Islands', code: 298 },
      { value: 'FJ', name: 'Fiji', code: 679 },
      { value: 'FI', name: 'Finland', code: 358 },
      { value: 'FR', name: 'France', code: 33 },
      { value: 'GF', name: 'French Guiana', code: 594 },
      { value: 'PF', name: 'French Polynesia', code: 689 },
      { value: 'TF', name: 'French Southern Territories', code: 262 },
      { value: 'GA', name: 'Gabon', code: 241 },
      { value: 'GM', name: 'Gambia', code: 220 },
      { value: 'GE', name: 'Georgia', code: 995 },
      { value: 'DE', name: 'Germany', code: 49 },
      { value: 'GH', name: 'Ghana', code: 233 },
      { value: 'GI', name: 'Gibraltar', code: 350 },
      { value: 'GR', name: 'Greece', code: 30 },
      { value: 'GL', name: 'Greenland', code: 299 },
      { value: 'GD', name: 'Grenada', code: 1473 },
      { value: 'GP', name: 'Guadeloupe', code: 590 },
      { value: 'GU', name: 'Guam', code: 1671 },
      { value: 'GT', name: 'Guatemala', code: 502 },
      { value: 'GN', name: 'Guinea', code: 224 },
      { value: 'GW', name: 'Guinea-Bissau', code: 245 },
      { value: 'GY', name: 'Guyana', code: 592 },
      { value: 'HT', name: 'Haiti', code: 509 },
      { value: 'HM', name: 'Heard Island and Mcdonald Islands', code: 6723 },
      { value: 'VA', name: 'Holy See (Vatican City State)', code: 379 },
      { value: 'HN', name: 'Honduras', code: 504 },
      { value: 'HK', name: 'Hong Kong', code: 852 },
      { value: 'HU', name: 'Hungary', code: 36 },
      { value: 'IS', name: 'Iceland', code: 354 },
      { value: 'IN', name: 'India', code: 91 },
      { value: 'ID', name: 'Indonesia', code: 62 },
      { value: 'IR', name: 'Iran, Islamic Republic of', code: 98 },
      { value: 'IQ', name: 'Iraq', code: 964 },
      { value: 'IE', name: 'Ireland', code: 353 },
      { value: 'IL', name: 'Israel', code: 972 },
      { value: 'IT', name: 'Italy', code: 39 },
      { value: 'JM', name: 'Jamaica', code: 1876 },
      { value: 'JP', name: 'Japan', code: 81 },
      { value: 'JO', name: 'Jordan', code: 962 },
      { value: 'KZ', name: 'Kazakhstan', code: 7 },
      { value: 'KE', name: 'Kenya', code: 254 },
      { value: 'KI', name: 'Kiribati', code: 686 },
      { value: 'XK', name: 'Kosovo', code: 383 },
      { value: 'KP', name: 'Korea, Democratic People\'s Republic of', code: 850 },
      { value: 'KR', name: 'Korea, Republic of', code: 82 },
      { value: 'KW', name: 'Kuwait', code: 965 },
      { value: 'KG', name: 'Kyrgyzstan', code: 996 },
      { value: 'LA', name: 'Lao People\'s Democratic Republic', code: 856 },
      { value: 'LV', name: 'Latvia', code: 371 },
      { value: 'LB', name: 'Lebanon', code: 961 },
      { value: 'LS', name: 'Lesotho', code: 266 },
      { value: 'LR', name: 'Liberia', code: 231 },
      { value: 'LY', name: 'Libyan Arab Jamahiriya', code: 218 },
      { value: 'LI', name: 'Liechtenstein', code: 423 },
      { value: 'LT', name: 'Lithuania', code: 370 },
      { value: 'LU', name: 'Luxembourg', code: 352 },
      { value: 'MO', name: 'Macao', code: 853 },
      { value: 'MK', name: 'Macedonia, the Former Yugoslav Republic of', code: 389 },
      { value: 'MG', name: 'Madagascar', code: 261 },
      { value: 'MW', name: 'Malawi', code: 265 },
      { value: 'MY', name: 'Malaysia', code: 60 },
      { value: 'MV', name: 'Maldives', code: 960 },
      { value: 'ML', name: 'Mali', code: 223 },
      { value: 'MT', name: 'Malta', code: 356 },
      { value: 'MH', name: 'Marshall Islands', code: 692 },
      { value: 'MQ', name: 'Martinique', code: 596 },
      { value: 'MR', name: 'Mauritania', code: 222 },
      { value: 'MU', name: 'Mauritius', code: 230 },
      { value: 'YT', name: 'Mayotte', code: 262 },
      { value: 'MX', name: 'Mexico', code: 52 },
      { value: 'FM', name: 'Micronesia, Federated States of', code: 691 },
      { value: 'MD', name: 'Moldova, Republic of', code: 373 },
      { value: 'MC', name: 'Monaco', code: 377 },
      { value: 'MN', name: 'Mongolia', code: 976 },
      { value: 'MS', name: 'Montserrat', code: 1664 },
      { value: 'MA', name: 'Morocco', code: 212 },
      { value: 'MZ', name: 'Mozambique', code: 258 },
      { value: 'MM', name: 'Myanmar', code: 95 },
      { value: 'NA', name: 'Namibia', code: 264 },
      { value: 'NR', name: 'Nauru', code: 674 },
      { value: 'NP', name: 'Nepal', code: 977 },
      { value: 'NL', name: 'Netherlands', code: 31 },
      { value: 'NC', name: 'New Caledonia', code: 687 },
      { value: 'NZ', name: 'New Zealand', code: 64 },
      { value: 'NI', name: 'Nicaragua', code: 505 },
      { value: 'NE', name: 'Niger', code: 227 },
      { value: 'NG', name: 'Nigeria', code: 234 },
      { value: 'NU', name: 'Niue', code: 683 },
      { value: 'NF', name: 'Norfolk Island', code: 672 },
      { value: 'MP', name: 'Northern Mariana Islands', code: 1670 },
      { value: 'NO', name: 'Norway', code: 47 },
      { value: 'OM', name: 'Oman', code: 968 },
      { value: 'PK', name: 'Pakistan', code: 92 },
      { value: 'PW', name: 'Palau', code: 680 },
      { value: 'PS', name: 'Palestinian Territory, Occupied', code: 970 },
      { value: 'PA', name: 'Panama', code: 507 },
      { value: 'PG', name: 'Papua New Guinea', code: 675 },
      { value: 'PY', name: 'Paraguay', code: 595 },
      { value: 'PE', name: 'Peru', code: 51 },
      { value: 'PH', name: 'Philippines', code: 63 },
      { value: 'PN', name: 'Pitcairn', code: 64 },
      { value: 'PL', name: 'Poland', code: 48 },
      { value: 'PT', name: 'Portugal', code: 351 },
      { value: 'PR', name: 'Puerto Rico', code: 1787 },
      { value: 'QA', name: 'Qatar', code: 974 },
      { value: 'RE', name: 'Reunion', code: 262 },
      { value: 'RO', name: 'Romania', code: 40 },
      { value: 'RU', name: 'Russian Federation', code: 7 },
      { value: 'RW', name: 'Rwanda', code: 250 },
      { value: 'SH', name: 'Saint Helena', code: 290 },
      { value: 'KN', name: 'Saint Kitts and Nevis', code: 1869 },
      { value: 'LC', name: 'Saint Lucia', code: 1758 },
      { value: 'PM', name: 'Saint Pierre and Miquelon', code: 508 },
      { value: 'VC', name: 'Saint Vincent and the Grenadines', code: 1784 },
      { value: 'WS', name: 'Samoa', code: 685 },
      { value: 'SM', name: 'San Marino', code: 378 },
      { value: 'ST', name: 'Sao Tome and Principe', code: 239 },
      { value: 'SA', name: 'Saudi Arabia', code: 966 },
      { value: 'SN', name: 'Senegal', code: 221 },
      { value: 'SC', name: 'Seychelles', code: 248 },
      { value: 'SL', name: 'Sierra Leone', code: 232 },
      { value: 'SG', name: 'Singapore', code: 65 },
      { value: 'SK', name: 'Slovakia', code: 421 },
      { value: 'SI', name: 'Slovenia', code: 386 },
      { value: 'SB', name: 'Solomon Islands', code: 677 },
      { value: 'SO', name: 'Somalia', code: 252 },
      { value: 'ZA', name: 'South Africa', code: 27 },
      { value: 'GS', name: 'South Georgia and the South Sandwich Islands', code: 500 },
      { value: 'ES', name: 'Spain', code: 34 },
      { value: 'LK', name: 'Sri Lanka', code: 94 },
      { value: 'SD', name: 'Sudan', code: 249 },
      { value: 'SR', name: 'Suriname', code: 597 },
      { value: 'SJ', name: 'Svalbard and Jan Mayen', code: 47 },
      { value: 'SZ', name: 'Swaziland', code: 268 },
      { value: 'SE', name: 'Sweden', code: 46 },
      { value: 'CH', name: 'Switzerland', code: 41 },
      { value: 'SY', name: 'Syrian Arab Republic', code: 963 },
      { value: 'TW', name: 'Taiwan', code: 886 },
      { value: 'TJ', name: 'Tajikistan', code: 992 },
      { value: 'TZ', name: 'Tanzania, United Republic of', code: 255 },
      { value: 'TH', name: 'Thailand', code: 66 },
      { value: 'TL', name: 'Timor-Leste', code: 670 },
      { value: 'TG', name: 'Togo', code: 228 },
      { value: 'TK', name: 'Tokelau', code: 690 },
      { value: 'TO', name: 'Tonga', code: 676 },
      { value: 'TT', name: 'Trinidad and Tobago', code: 1868 },
      { value: 'TN', name: 'Tunisia', code: 216 },
      { value: 'TR', name: 'Turkey', code: 90 },
      { value: 'TM', name: 'Turkmenistan', code: 993 },
      { value: 'TC', name: 'Turks and Caicos Islands', code: 1649 },
      { value: 'TV', name: 'Tuvalu', code: 688 },
      { value: 'UG', name: 'Uganda', code: 256 },
      { value: 'UA', name: 'Ukraine', code: 380 },
      { value: 'AE', name: 'United Arab Emirates', code: 971 },
      { value: 'GB', name: 'United Kingdom', code: 44 },
      { value: 'US', name: 'United States of America', code: 1 },
      { value: 'UM', name: 'United States Minor Outlying Islands', code: 246 },
      { value: 'UY', name: 'Uruguay', code: 598 },
      { value: 'UZ', name: 'Uzbekistan', code: 998 },
      { value: 'VU', name: 'Vanuatu', code: 678 },
      { value: 'VE', name: 'Venezuela', code: 58 },
      { value: 'VN', name: 'Viet Nam', code: 84 },
      { value: 'VG', name: 'Virgin Islands, British', code: 1284 },
      { value: 'VI', name: 'Virgin Islands, U.S.', code: 1340 },
      { value: 'WF', name: 'Wallis and Futuna', code: 681 },
      { value: 'EH', name: 'Western Sahara', code: 212 },
      { value: 'YE', name: 'Yemen', code: 967 },
      { value: 'ZM', name: 'Zambia', code: 260 },
      { value: 'ZW', name: 'Zimbabwe', code: 263 },
      { value: 'AX', name: 'Åland Islands', code: 358 },
      { value: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', code: 599 },
      { value: 'CW', name: 'Curaçao', code: 599 },
      { value: 'GG', name: 'Guernsey', code: 441481 },
      { value: 'IM', name: 'Isle of Man', code: 441624 },
      { value: 'JE', name: 'Jersey', code: 441534 },
      { value: 'ME', name: 'Montenegro', code: 382 },
      { value: 'BL', name: 'Saint Barthélemy', code: 590 },
      { value: 'MF', name: 'Saint Martin (French part)', code: 590 },
      { value: 'RS', name: 'Serbia', code: 381 },
      { value: 'SX', name: 'Sint Maarten (Dutch part)', code: 1721 },
      { value: 'SS', name: 'South Sudan', code: 211 }
    ];
  private nl: Array<Country> =
    [
      { value: 'AF', name: 'Afghanistan', code: 93 },
      { value: 'AL', name: 'Albanië', code: 355 },
      { value: 'DZ', name: 'Algerije', code: 213 },
      { value: 'AS', name: 'Amerikaans-Samoa', code: 1684 },
      { value: 'AD', name: 'Andorra', code: 376 },
      { value: 'AO', name: 'Angola', code: 244 },
      { value: 'AI', name: 'Anguilla', code: 1264 },
      { value: 'AQ', name: 'Antarctica', code: 672 },
      { value: 'AG', name: 'Antigua en Barbuda', code: 1268 },
      { value: 'AR', name: 'Argentinië', code: 54 },
      { value: 'AM', name: 'Armenië', code: 374 },
      { value: 'AW', name: 'Aruba', code: 297 },
      { value: 'AU', name: 'Australië', code: 61 },
      { value: 'AT', name: 'Oostenrijk', code: 43 },
      { value: 'AZ', name: 'Azerbeidzjan', code: 994 },
      { value: 'BS', name: 'Bahama\'s', code: 1242 },
      { value: 'BH', name: 'Bahrein', code: 973 },
      { value: 'BD', name: 'Bangladesh', code: 880 },
      { value: 'BB', name: 'Barbados', code: 1246 },
      { value: 'BY', name: 'Wit-Rusland', code: 375 },
      { value: 'BE', name: 'België', code: 32 },
      { value: 'BZ', name: 'Belize', code: 501 },
      { value: 'BJ', name: 'Benin', code: 229 },
      { value: 'BM', name: 'Bermuda', code: 1441 },
      { value: 'BT', name: 'Bhutan', code: 975 },
      { value: 'BO', name: 'Bolivië', code: 591 },
      { value: 'BA', name: 'Bosnië-Herzegovina', code: 387 },
      { value: 'BW', name: 'Botswana', code: 267 },
      { value: 'BV', name: 'Bouvet Eiland', code: 47 },
      { value: 'BR', name: 'Brazilië', code: 55 },
      { value: 'IO', name: 'Brits Indische oceaan', code: 246 },
      { value: 'BN', name: 'Brunei Darussalam', code: 673 },
      { value: 'BG', name: 'Bulgarije', code: 359 },
      { value: 'BF', name: 'Burkina Faso', code: 226 },
      { value: 'BI', name: 'Burundi', code: 257 },
      { value: 'KH', name: 'Cambodja', code: 855 },
      { value: 'CM', name: 'Kameroen', code: 237 },
      { value: 'CA', name: 'Canada', code: 1 },
      { value: 'CV', name: 'Kaapverdië', code: 238 },
      { value: 'KY', name: 'Kaaimaneilanden', code: 1345 },
      { value: 'CF', name: 'Centraal-Afrikaanse Republiek', code: 236 },
      { value: 'TD', name: 'Tsjaad', code: 235 },
      { value: 'CL', name: 'Chili', code: 56 },
      { value: 'CN', name: 'China', code: 86 },
      { value: 'CX', name: 'Christmaseiland', code: 61 },
      { value: 'CC', name: 'Cocoseilanden', code: 61 },
      { value: 'CO', name: 'Colombia', code: 57 },
      { value: 'KM', name: 'Comoren', code: 269 },
      { value: 'CG', name: 'Congo, Volksrepubliek', code: 243 },
      { value: 'CD', name: 'Congo, Democratische Republiek', code: 242 },
      { value: 'CK', name: 'Cookeilanden', code: 682 },
      { value: 'CR', name: 'Costa Rica', code: 506 },
      { value: 'CI', name: 'Ivoorkust', code: 225 },
      { value: 'HR', name: 'Kroatië', code: 385 },
      { value: 'CU', name: 'Cuba', code: 53 },
      { value: 'CY', name: 'Cyprus', code: 357 },
      { value: 'CZ', name: 'Tsjechië', code: 420 },
      { value: 'DK', name: 'Denemarken', code: 45 },
      { value: 'DJ', name: 'Djibouti', code: 253 },
      { value: 'DM', name: 'Dominica', code: 1767 },
      { value: 'DO', name: 'Dominicaanse Republiek', code: 1809 },
      { value: 'EC', name: 'Ecuador', code: 593 },
      { value: 'EG', name: 'Egypte', code: 20 },
      { value: 'SV', name: 'El Salvador', code: 503 },
      { value: 'GQ', name: 'Equatoriaal-Guinea', code: 240 },
      { value: 'ER', name: 'Eritrea', code: 291 },
      { value: 'EE', name: 'Estland', code: 372 },
      { value: 'ET', name: 'Ethiopië', code: 251 },
      { value: 'FK', name: 'Falklandeilanden', code: 500 },
      { value: 'FO', name: 'Faeröer', code: 298 },
      { value: 'FJ', name: 'Fiji', code: 679 },
      { value: 'FI', name: 'Finland', code: 358 },
      { value: 'FR', name: 'Frankrijk', code: 33 },
      { value: 'GF', name: 'Frans-Guyana', code: 594 },
      { value: 'PF', name: 'Frans-Polynesië', code: 689 },
      { value: 'TF', name: 'Franse Zuidelijke Gebieden', code: 262 },
      { value: 'GA', name: 'Gabon', code: 241 },
      { value: 'GM', name: 'Gambia', code: 220 },
      { value: 'GE', name: 'Georgië', code: 995 },
      { value: 'DE', name: 'Duitsland', code: 49 },
      { value: 'GH', name: 'Ghana', code: 223 },
      { value: 'GI', name: 'Gibraltar', code: 350 },
      { value: 'GR', name: 'Griekenland', code: 30 },
      { value: 'GL', name: 'Groenland', code: 299 },
      { value: 'GD', name: 'Grenada', code: 1473 },
      { value: 'GP', name: 'Guadeloupe', code: 590 },
      { value: 'GU', name: 'Guam', code: 1671 },
      { value: 'GT', name: 'Guatemala', code: 502 },
      { value: 'GN', name: 'Guinea', code: 224 },
      { value: 'GW', name: 'Guinee-Bissau', code: 245 },
      { value: 'GY', name: 'Guyana', code: 592 },
      { value: 'HT', name: 'Haïti', code: 509 },
      { value: 'HM', name: 'Heard en McDonaldeilanden', code: 6723 },
      { value: 'VA', name: 'Heilige Stoel', code: 379 },
      { value: 'HN', name: 'Honduras', code: 504 },
      { value: 'HK', name: 'Hong Kong', code: 852 },
      { value: 'HU', name: 'Hongarije', code: 36 },
      { value: 'IS', name: 'IJsland', code: 354 },
      { value: 'IN', name: 'India', code: 91 },
      { value: 'ID', name: 'Indonesia', code: 62 },
      { value: 'IR', name: 'Iran', code: 98 },
      { value: 'IQ', name: 'Irak', code: 964 },
      { value: 'IE', name: 'Ierland', code: 353 },
      { value: 'IL', name: 'Israël', code: 972 },
      { value: 'IT', name: 'Italië', code: 39 },
      { value: 'JM', name: 'Jamaica', code: 1879 },
      { value: 'JP', name: 'Japan', code: 81 },
      { value: 'JO', name: 'Jordanië', code: 962 },
      { value: 'KZ', name: 'Kazachstan', code: 7 },
      { value: 'KE', name: 'Kenia', code: 254 },
      { value: 'KI', name: 'Kiribati', code: 686 },
      { value: 'XK', name: 'Kosovo', code: 383 },
      { value: 'KP', name: 'Noord-Korea, Democratische Volksrepubliek', code: 850 },
      { value: 'KR', name: 'Zuid-Korea', code: 82 },
      { value: 'KW', name: 'Koeweit', code: 965 },
      { value: 'KG', name: 'Kirgizstan', code: 996 },
      { value: 'LA', name: 'Laos', code: 856 },
      { value: 'LV', name: 'Letland', code: 371 },
      { value: 'LB', name: 'Libanon', code: 961 },
      { value: 'LS', name: 'Lesotho', code: 266 },
      { value: 'LR', name: 'Liberia', code: 231 },
      { value: 'LY', name: 'Libië', code: 218 },
      { value: 'LI', name: 'Liechtenstein', code: 423 },
      { value: 'LT', name: 'Litouwen', code: 370 },
      { value: 'LU', name: 'Luxemburg, Groot-Hertogdom', code: 352 },
      { value: 'MO', name: 'Macao', code: 853 },
      { value: 'MK', name: 'Macedonië, Ex-Joegoslavische Republiek', code: 389 },
      { value: 'MG', name: 'Madagaskar', code: 261 },
      { value: 'MW', name: 'Malawi', code: 265 },
      { value: 'MY', name: 'Maleisië', code: 60 },
      { value: 'MV', name: 'Maldiven', code: 960 },
      { value: 'ML', name: 'Mali', code: 223 },
      { value: 'MT', name: 'Malta', code: 356 },
      { value: 'MH', name: 'Marshalleilanden', code: 692 },
      { value: 'MQ', name: 'Martinique', code: 596 },
      { value: 'MR', name: 'Mauritanië', code: 222 },
      { value: 'MU', name: 'Mauritius', code: 230 },
      { value: 'YT', name: 'Mayotte', code: 262 },
      { value: 'MX', name: 'Mexico', code: 52 },
      { value: 'FM', name: 'Micronesië, Federale Staten', code: 691 },
      { value: 'MD', name: 'Moldavië', code: 373 },
      { value: 'MC', name: 'Monaco', code: 377 },
      { value: 'MN', name: 'Mongolië', code: 976 },
      { value: 'MS', name: 'Montserrat', code: 1664 },
      { value: 'MA', name: 'Marokko', code: 212 },
      { value: 'MZ', name: 'Mozambique', code: 258 },
      { value: 'MM', name: 'Myanmar', code: 95 },
      { value: 'NA', name: 'Namibië', code: 264 },
      { value: 'NR', name: 'Nauru', code: 674 },
      { value: 'NP', name: 'Nepal', code: 977 },
      { value: 'NL', name: 'Nederland', code: 31 },
      { value: 'NC', name: 'Nieuw-Caledonië', code: 687 },
      { value: 'NZ', name: 'Nieuw-Zeeland', code: 64 },
      { value: 'NI', name: 'Nicaragua', code: 505 },
      { value: 'NE', name: 'Niger', code: 227 },
      { value: 'NG', name: 'Nigeria', code: 234 },
      { value: 'NU', name: 'Niue', code: 683 },
      { value: 'NF', name: 'Norfolk', code: 672 },
      { value: 'MP', name: 'Noordelijke Marianen', code: 1670 },
      { value: 'NO', name: 'Noorwegen', code: 47 },
      { value: 'OM', name: 'Oman', code: 968 },
      { value: 'PK', name: 'Pakistan', code: 92 },
      { value: 'PW', name: 'Palau', code: 680 },
      { value: 'PS', name: 'Palestina', code: 970 },
      { value: 'PA', name: 'Panama', code: 507 },
      { value: 'PG', name: 'Papoea-Nieuw-Guinea', code: 675 },
      { value: 'PY', name: 'Paraguay', code: 595 },
      { value: 'PE', name: 'Peru', code: 51 },
      { value: 'PH', name: 'Filipijnen', code: 63 },
      { value: 'PN', name: 'Pitcairn', code: 64 },
      { value: 'PL', name: 'Polen', code: 48 },
      { value: 'PT', name: 'Portugal', code: 351 },
      { value: 'PR', name: 'Puerto Rico', code: 1787 },
      { value: 'QA', name: 'Qatar', code: 974 },
      { value: 'RE', name: 'Réunion', code: 262 },
      { value: 'RO', name: 'Roemenië', code: 40 },
      { value: 'RU', name: 'Rusland', code: 7 },
      { value: 'RW', name: 'Rwanda', code: 250 },
      { value: 'SH', name: 'Sint-Helena', code: 290 },
      { value: 'KN', name: 'Saint Kitts en Nevis', code: 1869 },
      { value: 'LC', name: 'Saint Lucia', code: 1758 },
      { value: 'PM', name: 'Saint-Pierre en Miquelon', code: 508 },
      { value: 'VC', name: 'Saint Vincent en de Grenadines', code: 1784 },
      { value: 'WS', name: 'Samoa', code: 685 },
      { value: 'SM', name: 'San Marino', code: 378 },
      { value: 'ST', name: 'São Tomé en Principe', code: 239 },
      { value: 'SA', name: 'Saudi-Arabië', code: 966 },
      { value: 'SN', name: 'Senegal', code: 221 },
      { value: 'SC', name: 'Seychellen', code: 248 },
      { value: 'SL', name: 'Sierra Leone', code: 232 },
      { value: 'SG', name: 'Singapore', code: 65 },
      { value: 'SK', name: 'Slowakije', code: 421 },
      { value: 'SI', name: 'Slovenië', code: 386 },
      { value: 'SB', name: 'Salomonseilanden', code: 677 },
      { value: 'SO', name: 'Somalië', code: 252 },
      { value: 'ZA', name: 'Zuid-Afrika', code: 27 },
      { value: 'GS', name: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden', code: 500 },
      { value: 'ES', name: 'Spanje', code: 34 },
      { value: 'LK', name: 'Sri Lanka', code: 94 },
      { value: 'SD', name: 'Soedan', code: 249 },
      { value: 'SR', name: 'Suriname', code: 597 },
      { value: 'SJ', name: 'Spitsbergen en Jan Mayen', code: 47 },
      { value: 'SZ', name: 'Ngwane, Koninkrijk Swaziland', code: 268 },
      { value: 'SE', name: 'Zweden', code: 46 },
      { value: 'CH', name: 'Zwitserland', code: 41 },
      { value: 'SY', name: 'Syrië', code: 963 },
      { value: 'TW', name: 'Taiwan', code: 886 },
      { value: 'TJ', name: 'Tadzjikistan', code: 992 },
      { value: 'TZ', name: 'Tanzania, Verenigde Republiek', code: 255 },
      { value: 'TH', name: 'Thailand', code: 66 },
      { value: 'TL', name: 'Timor Leste', code: 670 },
      { value: 'TG', name: 'Togo', code: 228 },
      { value: 'TK', name: 'Tokelau', code: 690 },
      { value: 'TO', name: 'Tonga', code: 676 },
      { value: 'TT', name: 'Trinidad en Tobago', code: 1868 },
      { value: 'TN', name: 'Tunesië', code: 216 },
      { value: 'TR', name: 'Turkije', code: 1649 },
      { value: 'TM', name: 'Turkmenistan', code: 993 },
      { value: 'TC', name: 'Turks- en Caicoseilanden', code: 1649 },
      { value: 'TV', name: 'Tuvalu', code: 688 },
      { value: 'UG', name: 'Oeganda', code: 256 },
      { value: 'UA', name: 'Oekraïne', code: 380 },
      { value: 'AE', name: 'Verenigde Arabische Emiraten', code: 971 },
      { value: 'GB', name: 'Verenigd Koninkrijk', code: 44 },
      { value: 'US', name: 'Verenigde Staten van Amerika', code: 1 },
      { value: 'UM', name: 'Ver afgelegen eilandjes van de Verenigde Staten', code: 246 },
      { value: 'UY', name: 'Uruguay', code: 598 },
      { value: 'UZ', name: 'Oezbekistan', code: 998 },
      { value: 'VU', name: 'Vanuatu', code: 678 },
      { value: 'VE', name: 'Venezuela', code: 58 },
      { value: 'VN', name: 'Vietnam', code: 84 },
      { value: 'VG', name: 'Maagdeneilanden, Britse', code: 1284 },
      { value: 'VI', name: 'Maagdeneilanden, Amerikaanse', code: 1340 },
      { value: 'WF', name: 'Wallis en Futuna', code: 681 },
      { value: 'EH', name: 'Westelijke Sahara', code: 212 },
      { value: 'YE', name: 'Jemen', code: 967 },
      { value: 'ZM', name: 'Zambia', code: 260 },
      { value: 'ZW', name: 'Zimbabwe', code: 263 },
      { value: 'AX', name: 'Åland', code: 358 },
      { value: 'BQ', name: 'Bonaire, Sint Eustatius en Saba', code: 599 },
      { value: 'CW', name: 'Curaçao', code: 599 },
      { value: 'GG', name: 'Guernsey', code: 441481 },
      { value: 'IM', name: 'Eiland Man', code: 441624 },
      { value: 'JE', name: 'Jersey', code: 441534 },
      { value: 'ME', name: 'Montenegro', code: 382 },
      { value: 'BL', name: 'Saint Barthélemy', code: 590 },
      { value: 'MF', name: 'Sint-Maarten (Frans deel)', code: 590 },
      { value: 'RS', name: 'Servië', code: 381 },
      { value: 'SX', name: 'Sint Maarten (Nederlands deel)', code: 1721 },
      { value: 'SS', name: 'Zuid-Soedan', code: 211 }
    ];

  private prioritisedCountries: Array<string> = ['NL', 'BE'];

  setPrioritizedCountries(countries: Array<string>) {
    this.prioritisedCountries = countries;
  }

  setCountries(countries: any) {
    // TODO: Blandi change into Object.entries after angular 7 upgrade
    Object['entries'](countries).forEach((keyVal: Array<any>) => {
      const index = this.list.map(ec => ec.value).indexOf(keyVal[0]);
      this.list[index].name = keyVal[1];
    });
  }

  // needed for kvk country mappings
  getNl() {
    return this.nl;
  }

  get() {
    let countriesList: Array<Country> = [];
    const priorCountriesList: Array<Country> = [];
    countriesList = JSON.parse(JSON.stringify(this.list));

    this.prioritisedCountries.forEach((countryValue: string) => {
      const priorCountry = countriesList.find((country: Country) =>
        (countryValue === country.value)
      );
      priorCountriesList.push(priorCountry);
    });

    countriesList.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;

      return 0;
    });

    priorCountriesList[priorCountriesList.length - 1]['highlighted'] = true;

    return priorCountriesList.concat(countriesList);
  }

  getUnprioritized() {
    return this.list;
  }
}
