<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n *ngIf="!tempPassMessage">Welcome! Please log in</h1>
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n="@@changePassword" *ngIf="tempPassMessage">Change password</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form [formGroup]="loginForm" class="auth-form" novalidate>
        <div *ngIf="firstLogin">
          <div class="notify_mail" *ngIf="!tempPassMessage">
            <span i18n="@@tempPasswordSent">A temporary password has been sent to your email address.</span>
          </div>
        </div>

        <ng-container *ngIf="!tempPassMessage">
          <div class="field__item">
            <label class="field__label field__label_contacts"
                   for="username" i18n="@@username">Username</label>
            <input #firstField type="text"
                   class="form-control input_field"
                   name="username"
                   bcLowercase
                   pattern="[\S]+"
                   data-tests-id="username"
                   id="username"
                   formControlName="username">
            <bc-messages [element]="loginForm.get('username')"></bc-messages>
          </div>

          <div class="field__item">
            <label class="field__label field__label_contacts" for="password">
              <ng-container *ngIf="firstLogin" i18n="@@temporaryPassword">Temporary Password</ng-container>
              <ng-container *ngIf="!firstLogin" i18n="@@password">Password</ng-container>
            </label>
            <div class="input-group">
              <input #secondField type="password"
                     class="form-control input_field"
                     name="password"
                     id="password"
                     data-tests-id="login-password"
                     placeholder="********"
                     formControlName="password"
                     bcShowPassword
                     [showPasswordState]="viewPass">
              <div class="input-group-addon" (click)="viewPass = !viewPass" tabindex="-1">
            <span class="glyphicon"
                  [ngClass]="viewPass == true ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"></span>
              </div>
            </div>
            <bc-messages [element]="loginForm.get('password')"></bc-messages>
          </div>
        </ng-container>

        <div *ngIf="tempPassMessage">
          <div class="warning-block">
            <div class="warning-block_icon">
              <svg class="svg-icon" width="28" height="25">
                <use xlink:href="#icon-warning"></use>
              </svg>
            </div>
            <div class="warning-block_content">
              <p class="main-text main-text_warm-grey">
                <strong class="h4-header h4-header_charcoal">
                  <ng-container i18n="@@attention">Notice</ng-container>:&nbsp;</strong>
                <ng-container i18n="@@setNewPassword">To keep your account secure, please set a new password.
                </ng-container>
              </p>
            </div>
          </div>

          <div class="field__item">
            <label class="field__label field__label_contacts"
                   for="newPassword" i18n="@@newPassword">New password</label>
            <div class="input-group">
              <input type="password"
                     class="form-control input_field"
                     data-tests-id="new-password"
                     name="new-password"
                     id="newPassword"
                     formControlName="newPassword"
                     bcShowPassword
                     [showPasswordState]="viewChangePass">
              <div class="input-group-addon" (click)="viewChangePass = !viewChangePass" tabindex="-1">
              <span class="glyphicon"
                    [ngClass]="viewChangePass == true ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"></span>
              </div>
            </div>
            <bc-messages [element]="loginForm.get('newPassword')"></bc-messages>
          </div>

          <div class="field__item">
            <label class="field__label field__label_contacts"
                   for="confirm-password" i18n="@@confirmNewPassword">Confirm new password</label>
            <div class="input-group">
              <input type="password"
                     class="form-control input_field"
                     data-tests-id="confirm-password"
                     name="confirm-password"
                     id="confirm-password"
                     formControlName="confirmPassword"
                     bcShowPassword
                     [showPasswordState]="viewChangeConfPass">
              <div class="input-group-addon" (click)="viewChangeConfPass = !viewChangeConfPass" tabindex="-1">
              <span class="glyphicon"
                    [ngClass]="viewChangeConfPass == true ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"></span>
              </div>
            </div>
            <bc-messages [element]="loginForm.get('confirmPassword')"></bc-messages>
          </div>
        </div>

        <button i18n="@@logIn" *ngIf="!tempPassMessage"
                class="btn bc-btn bc-btn_block bc-btn_custom"
                data-tests-id="login-button"
                type="submit"
                name="submit"
                (click)="onSubmit(loginForm)"
                [disabled]="loginForm.invalid">Log in
        </button>
        <button i18n="@@changePassword" *ngIf="tempPassMessage"
                class="btn bc-btn bc-btn_block bc-btn_custom"
                data-tests-id="change-password-button"
                type="submit"
                name="submit"
                (click)="onSubmit(loginForm)"
                [disabled]="loginForm.invalid">Change Password
        </button>

        <div class="row auth-form__link" *ngIf="!tempPassMessage">
          <div class="col-sm-6">
            <a class="u-unstyled-btn bc-btn-link" routerLink='/account-type' i18n="@@createAccount">Create account</a>
          </div>
          <div class="col-sm-6 text-right">
            <a class="bc-btn-link" routerLink='/restorePassword' *ngIf="!firstLogin" i18n>Forgot your password?</a>
          </div>
          <div class="col-sm-6 text-right" *ngIf="firstLogin">
            <a class="bc-btn-link pointer" (click)="resendPassword()" i18n="@@resendPassword">Resend password</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
