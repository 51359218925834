<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="IDENTIFICATION" i18n="@@identificationDetails">Identification</bc-message>
  <bc-message key="ADDRESS" i18n="@@addressDetails">Address</bc-message>
  <bc-message key="ADDITIONAL" i18n="@@additionalDetails">Additional details</bc-message>
  <bc-message key="SPECIFIC_SITUATIONS" i18n="@@specificSituations">Specific situations</bc-message>
  <bc-message key="BASIC" i18n="@@basicDetails">Basic details</bc-message>
  <bc-message key="ORIGIN_OF_ASSETS" i18n>Origin of assets</bc-message>

  <bc-message key="REGISTER_TITLE" i18n="@@registerTitle">Register</bc-message>
  <bc-message key="LOGIN_TITLE" i18n="@@loginTitle">Login</bc-message>

  <bc-message key="EVIDOS_STATUS_101" i18n>Invitation sent</bc-message>
  <bc-message key="EVIDOS_STATUS_102" i18n>Received</bc-message>
  <bc-message key="EVIDOS_STATUS_103" i18n>Opened</bc-message>
  <bc-message key="EVIDOS_STATUS_104" i18n>Reminder sent</bc-message>
  <bc-message key="EVIDOS_STATUS_105" i18n>Document opened</bc-message>
  <bc-message key="EVIDOS_STATUS_201" i18n>Cancelled</bc-message>
  <bc-message key="EVIDOS_STATUS_202" i18n>Rejected</bc-message>
  <bc-message key="EVIDOS_STATUS_203" i18n>Signed</bc-message>
  <bc-message key="EVIDOS_STATUS_301" i18n>Signed document sent</bc-message>
  <bc-message key="EVIDOS_STATUS_302" i18n>Signed document opened</bc-message>
  <bc-message key="EVIDOS_STATUS_303" i18n>Signed document downloaded</bc-message>
  <bc-message key="EVIDOS_STATUS_401" i18n>Receipt sent</bc-message>
  <bc-message key="EVIDOS_STATUS_402" i18n>Receipt opened</bc-message>
  <bc-message key="EVIDOS_STATUS_403" i18n>Receipt downloaded</bc-message>
  <bc-message key="EVIDOS_STATUS_500" i18n>Finished</bc-message>
  <bc-message key="EVIDOS_STATUS_600" i18n>Deleted</bc-message>
  <bc-message key="EVIDOS_STATUS_700" i18n>Expired</bc-message>
  <bc-message key="EVIDOS_STATUS_999" i18n="@@failed">Failed</bc-message>

  <bc-message key="NOTIFICATION_SUCCESS" i18n="@@recieveNotificationOn">You will recieve a notification on</bc-message>
  <bc-message key="UPDATE_SUCCESS" i18n="@@updateSuccess">Registration updated successfully.</bc-message>
</bc-catch-messages>


<bc-header></bc-header>

<div class="content__wrap">
  <router-outlet></router-outlet>
</div>
