import { Directive, OnInit, OnDestroy } from '@angular/core';

import { NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';


@Directive({
  selector: '[bcLowercase]'
})
export class BcLowercaseDirective implements OnInit, OnDestroy {
  private disposeValueChanges: Subscription;

  constructor(private ngControl: NgControl) { }

  ngOnInit() {
    this.disposeValueChanges = this.ngControl.valueChanges.pipe(
      distinctUntilChanged(),
      map((v: string) => v.toLowerCase()),
      tap((v: string) => this.ngControl.control.setValue(v))
    ).subscribe();

    if (this.ngControl.control.value) {
      const initFormatted = this.ngControl.control.value.toLowerCase();
      this.ngControl.control.setValue(initFormatted);
    }
  }

  ngOnDestroy() {
    this.disposeValueChanges.unsubscribe();
  }
}
