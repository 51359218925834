<div class="container">
  <div class="row heading_row content_row"></div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <div class="error__wrap text-center">
        <svg class="error__icon" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 471.2 471.2" width="100" height="100">

             <path d="M227.619 444.2h-122.9c-33.4 0-60.5-27.2-60.5-60.5V87.5c0-33.4 27.2-60.5 60.5-60.5h124.9c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5h-124.9c-48.3 0-87.5 39.3-87.5 87.5v296.2c0 48.3 39.3 87.5 87.5 87.5h122.9c7.5 0 13.5-6 13.5-13.5s-6.1-13.5-13.5-13.5z"/>
             <path d="M450.019 226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1 0-5.3 5.3-5.3 13.8 0 19.1l62.8 62.8h-273.9c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h273.9l-62.8 62.8c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.4-5.4 5.4-14 .1-19.2z"/>
        </svg>

        <h2 class="error__header h2-header h2-header_charcoal" i18n="@@loggedOutMessage">You have been logged out.</h2>
      </div>
    </div>
  </div>
</div>
