import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { ToastrModule } from 'ngx-toastr';
import { BcMessagesModule, BcSpinnerModule } from '../../../widgets';
import { ObligatoryMfaSetupComponent } from './obligatory-mfa-setup/obligatory-mfa-setup.component';
import { SoftwareMfaRegisterModalComponent } from './software-mfa-register/software-mfa-register-modal.component';
import { OptionalMfaSetupComponent } from './optional-mfa-setup/optional-mfa-setup.component';
import { SoftwareMfaQuestionModalComponent } from './software-mfa-question/software-mfa-question-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    BcMessagesModule,
    BcSpinnerModule,
    QRCodeModule
  ],
  declarations: [
    ObligatoryMfaSetupComponent,
    OptionalMfaSetupComponent,
    SoftwareMfaQuestionModalComponent,
    SoftwareMfaRegisterModalComponent
  ],
  exports: [
    OptionalMfaSetupComponent
  ]
})
export class SoftwareMfaModule {}
