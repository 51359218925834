import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService, SurveysService, SurveyType } from '../services/';
import { ProcessorsService } from '../../custodian/processors.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorSurveyGuard  {

  constructor(private registrationService: RegistrationService,
              private processorsService: ProcessorsService,
              private surveysService: SurveysService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return this.processorsService.resolve().then(() => {
      if (!this.processorsService.surveyProcessors.length) {
        this.router.navigate(['/dashboard']);

        return false;
      }

      return this.surveysService.getSurveyHistory(this.registrationService.registrationId, SurveyType.PROCESSOR).then(res => {
        if (res.length) this.patchSurveyDetails(res);

        return true;
      });
    }).catch(() => {
      this.router.navigate(['/dashboard']);

      return false;
    });
  }

  private patchSurveyDetails(history) {
    this.processorsService.surveyProcessors.forEach(processor => {
      processor.surveys.map(survey => {
        const surveyHistory = history.find(item => item.form_id === survey.form_id);

        if (surveyHistory) survey.response = surveyHistory.survey.response;

        return survey;
      });
    });
  }
}
