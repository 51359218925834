<div class="container">
  <div class="row heading_row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h1 class="h1-header h1-header_light h1-header_charcoal text-center"
          i18n="@@verificationCode">Verification code</h1>
    </div>
  </div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form [formGroup]="confirmMFAForm" class="auth-form" (ngSubmit)="onSubmit(confirmMFAForm)" novalidate>
        <div class="field__item msg_about_sms">
          <span *ngIf="mfaType === 'SOFTWARE'" i18n="@@softMFAMsg">Please enter the 6 digit number from the authenticator app.</span>
          <span *ngIf="mfaType === 'SMS'" i18n="@@smsSentMsg1">We have sent a message to {{ phoneNumber }} with a verification code.</span><br/>
          <span *ngIf="mfaType === 'SMS'" i18n="@@smsSentMsg2">Please enter the 6 digit number here.</span>
        </div>

        <div class="field__item text-center">
          <input type="text"
              class="form-control input_field"
              name="MFAcode"
              id="MFAcode"
              autofocus
              formControlName="MFAcode"
              (paste)="onPaste($event)">
          <bc-messages [element]="confirmMFAForm.get('MFAcode')"></bc-messages>
        </div>

        <button i18n="@@submit"
                class="btn bc-btn bc-btn_block bc-btn_custom"
                type="submit"
                name="submit"
                [disabled]="!confirmMFAForm.valid">Submit</button>
      </form>
    </div>
  </div>
</div>
<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="CONFIRM_CODE_WRONG" i18n>Verification code wrong. </bc-message>
  <bc-message key="CONFIRM_EXPIRED" i18n>Verification code expired. You will be redirected to </bc-message>
  <bc-message key="LOGIN" i18n>login</bc-message>
</bc-catch-messages>
