<ul class="u-unstyled-list error-message" *ngIf="element.errors && element.dirty">
  <li *ngIf="element.errors.required" i18n="error messages">This field is required</li>
  <li *ngIf="element.errors.signerOrUbo" i18n="@@errorSignerOrUbo">Select at least one of the options above</li>

  <li *ngIf="element.errors.minNumber && element.errors.minNumber.value !== 0">
    <ng-container i18n="error messages">Minimum value</ng-container> -
    <ng-container [ngSwitch]="element.errors.minNumber.format">
      <span *ngSwitchCase="'currency'"> {{ element.errors.minNumber.min | currency:'EUR':'symbol':'1.0-2' }}.</span>
      <span *ngSwitchCase="'percentage'"> {{ element.errors.minNumber.min }}%.</span>
      <span *ngSwitchDefault> {{ element.errors.minNumber.min }}.</span>
    </ng-container>
  </li>

  <li *ngIf="element.errors.maxNumber">
    <ng-container i18n="error messages">Maximum value</ng-container> - {{ element.errors.maxNumber.max }}
  </li>

  <li *ngIf="element.errors.userNotExist" i18n="error messages">The username-password combination you have entered is incorrect</li>

  <li *ngIf="element.errors.userNotConfirmed">
    <ng-container i18n="@@userNotConfirmedError">User is not confirmed, confirmation code will be sent by sms. </ng-container>
    <a i18n="error messages" [routerLink]="['/signup/confirm']" [queryParams]="{username: element.errors.userNotConfirmed.username}">Enter confirmation code</a>
  </li>

  <li *ngIf="element.errors.minlength" i18n="@@minLengthError">
    The entered data must be at least {{ element.errors.minlength.requiredLength }} characters long
  </li>

  <li *ngIf="element.errors.pattern" i18n="@@errorpattern">Field contains invalid character(s)</li>
  <li *ngIf="element.errors.maxlength" i18n="error messages">The entered data is too long</li>
  <li *ngIf="element.errors.reqNumber" i18n="error messages">You have to include at least one Number</li>
  <li *ngIf="element.errors.reqSpecial" i18n="error messages">You have to include at least one special character</li>
  <li *ngIf="element.errors.reqUpperCase" i18n="error messages">You have to include at least one Upper Case letter</li>
  <li *ngIf="element.errors.reqLowerCase" i18n="error messages">You have to include at least one Lower Case letter</li>
  <li *ngIf="element.errors.cantResetPassword" i18n="error messages">If the username exists, the verification code has been send</li>
  <li *ngIf="element.errors.wrongPassword" i18n="error messages">The username-password combination you have entered is incorrect</li>
  <li *ngIf="element.errors.confirmPassword" i18n="error messages">Repeated password must be equal to new password</li>
  <li *ngIf="element.errors.invalidConfirmCode" i18n="error messages">Verification code wrong. </li>
  <li *ngIf="element.errors.invalidPostcode" i18n="error messages@@invalidPostcode">Invalid postal code or house number.</li>
  <li *ngIf="element.errors.confirmCodeExpired">
    <ng-container i18n="error messages">Verification code expired. You will be redirected to </ng-container>
    <a routerLink="/login" i18n="error messages">login</a>
  </li>
  <li *ngIf="element.errors.restoreConfirmCodeWrong">
    <ng-container i18n="error messages">Verification code wrong. </ng-container>
    <a routerLink="/restorePassword" i18n="error messages">Request another one</a>
  </li>
  <li *ngIf="element.errors.attemptsLimit" i18n="error messages">Attempt limit exceeded, please try after some time</li>


  <li *ngIf="element.errors.reqEmail" i18n="error messages">Please, provide a valid email</li>
  <li *ngIf="element.errors.optionalDate" i18n="error messages@@dateFormatError">Please, provide date in standard: dd-mm-yyyy</li>
  <li *ngIf="element.errors.reqMobilePhone" i18n="error messages">Please fill in a valid mobile phone number in international standard format</li>
  <li *ngIf="element.errors.reqLandOrMobilePhone" i18n="error messages">Please fill in a valid phone number in the international format</li>
  <li *ngIf="element.errors.fileSize" i18n="error messages">The selected file is too large (10Mb)</li>
  <li *ngIf="element.errors.fileType" i18n="error messages">The selected file type is not valid</li>

  <li *ngIf="element.errors.alphanumericTINNP" i18n="NP TIN | error messages">Invalid Tax Identification Number, only use A-Z & 0-9</li>
  <li *ngIf="element.errors.alphanumericTINLE" i18n="LE TIN | error messages">Invalid Tax Identification Number, only use A-Z & 0-9</li>
  <li *ngIf="element.errors.alphanumericBAN" i18n="error messages">Invalid Bank Account Number, only use A-Z & 0-9</li>
  <li *ngIf="element.errors.invalidIBAN" i18n="error messages">Please, provide a valid IBAN number</li>
  <li *ngIf="element.errors.invalidLEI" i18n="error messages@@invalidLei">Please provide a valid LEI code</li>

  <li *ngIf="element.errors.fileName" i18n="error messages">Filename has unsupported character: '{{ element.errors.fileName.wrong_character }}'</li>
  <li *ngIf="element.errors.invalidCountryName" i18n="error messages">Please select a country from the list</li>
</ul>
