<header class="navbar">
  <div class="navbar__container-fluid container-fluid">
    <div class="navbar__logo main-logo" *ngIf="!(user | async)"></div>

    <a class="navbar__logo main-logo" *ngIf="user | async" routerLink="/dashboard"></a>

    <div class="profile-menu hidden-xs" *ngIf="user | async">
      <button type="button" class="profile-menu__button">
        <span class="h4-header h4-header_charcoal profile-menu__username">
          {{ user.value.name.full }}
        </span>
        <span class="profile-menu__avatar">
          <span class="glyphicon glyphicon-user"></span>
        </span>
      </button>

      <ul class="nav-menu nav-menu_sub-menu profile-menu__menu">
        <li class="nav-menu_sub-menu__item" *ngIf="!isIntegratedPortalClient">
          <a class="nav-menu_sub-menu__link icon-link"
             routerLink="/profile-settings"
             routerLinkActive="nav-menu_sub-menu__link_active">
            <svg class="nav-menu__icon icon-link__icon svg-icon" width="19" height="19">
              <use xlink:href="#settings-icon"></use>
            </svg>
            <ng-container i18n="@@userSettingsLabel">User Settings</ng-container>
          </a>
        </li>
        <li class="nav-menu_sub-menu__item">
          <button type="button" class="u-unstyled-btn nav-menu_sub-menu__link icon-link" (click)="logout()">
            <svg class="nav-menu__icon icon-link__icon svg-icon" width="18" height="18">
              <use xlink:href="#person-icon"></use>
            </svg>
            <ng-container i18n="@@logout">Logout</ng-container>
          </button>
        </li>
      </ul>
    </div>

    <div class="language-menu hidden-xs">
      <button type="button" class="language-menu__button">
        <span class="h4-header h4-header_charcoal language-menu__text" i18n="@@currentLocale">
          English
        </span>
        <span class="spacing"></span>
      </button>

      <ul class="nav-menu nav-menu_sub-menu profile-menu__menu">
        <ng-container *ngFor="let l of locales">
          <li class="nav-menu_sub-menu__item" *ngIf="locale !== l.value">
            <button class="u-unstyled-btn nav-menu_sub-menu__link icon-link" type="button" (click)="switchLocale(l.value)">
              {{ l.name }}
            </button>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="mobile-menu visible-xs-block">
      <button type="button" class="u-unstyled-btn mobile-menu__toggle-btn" bcDropdownToggle>
        <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
      </button>

      <ul class="nav-menu_sub-menu u-unstyled-list">
        <li class="nav-menu_sub-menu__item" *ngIf="(user | async) && !isIntegratedPortalClient">
          <a class="nav-menu_sub-menu__link icon-link"
             routerLink="/profile-settings"
             routerLinkActive="nav-menu_sub-menu__link_active">
            <svg class="nav-menu__icon icon-link__icon svg-icon" width="19" height="19">
              <use xlink:href="#settings-icon"></use>
            </svg>
            <ng-container i18n="@@userSettingsLabel">User Settings</ng-container>
          </a>
        </li>
        <li class="nav-menu_sub-menu__item" *ngIf="user | async">
          <button type="button" class="u-unstyled-btn nav-menu_sub-menu__link icon-link" (click)="logout()">
            <svg class="nav-menu__icon icon-link__icon svg-icon" width="18" height="18">
              <use xlink:href="#person-icon"></use>
            </svg>
            <ng-container i18n="@@logout">Logout</ng-container>
          </button>
        </li>
        <li class="nav-menu_sub-menu__item language-menu_item">
          <ng-container *ngFor="let l of locales">
            <button class="u-unstyled-btn language-menu_link text-link text-link_custom"
               *ngIf="locale !== l.value" type="button" (click)="switchLocale(l.value)">
              {{ l.value.substring(0, 2) }}
            </button>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</header>
