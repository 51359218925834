import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { TokenHelper, UsersGatewayService } from '@blancoservices/bc-auth-web';

import { Logger } from '../../core/services';
import { UserLoginService } from '../../core/services/cognito.service';
import { BcSpinnerService, BcMessagesManager } from '../../widgets';

import { catchError } from 'rxjs/operators';
import { throwError, Subscription } from 'rxjs';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'bc-confirm-code-component',
  templateUrl: 'confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmCodeComponent implements OnInit, OnDestroy {
  confirmMFAForm: UntypedFormGroup;
  currentYear: number;
  mfaType: string;
  phoneNumber: string;
  valueSubscription: Subscription;

  constructor(
    public router: Router,
    private logger: Logger,
    private spinner: BcSpinnerService,
    private usersGatewayService: UsersGatewayService,
    private toastr: ToastrService,
    public bcMessagesManager: BcMessagesManager,
  ) {
    this.currentYear = new Date().getFullYear();
    this.confirmMFAForm = new UntypedFormGroup({
      MFAcode: new UntypedFormControl(null, Validators.required)
    });
    this.valueSubscription = this.confirmMFAForm.get('MFAcode').valueChanges.subscribe((value) => {
      // eslint-disable-next-line no-magic-numbers
      if (value && value.length === 6) {
        this.onSubmit(this.confirmMFAForm, value);
      }
    });
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }

  ngOnInit() {
    if (TokenHelper.isAccesTokenValid()) this.router.navigate([UserLoginService.redirectUser()]);

    if (UserLoginService.savedCognitoSession) {
      this.mfaType = UserLoginService.savedCognitoSession.mfaType;
      const phoneNumber = UserLoginService.savedCognitoSession.phone;
      if (phoneNumber) this.phoneNumber = phoneNumber.replace(/(\d{2})(.*)(\d{3})/, '$1******$3');
    }
  }

  onPaste(event: ClipboardEvent) {
    // find the first 6 numbers from the end of the string
    const text = event.clipboardData.getData('text');
    const findNumber = text.split('').reverse().join('').match(/\d{6}/);
    const value = findNumber ? findNumber[0].split('').reverse().join('') : '';
    this.confirmMFAForm.setValue({MFAcode: value});
    event?.preventDefault();
  }

  onSubmit(form: UntypedFormGroup, value = null) {
    const timeoutValue = 5000;

    this.spinner.set(true);
    this.usersGatewayService.completeMFAChallenge({
      ...UserLoginService.savedCognitoSession,
      mfaCode: value || form.value.MFAcode
    }).pipe(catchError((response: HttpErrorResponse) => {
      this.spinner.set(false);
      const errorType = response.error.errorType,
            message = response.error.errorMessage;
      switch (errorType) {
        case 'CodeMismatchException':
          this.toastr.error(this.bcMessagesManager.translations['CONFIRM_CODE_WRONG']);
          break;
        case 'NotAuthorizedException':
          this.toastr.error(`${this.bcMessagesManager.translations['CONFIRM_EXPIRED']} ${this.bcMessagesManager.translations['LOGIN']}`);
          setTimeout(() => {
            this.router.navigate([UserLoginService.redirectUser()]);
          }, timeoutValue);
          break;
        default:
      }
      this.logger.showError(message);

      return throwError(response);
    }))
      .subscribe((result: any) => {
        UserLoginService.redirectClients(result.idToken.payload, result.refreshToken.token);
        this.router.navigate([UserLoginService.redirectUser()]);
        this.spinner.set(false);
      });
  }
}
