import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SettingsService, RegistrationService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class RegionSettingsResolverService  {
  constructor(private settingsService: SettingsService, private registrationService: RegistrationService) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.settingsService.getRegionSettings(this.registrationService.tenantId);
  }
}
