import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UsersGatewayService } from '@blancoservices/bc-auth-web';

import { UserService, UserLoginService, RegistrationService, BcModalService, SettingsService } from '../../services';
import { BcSpinnerService } from '../../../widgets';
import { Countries } from '../../../translations/countries-translations/country.service';
import { LOCALES } from '../../../shared/models/locales.enum';
import { environment } from '@env/environment';


@Component({
  selector: 'bc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: string;
  user: BehaviorSubject<User>;
  locale: string;
  locales = [
    { value: LOCALES.EN_US, name: 'English'},
    { value: LOCALES.NL_NL, name: 'Nederlands'},
    { value: LOCALES.FR_FR, name: 'Français'},
    { value: LOCALES.DE_DE, name: 'Deutsch'},
  ];
  get isIntegratedPortalClient(): boolean {
    return this.userService.isIntegratedPortalClient;
  }

  constructor(@Inject(LOCALE_ID) private localeId: string,
              private router: Router,
              private usersGateway: UsersGatewayService,
              private userLoginService: UserLoginService,
              private registrationService: RegistrationService,
              private userService: UserService,
              private countries: Countries,
              private settings: SettingsService,
              private bcSpinnerService: BcSpinnerService,
              private bcModalService: BcModalService) { }

  ngOnInit() {
    this.locale = this.localeId;
    this.user = this.userService.user;
    this.bcSpinnerService.set(true);

    this.registrationService.tenantId$.pipe(
      switchMap((tenantId: string) => tenantId ? this.settings.getRegionSettings(tenantId) : of({}))
    ).subscribe(({allowedRegions, allowedLocales}: InternationalisationSettings) => {
      if (!allowedLocales) return;
      this.countries.setPrioritizedCountries(allowedRegions);
      this.locales = this.locales.filter((locale: any) => allowedLocales.includes(locale.value));
      this.checkLocaleAvailability();
      this.bcSpinnerService.set(false);
    });
  }

  logout() {
    const questions = [$localize `Are you sure you want to log out?`];
    const confirmationModal = this.bcModalService.openConfirmationBox(questions);

    if (this.userService.username.getValue()) {
      this.userService.username.next('');
    }

    confirmationModal.instance.close.subscribe(() => confirmationModal.destroy());
    confirmationModal.instance.yes.subscribe(() => {
      confirmationModal.destroy();
      const redirect = this.isIntegratedPortalClient ? '/logged-out' : '/login';

      this.userLoginService.logout().subscribe(() => this.router.navigate([redirect]));
    });
    confirmationModal.instance.no.subscribe(() => {
      confirmationModal.destroy();
    });
  }

  switchLocale(locale: string) {
    /* eslint-disable no-magic-numbers */
    const monthAfterToday = new Date(Date.now() + 2592000000);
    const hostDomain = document.location.host.split('.').slice(-2).join('.');
    /* eslint-enable no-magic-numbers */

    document.cookie = `locale=${locale}; expires=${monthAfterToday.toUTCString()}; domain=${hostDomain}; Path=/;`;

    // TODO: find better solution
    if (location.pathname === '/confirm') {
      this.router.navigate(['login']);
    }

    setTimeout(() => location.reload());
  }

  private checkLocaleAvailability() {
    const isLocaleAllowed = this.locales.find(locale => this.localeId === locale.value);

    // Switch to default locale in prd|acc only, to avoid redirect loop in local environment
    if (!isLocaleAllowed && (environment.ENV === 'prd' || environment.ENV === 'acc')) {
      this.switchLocale(this.locales[0].value);
    }
  }
}
