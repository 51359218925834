import { Component, OnInit, Renderer2, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { UsersGatewayService, LoginInput, TokenHelper } from '@blancoservices/bc-auth-web';

import { CustomValidators } from '../../shared/validators/custom.validators';
import { Logger, TitleService, UserService, UserLoginService, TenantService } from '../../core/services';
import { BcSpinnerService, BcMessagesManager } from '../../widgets';
import { BackendErrorModel, ErrorCodes, ServerStatuses } from '../../shared/models/error-handling';

import { catchError } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';


@Component({
  selector: 'bc-login-component',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewChecked, AfterViewInit {
  tempPassMessage = false;

  loginForm: UntypedFormGroup;
  currentYear: number;
  firstLogin = false;
  viewPass: boolean;

  authData: LoginInput;

  changePassAdded = false;
  @ViewChild('firstField', { static: true }) firstField: ElementRef;
  @ViewChild('secondField', { static: true }) secondField: ElementRef;

  constructor(
    private titleService: TitleService,
    private userService: UserService,
    private tenantService: TenantService,
    private usersGateway: UsersGatewayService,
    private toastr: ToastrService,
    private logger: Logger,
    public router: Router,
    private route: ActivatedRoute,
    private bcSpinner: BcSpinnerService,
    public bcMessagesManager: BcMessagesManager,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {
    this.currentYear = new Date().getFullYear();

    this.initForm();
    this.prefillUsername();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.username) this.loginForm.get('username').patchValue(params.username);
    });

    if (TokenHelper.isAccesTokenValid()) this.router.navigate([UserLoginService.redirectUser()]);
  }

  // TODO: check if these focus hack's work or should we improve/delete these
  ngAfterViewInit() {
    if (this.firstLogin) {
      this.secondField.nativeElement.focus();
    } else {
      this.firstField.nativeElement.focus();
    }

    this.cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    if (this.changePassAdded) {
      this.renderer.selectRootElement('#newPassword').focus();
      this.changePassAdded = false;
      this.cdRef.detectChanges();
    }
  }

  onSubmit(form: UntypedFormGroup) {
    if (!this.loginForm.valid) return;

    this.bcSpinner.set(true);

    const formValues = form.getRawValue();

    const updatedData: LoginInput = { userName: '', password: ''};
    if (formValues.username) updatedData.userName = formValues.username;
    if (formValues.password) updatedData.password = formValues.password;
    if (formValues.newPassword) updatedData.newPassword = formValues.newPassword;

    sessionStorage.setItem('username', form.getRawValue().username);

    this.authData = {
      ...this.authData,
      ...updatedData,
      tenantId: this.tenantService.tenantData.tenantId
    };

    this.usersGateway.login(this.authData).pipe(catchError((response: HttpErrorResponse) => {
      this.bcSpinner.set(false);

      return this.loginErrorHandling(response);
    })).subscribe((result: any) => {
      this.bcSpinner.set(false);

      if (result.mfaType) {
       this.redirectToMFA(formValues.username, result);
      } else {
        UserLoginService.redirectClients(result.idToken.payload, result.refreshToken.token);
        this.router.navigate([UserLoginService.redirectUser()]);
      }
    });
  }

  resendPassword() {
    this.usersGateway.resendTempPassword({ userName: this.loginForm.getRawValue().username})
    .pipe(catchError((response: HttpErrorResponse) => {
      const message = response.error.errorMessage;
      this.logger.showError(message);

      return throwError(response);
    }))
    .subscribe(() => {
      this.toastr.info($localize`:@@tempPasswordSent:A temporary password has been sent to your email address.`);
    });
  }

  private initForm() {
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required)
    }, this.validateForm.bind(this));
  }

  private validateForm(form: UntypedFormGroup) {
    if ((form.value['newPassword']) && (form.get('newPassword').value !== form.get('confirmPassword').value)) {
      form.controls['confirmPassword'].setErrors({confirmPassword: true});

      return {mismatch: true};
    }

    if (form.controls['username'].hasError('wrongPassword') || form.controls['password'].hasError('wrongPassword')) {
      form.controls['username'].setErrors(null);
      form.controls['password'].setErrors(null);
    }

    return null;
  }

  private updateLoginForm() {
    const minPassLength = 8, maxPassLength = 99;
    this.loginForm.get('username').disable();
    this.loginForm.get('password').disable();

    this.loginForm.addControl('newPassword', new UntypedFormControl(null, Validators.compose([
      Validators.required,
      Validators.minLength(minPassLength),
      Validators.maxLength(maxPassLength),
      CustomValidators.reqNumber(),
      CustomValidators.reqSpecial(),
      CustomValidators.reqUpperCase(),
      CustomValidators.reqLowerCase()
    ])));
    this.loginForm.addControl('confirmPassword', new UntypedFormControl(null, Validators.required));

    this.changePassAdded = true;
  }

  private prefillUsername() {
    const username = this.userService.username.getValue();

    if (username) {
      this.loginForm.get('username').setValue(username);
      this.firstLogin = true;
    }
  }

  private loginErrorHandling(response) {
    const status = response.status;
    const backendError: BackendErrorModel = response.error;
    const message = backendError ? backendError.errorMessage || backendError.message : response.message;

    if (status === ServerStatuses.NOT_ALLOWED_STATUS) {
      this.logger.showError(message);
      sessionStorage.removeItem('username');

      return EMPTY;
    }

    if (backendError.errorCode === ErrorCodes.E_TENANT_LOGIN_MISMATCH) {
      this.logger.error(`${backendError.errorCode}: ${backendError.errorMessage}`);
      this.toastr.error($localize `:@@invalidCredentials:Invalid credentials`, $localize `:@@error:Error!`);

      return EMPTY;
    }

    switch (message) {
      case 'Incorrect username or password.':
        this.logger.error(message);
        this.loginForm.controls['password'].setErrors({
          wrongPassword: true
        });
        break;

      case 'User does not exist.':
        this.logger.error(message);
        this.loginForm.controls['password'].setErrors({
          wrongPassword: true
        });
        break;

      case 'New password is required.':
        this.logger.error(message);
        this.updateLoginForm();
        this.tempPassMessage = true;
        break;

      case 'Invalid attributes given, profile is missing':
        this.logger.showError(`${ message }. Please, contact our manager.`,);
        break;

      default:
        this.logger.showError(message);
    }

    return EMPTY;
  }

  private redirectToMFA(username: string, details) {
    UserLoginService.savedCognitoSession = {
      userName: username,
      phone: details.phone,
      session: details.session,
      mfaType: details.mfaType
    };
    this.router.navigate(['confirm']);
  }
}
