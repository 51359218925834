import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { BcMessagesManager } from '../../widgets';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  savedTitle: string;

  constructor(public bcMessagesManager: BcMessagesManager,
              private titleService: Title) { }

  setTitle(title: string) {
    if (title === this.savedTitle) return;

    switch (title) {
      case 'login':
        this.titleService.setTitle(this.bcMessagesManager.translations['LOGIN_TITLE']);
        break;
      case 'register':
        this.titleService.setTitle(this.bcMessagesManager.translations['REGISTER_TITLE']);
        break;
      default:
        this.titleService.setTitle(title);
    }
  }
}
