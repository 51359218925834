import { Component, Inject } from '@angular/core';
import {
  TRANSLATIONS_HELPER_MANAGER,
  LOCALES_MANAGER,
  LEI_STATUS_TRANSLATIONS_MANAGER,
  BINCKBANK_TRANSLATIONS_MANAGER,
  BANK_ACCOUNT_TYPES_TRANSLATIONS_MANAGER
} from './translation.injectors';
import { TranslationsHelper } from '../core/services/translations-helper.service';
import { BcMessagesManager } from '../widgets';

@Component({
  selector: 'bc-translations',
  templateUrl: 'translations.component.html'
})
export class TranslationsComponent {
  constructor(
    private translationsHelper: TranslationsHelper,
    @Inject(TRANSLATIONS_HELPER_MANAGER) public bcTranslationsMessagesManager: BcMessagesManager,
    @Inject(LEI_STATUS_TRANSLATIONS_MANAGER) public leiStatusesManager: BcMessagesManager,
    @Inject(LOCALES_MANAGER) public bcLocalesManager: BcMessagesManager,
    @Inject(BINCKBANK_TRANSLATIONS_MANAGER) public bcBinckBankManager: BcMessagesManager,
    @Inject(BANK_ACCOUNT_TYPES_TRANSLATIONS_MANAGER) public bcBankAccountTypesManager: BcMessagesManager,
  ) { }

  setupTranslations() {
    this.translationsHelper.setTranslations();
  }
}
