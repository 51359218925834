import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService, SettingsService } from '../services/';

@Injectable({
  providedIn: 'root'
})
export class KycGuard  {

  constructor(private registrationService: RegistrationService,
              private settingsService: SettingsService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      if (this.settingsService.kycSurveySettingEnable && this.registrationService.kycURLs) {
        resolve(true);

        return;
      }
      this.router.navigate(['/dashboard']);

      reject(false);
    });
  }
}
