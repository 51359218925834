<div class="bc-new-modal modal-dialog modal-dialog_sm">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="20" height="20">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n="@@authApp">Authenticator app</h3>
    </div>

    <div class="modal-body text-center">
      <svg class="svg-icon align-center" width="150" height="150">
        <use xlink:href="#verification-icon"></use>
      </svg>

      <p class="h3-header" i18n="@@authAppQuestion">Do you want to add an authenticator app for the 2 step verification?</p>
    </div>

    <div class="modal-footer justify-buttons">
      <button class="btn bc-btn bc-btn_block bc-btn_small bc-btn_default" i18n="@@no" type="button" (click)="onClose($event)">No</button>
      <button class="btn bc-btn bc-btn_block bc-btn_small bc-btn_custom" i18n="@@yes" type="button" (click)="submit()">Yes</button>
    </div>

  </div>
</div>
