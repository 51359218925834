import { Component, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormControl } from '@angular/forms';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';

import { BcModalComponent } from '../../../../widgets/bc.modal/bc.modal.component';
import { Logger, SettingsService, UserService } from '../../../services';

@Component({
  selector: 'bc-software-mfa-register-modal',
  templateUrl: './software-mfa-register-modal.component.html',
  styleUrls: [ './software-mfa-register-modal.component.scss' ]
})
export class SoftwareMfaRegisterModalComponent extends BcModalComponent implements OnInit {
  agree = new EventEmitter();
  qrCode$: Observable<string>;

  confirmMFACode = new FormControl(null, Validators.compose([
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(6)
  ]));

  constructor(
    private usersGatewayService: UsersGatewayService,
    private toastr: ToastrService,
    private logger: Logger,
    private settingsService: SettingsService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.qrCode$ = this.getMFACodeSettings();
  }

  getMFACodeSettings(): Observable<string> {
    return this.usersGatewayService.associateSoftwareMFA().pipe(
      map(({ SecretCode }) => {
        const business = this.settingsService.settings.general.businessName;
        const username = this.userService.user.value.username;

        return `otpauth://totp/${username}?secret=${SecretCode}&digits=6&issuer=${encodeURI(business)}`;
      })
    );
  }

  verifyMfa() {
    if (this.confirmMFACode.invalid) return;

    const userCode: any = `${this.confirmMFACode.value}`;

    this.usersGatewayService.verifySoftwareMFA(userCode).pipe(
      catchError((response: HttpErrorResponse) => {
        const errorType = response.error.errorType;
        const message = response.error.errorMessage;

        switch (errorType) {
          case 'EnableSoftwareTokenMFAException':
            this.confirmMFACode.setErrors({ invalidConfirmCode: true });
            break;
          default:
            this.logger.showError(message);
        }

        return throwError(response);
      })
    ).subscribe(() => {
      this.toastr.success($localize `:@@success:Success!`);
      this.agree.emit('yes');
    });
  }
}
