import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../services';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard  {

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!TokenHelper.isAccesTokenValid()) {
      this.router.navigate(['/login']);

      return Promise.resolve(false);
    }

    return this.userService.get()
      .then(() => {
        if (!this.userService.isUserTenantClient()) {
          this.router.navigate(['/error'], { queryParams: { error: 'profile_error' } });

          return false;
        }

        return true;
      })
      .catch((message: string) => {
        console.error(message);
        this.router.navigate(['/login']);

        return false;
      });
  }
}
