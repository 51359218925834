import { RegistrationSubTypes, RegistrationTypes } from '../shared/models/registrationType.enum';
import { BehaviorSubject } from 'rxjs';
import { ValidationStatuses } from '../core/services';
import { Modules } from './processors.service';
import {
  BinckBankPages,
  ConditionalIndividualSections,
  FranxAvailableFields,
  FranxPages,
  ConditionalBusinessSections,
  getBinckBankStructureConfig, getSaxoStructureConfig, SaxoPages
} from './models';

export const getProcessorPageStructure = (processor: ProcessorDetails, registrationType: RegistrationTypes | RegistrationSubTypes) => {
  const mapper = {
    [Modules.BINCKBANK]: () => getBinckBankStructure(processor, registrationType),
    [Modules.FRANX]: () => getFranxPagesStructure(processor),
    [Modules.SAXO]: () => getSaxoPageStructure(processor, registrationType),
    default: () => {
      console.error(`${processor.processorVendor} isn't implemented`);

      return [];
    }
  };

  return (mapper[processor.processorVendor] || mapper.default)()
    .map((item) => ({ ...item, status$: new BehaviorSubject(ValidationStatuses.NotStarted) }));
};

const getFranxPagesStructure = (processor: ProcessorDetails): Array<NavItem> => {
  return FranxPages(processor.processor_id)
    .filter((item) => isPageAvailable(processor.settings, FranxAvailableFields[item.id]));
};

const getBinckBankStructure = (processor: ProcessorDetails, registrationType: RegistrationTypes | RegistrationSubTypes) => {
  const structureConfiguration = getBinckBankStructureConfig(registrationType, processor.settings.version);

  return BinckBankPages(processor.processor_id)
    // eslint-disable-next-line no-prototype-builtins
    .filter((item) => structureConfiguration.hasOwnProperty(item.id))
    .filter((item) => {
      const conditionalSections = [RegistrationTypes.INDIVIDUAL, RegistrationSubTypes.CHILD].includes(registrationType) ?
        ConditionalIndividualSections :
        registrationType === RegistrationTypes.BUSINESS ? ConditionalBusinessSections : [];

      return conditionalSections.includes(item.id) ?
        isFirstQuestionAvailable(processor.settings, structureConfiguration[item.id]) :
        isPageAvailable(processor.settings, structureConfiguration[item.id]);
    });
};

const getSaxoPageStructure = (processor: ProcessorDetails, registrationType: RegistrationTypes | RegistrationSubTypes): Array<NavItem> => {
  const structureConfiguration = getSaxoStructureConfig(registrationType, processor.settings.version);

  // eslint-disable-next-line no-prototype-builtins
  return SaxoPages(processor.processor_id).filter((item) => structureConfiguration.hasOwnProperty(item.id));
};

const isPageAvailable = (processorSettings, questionsFields) => {
  return !questionsFields.every((key) => !processorSettings.clientAccess.questions[key]);
};

const isFirstQuestionAvailable = (processorSettings, question) => {
  return !!processorSettings.clientAccess.questions[question];
};


