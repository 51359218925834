import { Injectable } from '@angular/core';

@Injectable()
export class RestorePassService {
  static codeDeliveryDetails = {
    AttributeName: '',
    DeliveryMedium: '',
    Destination: ''
  };

  constructor() {  }
}
