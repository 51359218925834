import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Limit } from '../../shared/pipes';

import { BcSelectComponent } from './bc.select.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    BcSelectComponent,
    Limit
  ],
  exports: [
    BcSelectComponent
  ]
})
export class BcSelectModule {  }
