<bc-catch-messages [manager]="bcTranslationsMessagesManager" (finished)="setupTranslations()">
  <!--Boolean-->
  <bc-message key="YES" i18n="yes@@yes">Yes</bc-message>
  <bc-message key="NO" i18n="no@@no">No</bc-message>

  <bc-message key="EMPTY_BUSINESS_NAME" i18n="@@emptyBusinessName">The organisation</bc-message>
  <bc-message key="PERSON_NAME_PLACEHOLDER" i18n="@@person">Person</bc-message>
  <bc-message key="REPRESENTATIVE_NAME_PLACEHOLDER" i18n="@@representativeNamePlaceholder">Representative</bc-message>
  <bc-message key="CHILD_NAME_PLACEHOLDER" i18n="@@childNamePlaceholder">Child</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="bcLocalesManager">
  <bc-message key="en-US" i18n="@@enUSLanguage">English</bc-message>
  <bc-message key="nl-NL" i18n="@@nlNLLanguage">Dutch</bc-message>
  <bc-message key="fr-FR" i18n="@@frBELanguage">French</bc-message>
  <bc-message key="de-DE" i18n="@@deDELanguage">German</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="leiStatusesManager">
  <bc-message key="DUPLICATE" i18n="@@duplicate">Duplicate</bc-message>
  <bc-message key="ANNULLED" i18n="@@annulled">Annulled</bc-message>
  <bc-message key="ISSUED" i18n="@@issued">Issued</bc-message>
  <bc-message key="LAPSED" i18n="@@lapsed">Lapsed</bc-message>
  <bc-message key="MERGED" i18n="@@merged">Merged</bc-message>
  <bc-message key="PENDING_ARCHIVAL" i18n="@@pendingarchival">Pending archival</bc-message>
  <bc-message key="PENDING_TRANSFER" i18n="@@pendingtrasfer">Pending transfer</bc-message>
  <bc-message key="RETIRED" i18n="@@retired">Retired</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="bcBinckBankManager">
  <bc-message key="EMPLOYEE_PRIVATE_BUSINESS" i18n="@@employeePrivateBusiness">Employee in a private business</bc-message>
  <bc-message key="SEMI_GOVERNMENTAL_INSTITUTION" i18n="@@semiGovernmentalInstitution">Working for a (semi-)governmental institution</bc-message>
  <bc-message key="PARTNER_IN_ENTERPRISE" i18n="@@partnerInEnterprise">Partner in an enterprise</bc-message>
  <bc-message key="DIRECTOR_NON_MAJOR_SHAREHOLDER" i18n="@@directorNonMajorShareholder">Director/non-major shareholder</bc-message>
  <bc-message key="SELF_EMPLOYMENT_WITHOUT_SHAREHOLDER" i18n="@@selfEmployedWithoutShareholder">Self-employed without staff/Entrepeneur/Director and major shareholder</bc-message>
  <bc-message key="STUDENT" i18n="@@student">Student</bc-message>
  <bc-message key="EMPLOYMENT_RETIRED" i18n="@@employment_retired">Retired</bc-message>
  <bc-message key="UNEMPLOYED" i18n="@@unemployed">Unemployed</bc-message>
  <bc-message key="OFFERING_TRADE_IN_CRYPTO_CURRENCIES" i18n="@@offeringTradeInCryptoCurrencies">Offering trade in cryptocurrencies or Initial Coin Offering/ICO</bc-message>
  <bc-message key="PPS_OR_SIMILAR" i18n="@@PPSOrSimilar">Payment Service Provider or similar company (PSP)</bc-message>
  <bc-message key="CONSULTANCY_SERVICES" i18n="@@consultancyServices">Consultancy services</bc-message>
  <bc-message key="ENTERTAINMENT_AND_RECREATION" i18n="@@entertainmentAndRecreation">Entertainment and recreation</bc-message>
  <bc-message key="CONSTRUCTION_INFRASTRUCTURE_OFFSHORE_DREDGING_WORK" i18n="@@constructionInfrastructureOffshoreDredgingWork">Construction, infrastructure, offshore and dredging work</bc-message>
  <bc-message key="COFFEESHOP_GROWSHOP" i18n="@@coffeeshopGrowshop">Coffeeshop / growshop</bc-message>
  <bc-message key="COMMERCIAL_REAL_ESTATE_ACTIVITIES" i18n="@@commercialRealEstateActivities">(Commercial) real estate activities</bc-message>
  <bc-message key="CROWDFUNDING_PEER_TO_PEER_PLATFORMS" i18n="@@crowdfundingPeerToPeerPlatforms">Crowdfunding, peer-to-peer platforms for credit and payment services</bc-message>
  <bc-message key="MANUFACTURING_PRODUCTION" i18n="@@manufacturingProduction">Manufacturing/Production</bc-message>
  <bc-message key="PHARMACEUTICAL_INDUSTRY" i18n="@@pharmaceuticalIndustry">Pharmaceutical industry</bc-message>
  <bc-message key="FINANCIAL_AND_INSURANCE_ACTIVITIES" i18n="@@financialAndInsuranceActivities">Financial and insurance activities</bc-message>
  <bc-message key="CHARITIES_RELIGIOUS_ORGANIZATIONS" i18n="@@charitiesReligiousOrganizations">Charities / religious organizations</bc-message>
  <bc-message key="RAW_MATERIALS_MINERALS_MINING_QUARRYING" i18n="@@rawMaterialsMineralsMiningQuarrying">Raw materials, minerals, mining and quarrying</bc-message>
  <bc-message key="CRYPTO_CURRENCIES_TRADING" i18n="@@cryptocurrenciesTrading">Cryptocurrencies Trading</bc-message>
  <bc-message key="TRADE_IN_DIAMONDS" i18n="@@tradeInDiamonds">Trade in diamonds</bc-message>
  <bc-message key="TRADE_IN_JEWELRY" i18n="@@tradeInJewelry">Trade in jewelry (e.g. precious metals/diamonds)</bc-message>
  <bc-message key="TRADE_IN_ARMS_OR_MILITARY_GOODS" i18n="@@tradeInArmsOrMilitaryGoods">Trade in arms or other military goods</bc-message>
  <bc-message key="HOSPITALITY" i18n="@@hospitality">Hospitality</bc-message>
  <bc-message key="INTELLECTUAL_PROPERTY_PATENT_RIGHTS" i18n="@@intellectualPropertyPatentRights">Intellectual property / patent rights</bc-message>
  <bc-message key="JEWELER" i18n="@@jeweler">Jeweler</bc-message>
  <bc-message key="ART_DEALERSHIP" i18n="@@artDealership">Art dealership</bc-message>
  <bc-message key="AGRICULTURE_FORESTRY_FISHING" i18n="@@agricultureForestryFishing">Agriculture, forestry and fishing</bc-message>
  <bc-message key="OIL_GAS_INDUSTRY" i18n="@@oilGasIndustry">Oil and gas industry</bc-message>
  <bc-message key="EDUCATIONAL_SCIENTIFIC_RESEARCH" i18n="@@educationalScientificResearch">Educational/scientific research</bc-message>
  <bc-message key="GAMING_GAMBLING" i18n="@@gamingGambling">(Online) gaming and gambling</bc-message>
  <bc-message key="STORAGE_OF_GOODS" i18n="@@storageOfGoods">Storage of goods</bc-message>
  <bc-message key="GOVERNMENT_AGENCIES" i18n="@@governmentAgencies">Government agencies</bc-message>
  <bc-message key="PROFESSIONAL_SPORTS" i18n="@@professionalSports">Professional sports (players, intermediaries, representatives, (inter)national sports federations, teams/clubs and owners of teams/clubs)</bc-message>
  <bc-message key="SHIPPING_OTHER_TRANSPORT_OF_GOODS" i18n="@@shippingOtherTransportOfGoods">Shipping and other transport of goods (transport)</bc-message>
  <bc-message key="SEX_INDUSTRY" i18n="@@sexIndustry">Sex industry</bc-message>
  <bc-message key="TELECOM_IT_SERVICES" i18n="@@telecomItServices">Telecom & IT Services</bc-message>
  <bc-message key="TRUST_OFFICE" i18n="@@trustOffice">Trust Office (Corporate Service Provider)</bc-message>
  <bc-message key="AUCTION_HOUSE" i18n="@@auctionHouse">Auction house</bc-message>
  <bc-message key="WATER_SUPPLY_SEWERAGE" i18n="@@waterSupplySewerage">Water supply; sewerage; waste management and remediation activities</bc-message>
  <bc-message key="NONE_OF_THE_ABOVE_SECTORS" i18n="@@noneOfTheAboveSectors">None of the above sectors</bc-message>

  <!-- Assets -->
  <bc-message key="WAGE_PENSION_BENEFIT" i18n="@@wagePensionBenefit">Wage / Pension / Benefit</bc-message>
  <bc-message key="INCOME_FROM_OWN_ENTERPISE" i18n="@@incomeFromOwnEnterprise">Income from own enterprise</bc-message>
  <bc-message key="STOCK_MARKET_INV_REV" i18n="@@stockMarketAndInvestmentRevenues">Stock market and investment revenues</bc-message>
  <bc-message key="INV_REAL_ESTATE_RENTAL_REV" i18n="@@investmentsRealEstateRentalRevenues">Investments in real estate / Rental revenues</bc-message>
  <bc-message key="INTEREST_REVENUES_OR_LOANS" i18n="@@interestRevenuesOnLoans">Interest revenues on loans to third parties</bc-message>

  <bc-message key="EQUITY_INHERITANCE_DONATION_SALE_COMPANY" i18n="@@equityInheritanceDonationSaleCompany">Home equity / Inheritance / Donation / Sale of own company</bc-message>
  <bc-message key="STOCK_MARKET_INV_INCOME_OWN_COMPANY" i18n="@@stockMarketAndInvestmentRevenuesIncomeOwnCompany">Stock market and investment income / Income from own company</bc-message>

  <!-- Source of wealth -->
  <bc-message key="SALE_OF_COMPANY" i18n="@@saleOfACompany">Sale of a company</bc-message>
  <bc-message key="INHERITANCE_DONATION" i18n="@@inheritanceDonation">Inheritance / Donation</bc-message>
  <bc-message key="LOTTERY_GAMBLING_CRYPTOCURRECNCIES" i18n="@@lotteryGamblingCryptocurrencies">Lottery / Gambling / Cryptocurrencies</bc-message>
  <bc-message key="SAVING_SALE_OWN_HOME" i18n="@@savingsSaleOwnHome">Savings / Sale own home</bc-message>

  <!-- Abroad -->
  <bc-message key="EMIGRATION_PENSION" i18n="@@emigrationPension">Emigration (pension)</bc-message>
  <bc-message key="EMIGRATION_WORK" i18n="@@emigrationWork">Emigration (work)</bc-message>
  <bc-message key="EXPAT" i18n="@@expat">Expat</bc-message>
  <bc-message key="NA" i18n="@@notApplicable">Not Applicable</bc-message>

  <!-- Connection -->
  <bc-message key="FAMILY_NETHERLANDS" i18n="@@familyInTheNetherlands">Family in the Netherlands</bc-message>
  <bc-message key="LIVED_NETHERLANDS" i18n="@@livedInTheNetherlands">Lived in the Netherlands</bc-message>
  <bc-message key="WORKED_NETHERLANDS" i18n="@@workedInTheNetherlands">Worked in the Netherlands</bc-message>
  <bc-message key="OTHER_NETHERLANDS" i18n="@@other">Other</bc-message>

  <!--BUSINESS-->
  <bc-message key="GROUP_LOAN" i18n="@@groupLoan">Income from group loans</bc-message>
  <bc-message key="COMPANY_OPERATION" i18n="@@companyOperation">Revenue from the company's operational activities</bc-message>
  <bc-message key="SALE_INCOME_BUSINESS" i18n="@@dividentsOrSaleOfParticipatingInterest">Income from the sale of participations or dividends</bc-message>
  <bc-message key="LOAN_INTEREST" i18n="@@loanInterest">Revenue from interest on loans</bc-message>
  <bc-message key="REAL_ESTATE_INCOME" i18n="@@realEstateIncome">Income from (commercial) real estate</bc-message>
  <bc-message key="ACTIVITIES_REVENUE" i18n="@@revenue">Revenue from operating activities</bc-message>
  <bc-message key="PENSION_GOLDEN_HANDSHAKE" i18n="@@accruedPensionOrGoldenHandshake">Accrued pension and/or golden handshake</bc-message>
  <bc-message key="SALE_COMPANY_PARTICIPATIONS" i18n="@@saleOfCompanyOrParticipations">Sale of company and/or participations</bc-message>
  <bc-message key="GAIN_ON_INVESTMENT" i18n="@@gainOnInvestment">Gains on investment/investments</bc-message>
  <bc-message key="AFRICA" i18n="@@africa">Africa</bc-message>
  <bc-message key="ASIA" i18n="@@asia">Asia</bc-message>
  <bc-message key="EU_AND_EEA" i18n="@@EUandEEA">EU + EEA</bc-message>
  <bc-message key="OCEANIA" i18n="@@oceania">Oceania</bc-message>
  <bc-message key="REST_OF_EUROPE" i18n="@@restOfEurope">Rest Europe (including Russia)</bc-message>
  <bc-message key="CANADA_AND_USA" i18n="@@canadaAndUSA">USA + Canada</bc-message>
  <bc-message key="MEXICO_SOUTH_AMERICA" i18n="@@mexicoAndSouthAmerica">South America + Mexico</bc-message>
  <bc-message key="ANGLO_TRUST" i18n="@@angloSaxonTrust">Anglo-Saxon trust</bc-message>
  <bc-message key="BEARER_SHARES" i18n="@@bearerShares">Bearer Shares</bc-message>
  <bc-message key="TRUST_OFFICE" i18n="@@trustOffice">Corporate Service Provider (trust office)</bc-message>
  <bc-message key="NOMINEE_SHARE" i18n="@@nomineeShareholder">Nominee Shareholder</bc-message>
  <bc-message key="NONE_APPLICABLE" i18n="@@noneOfTheAboveApplicable">None of the above is applicable</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="bcBankAccountTypesManager">
  <bc-message key="IBAN">IBAN</bc-message>
  <bc-message key="OTHER" i18n="@@other">Other</bc-message>
</bc-catch-messages>
