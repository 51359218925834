import { Component, EventEmitter, OnInit } from '@angular/core';

import { BcModalComponent } from '../../bc.modal.component';


@Component({
  selector: 'bc-modal',
  templateUrl: 'bc.modal.confirmation.component.html',
  styleUrls: [ '../../../../../assets/sass/02-molecules/new_modal-dialog.scss' ]
})
export class NewConfirmationModalComponent extends BcModalComponent implements OnInit {
  close = new EventEmitter();
  yes = new EventEmitter();
  no = new EventEmitter();

  title: string;
  questions: Array<string>;
  buttonsSetup: ConfirmationModalButtonsSetup = {};

  constructor() {
    super();
  }

  ngOnInit() {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.buttonsSetup.hasOwnProperty('confirmationBtnStyle')) {
      this.buttonsSetup['confirmationBtnStyle'] = 'bc-btn_custom';
      this.buttonsSetup['confirmationText'] = $localize `:@@yes:Yes`;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!this.buttonsSetup.hasOwnProperty('rejectionBtnStyle')) {
      this.buttonsSetup['rejectionBtnStyle'] = 'bc-btn_default';
      this.buttonsSetup['rejectionText'] = $localize `:@@no:No`;
    }
  }

  onClose(event: Event) {
    event?.preventDefault();
    this.close.emit('close');
  }

  onYes(event: Event) {
    event?.preventDefault();
    this.yes.emit('Yes');
  }

  onNo(event: Event) {
    event?.preventDefault();
    this.no.emit('No');
  }
}
