import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { findGetParameter } from '../../../shared/helpers/helpers';

@Component({
  selector: 'bc-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorComponent {
  errorType: string;

  constructor(private location: Location) {
    this.errorType = findGetParameter('error');
    this.location.replaceState('error');
  }
}
