import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'bc-modal',
  templateUrl: 'bc.modal.component.html',
  styleUrls: [
    '../../../assets/sass/02-molecules/new_modal-dialog.scss'
  ]
})
export class BcModalComponent {
  close = new EventEmitter();
  customEvent = new EventEmitter();

  onClose(event: Event) {
    event?.preventDefault();
    this.close.emit('close');
  }
}
