import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService, SettingsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class SigningGuard  {

  constructor(private registrationService: RegistrationService,
              private settingsService: SettingsService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      if (this.settingsService.signingSettingEnable &&
        this.registrationService.isRegistrationComplete &&
        this.registrationService.isSigningStarted) {
        resolve(true);

        return;
      }
      this.router.navigate(['/dashboard']);

      reject(false);
    });
  }
}
