import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService, SurveysService } from '../services/';

@Injectable({
  providedIn: 'root'
})
export class SurveyGuard  {

  constructor(
    private registrationService: RegistrationService,
    private surveysService: SurveysService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.surveysService.getSurveySettings(this.registrationService.registrationId)
      .then(({ surveyEnabled }) => surveyEnabled)
      .catch((message) => {
        console.error(message);
        this.router.navigate(['/dashboard']);

        return false;
      });
  }
}
