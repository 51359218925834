import { Injectable } from '@angular/core';

import { RegistrationService, ValidationStatuses } from './registration.service';
import { BcMessagesManager } from '../../widgets';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SigningService {

  constructor(public registrationService: RegistrationService,
              public bcMessagesManager: BcMessagesManager) { }

  getSigningStatus() {
    const listOfSigners = this.getSigners();
    const pageStructure = this.getSignersStructureList(listOfSigners);

    return combineLatest(pageStructure.map(({status$}) => <Observable<ValidationStatuses>> status$))
      .pipe(
        map((values: Array<ValidationStatuses>) => {
          const filledParties = values.filter(v => v === ValidationStatuses.Done);

          return filledParties.length === values.length ? ValidationStatuses.Done :
            filledParties.length > 0 ? ValidationStatuses.InProgress : ValidationStatuses.NotStarted;
        })
      );
  }

  getSigners() {
    const signersList: Array<Part> = [];
    this.getRegistrationSigners(signersList, this.registrationService.root.parties);

    return this.extractSigners(signersList, this.registrationService.signing);
  }

  getSignersStructureList(signers: Array<SignerWithStatus>) {
    return signers.map((signer: SignerWithStatus) => {
      return {
        id: signer.fullName,
        text: signer.fullName,
        status$: new BehaviorSubject(signer.signingCompleted ? ValidationStatuses.Done : ValidationStatuses.NotStarted)
      };
    });
  }

  private getRegistrationSigners(signerList: Array<Part>, registrationParties: Array<Part>) {
    registrationParties.map((part: Part) => {
      if (part.naturalPerson) {
        if (part.naturalPerson.signer) {
          signerList.push(part);

          return;
        }
      } else if (part.parties && part.parties.length > 0) {
        this.getRegistrationSigners(signerList, part.parties);
      }
    });
  }

  private extractSigners(object: Array<Part>, signing: SigningObject): Array<SignerWithStatus> {
    const signersArray: Array<SignerWithStatus> = [];

    object.forEach((item: Part) => {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('naturalPerson')) {
        const signer: SignerWithStatus = {
          _id: item['_id'],
          fullName: `${item.naturalPerson.preferredName} ${item.naturalPerson.lastName}`,
          ubo: item.naturalPerson.ubo,
          email: item.naturalPerson.email
        };

        if (signing.response.Signers) {
          for (const index in signing.response.Signers) {
            if (signing.response.Signers[index].Id === signer['_id']) {
              const signerDetails = signing.response.Signers[index];

              if (signerDetails.Activities) {
                signer.details = this.translateEvidosStatuses(signerDetails);

                this.setLastActivity(signer);

                signersArray.push(signer);
              }
            }
          }
        }
      }
    });

    return signersArray;
  }

  private translateEvidosStatuses(details: SignerDetails) {
    for (const item in details.Activities) {
      if (details.Activities[item].Activity) {
        details.Activities[item].Activity = this.bcMessagesManager.translations[`EVIDOS_STATUS_${details.Activities[item].Code}`];
      }
    }

    return details;
  }

  private setLastActivity(signer: SignerWithStatus) {
    const activitiesSize = signer.details.Activities.length - 1;

    if (signer.details.Activities[activitiesSize]) {
      signer.lastStatus = signer.details.Activities[activitiesSize].Activity;
      /* eslint-disable no-magic-numbers */
      // Code `202 < x < 700` means completed status of signing
      signer.signingCompleted = (signer.details.Activities[activitiesSize].Code > 202) &&
        (signer.details.Activities[activitiesSize].Code < 700);
      /* eslint-enable no-magic-numbers */
    }
  }
}
