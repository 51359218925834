import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class RegistrationStatusGuard  {

  constructor(private registrationService: RegistrationService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.registrationService.resolve()
      .then(() => {
        const status = this.registrationService.registrationStatus;

        switch (status) {
          case 'INCOMPLETE':
          case 'COMPLETE':
            return true;

          case 'ACCEPTED':
          case 'DONE':
            if (!next.url[0] || next.url[0].path !== 'thank-you') {
              this.router.navigate(['/thank-you']);
            }

            return true;

          case 'REJECTED':
          case 'DELETED':
            if (!next.url[0] || next.url[0].path !== 'account-inactive') {
              this.router.navigate(['/account-inactive']);
            }

            return true;

          default:
            return true;
        }
      })
      .catch((message: string) => {
        console.error(message);
        this.router.navigate(['/error'], { queryParams: { error: 'reg_error' } });

        return false;
      });
  }
}
