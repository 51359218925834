import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger, TenantService } from '../../core/services';

@Injectable()
export class TenantGuard  {

  constructor(private tenantService: TenantService,
              private logger: Logger,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

      return this.tenantService.resolve().then(() => {
        if (this.tenantService.tenantData) {

          return true;
        }
        this.router.navigate(['/error']);

        return false;
      }).catch((message: string) => {
        this.logger.error(message);
        this.router.navigate(['/error']);

        return false;
      });
  }
}
