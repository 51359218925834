import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { ProcessorsService } from '../../custodian/processors.service';
import { RegistrationService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class CustodianGuard  {

  constructor(private registrationService: RegistrationService,
              private custodianService: ProcessorsService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.custodianService.resolve()
      .then(() => {
        return !!this.custodianService.processes.length;
      })
      .catch(() => {
        this.router.navigate(['/dashboard']);

        return false;
      });
  }

}
