import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BcSpinnerService {
  loading: boolean;
  loadChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.loading = false;
  }

  set(loading: boolean) {
    this.loading = loading;
    this.loadChange.next(this.loading);
  }
}
