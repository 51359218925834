import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { GatewayService } from './gateway.service';

import { Part } from '../../shared/models/part.model';
import { NaturalPerson } from '../../shared/models/natural-person.model';
import { LegalEntity } from '../../shared/models/legal-entity.model';

import { BcMessagesManager } from '../../widgets';

import { Countries } from '../../translations/countries-translations/country.service';
import { TranslationsHelper } from './translations-helper.service';

import { IdentificationType } from '../../shared/models/identificationType.enum';
import { RegistrationSubTypes, RegistrationTypes } from '../../shared/models/registrationType.enum';

import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { TokenHelper } from '@blancoservices/bc-auth-web';
import { BusinessDataProviders } from '../../legal-entity/basic/legal-entity-identification.service';

export const baseUrlBCOS = environment.BASE_URL_BCOS;
export const baseUrlBSUR = environment.BASE_URL_BSUR;
const baseUrlSocket = environment.BASE_URL_WSS;

export enum ValidationStatuses {
  NotStarted,
  InProgress,
  Done
}

/* eslint-disable no-prototype-builtins*/
@Injectable({
  providedIn: 'root'
})
export class RegistrationService  {
  identificationProvider: string;
  tenantId: string;
  registrationType: RegistrationType;
  registrationStatus: RegistrationStatus;
  tenantId$: BehaviorSubject<string>;

  // TODO make better solution
  surveyInProgress: BehaviorSubject<boolean>;
  invalidateSurveyId: string;

  root: TreeRoot;
  party: Part;
  partyIdx: number;
  workflow: WorkflowSettings;
  internationalisation: InternationalisationSettings;

  deliveryMethod: string;

  get registrationId(): string {
    return this.root._id;
  }

  get isFirstParty(): boolean {
    return this.registrationType !== 'BUSINESS' && this.party &&
      this.party['_id'] === this.root.parties[0]['_id'];
  }

  get isRegistrationComplete(): boolean {
    return this.registrationStatus !== 'INCOMPLETE';
  }

  get isSigningStarted(): boolean {
    return this.signing.status.code !== 0;
  }

  get identification(): MitekResult {
    if (!this.identifications || !this.party) {
      return null;
    }

    return this.identifications[this.party._id];
  }

  get caseId(): string {
    if (!this.identifications || !this.party) {
      return null;
    }

    return this.identifications[this.party._id] ? this.identifications[this.party._id].caseId : null;
  }

  get mitekStatus(): string {
    if (!this.identifications || !this.party) {
      return null;
    }

    return this.identifications[this.party._id] ? this.identifications[this.party._id].mitekStatus : null;
  }

  get mitekSubStatus(): string {
    if (!this.identifications || !this.party) {
      return null;
    }

    return this.identifications[this.party._id] ? this.identifications[this.party._id].mitekSubStatus : null;
  }

  get isWaitTimeShort(): boolean {
    if (!this.identifications || !this.party) {
      return null;
    }
    if (this.identifications[this.party._id]) {
      return this.identifications[this.party._id].waitTime === 'S';
    }

    return null;
  }

  get mitekNotFinished(): boolean {
    return this.mitekStatus === 'InProgress' || this.mitekStatus === 'Rejected';
  }

  get mitekResults(): any {
    if (!this.identifications || !this.party) {
      return {};
    }

    return this.identifications[this.party._id] ? this.identifications[this.party._id].mitekResults : {};
  }

  get legalStructureStarted(): boolean {
    return this.root && this.root.parties && this.root.parties[0] && Array.isArray(this.root.parties[0].parties) &&
      this.root.parties[0].parties.length > 0;
  }

  get isChildAccount() {
    return this.registrationType === RegistrationTypes.INDIVIDUAL &&
      this.root.subType === RegistrationSubTypes.CHILD;
  }

  get identificationSkippedOrManual(): boolean {
    if (!this.identifications || !this.party) {
      return null;
    }

    return this.identifications[this.party._id] ?
      (this.identifications[this.party._id].skip || this.identifications[this.party._id].manualIdentification) : null;
  }

  numberOfRepresentatives = 0;

  redirectLink: string;
  kyc: KYCObject;
  signing: SigningObject;
  kycURLs: Array<{id: string, url: string}>;
  survey: SurveyObject;
  identifications: MitekObject;

  legalStructure = new BehaviorSubject({
    id: 'root',
    text: '',
    isCurrent: true,
    isComplex: false,
    isSimplifiedLegalStructure: false,
    expanded: true,
    // TODO check naming
    status$: ValidationStatuses.NotStarted,
    items: []
  });

  npCompleted: Map<string, ValidationStatuses>;
  leCompleted = new BehaviorSubject(ValidationStatuses.NotStarted);

  npPageStructures: Map<string, Array<NavItem>>;
  lePageStructures: Map<string, Array<NavItem>>;

  dataProvider: BusinessDataProviders;

  get kycUrl() {
    if (!this.kycURLs) return null;

    const redirectPart = `&referral_url=${window.location.origin}`;

    const urlObj: {id: string, url: string} = this.kycURLs.find(({id}) => id === this.localeId);

    if (urlObj) return `${urlObj.url}${redirectPart}`;

    return `${this.kycURLs[0].url}${redirectPart}`.replace(/(locale=).*?(&)/, `$1${this.localeId}$2`);
  }

  constructor(public translationsHelper: TranslationsHelper,
              public bcMessagesManager: BcMessagesManager,
              private gatewayService: GatewayService,
              private countryService: Countries,
              @Inject(LOCALE_ID) private localeId: string) {
    this.surveyInProgress = new BehaviorSubject(false);
    this.tenantId$ = new BehaviorSubject<string>(null);

    this.npPageStructures = new Map();
    this.npCompleted = new Map();

    this.lePageStructures = new Map();
  }

  resolve(): Promise<TreeRoot> {
    if (this.root && this.root.type) {
      return Promise.resolve(this.root);
    }

    return this.getRegistration();
  }

  getNpPageStructure(partyId) {
    if (!this.npPageStructures.has(partyId)) {
      const tasklist = [
        {
          id: 'basic',
          text: this.bcMessagesManager.translations['BASIC'],
          route: `/natural-person/${partyId}/basic`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        },
        {
          id: 'identification',
          text: this.bcMessagesManager.translations['IDENTIFICATION'],
          route: `/natural-person/${partyId}/identification`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted),
          isNotMandatory: this.identificationProvider === IdentificationType.MITEK
        },
        {
          id: 'address',
          text: this.bcMessagesManager.translations['ADDRESS'],
          route: `/natural-person/${partyId}/address`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        },
        {
          id: 'additional',
          text: this.bcMessagesManager.translations['ADDITIONAL'],
          route: `/natural-person/${partyId}/additional`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        }
      ];
      if (this.checkWorkflowNP()) {
        tasklist.push({
          id: 'qualifications',
          text: this.bcMessagesManager.translations['SPECIFIC_SITUATIONS'],
          route: `/natural-person/${partyId}/qualifications`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        });
      }
      if (this.originOfAssetsEnabled(partyId)) {
        tasklist.push({
          id: 'originOfAssets',
          text: this.bcMessagesManager.translations['ORIGIN_OF_ASSETS'],
          route: `/natural-person/${partyId}/origin-of-assets`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        });
      }

      this.npPageStructures.set(partyId, tasklist);
    }

    return this.npPageStructures.get(partyId);
  }

  getLePageStructure(partyId) {
    if (!this.lePageStructures.has(partyId)) {
      const tasklist = [
        {
          id: 'basic',
          text: this.bcMessagesManager.translations['BASIC'],
          route: `/legal-entity/${partyId}/basic-info`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        },
        {
          id: 'address',
          text: this.bcMessagesManager.translations['ADDRESS'],
          route: `/legal-entity/${partyId}/address`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        },
        {
          id: 'additional',
          text: this.bcMessagesManager.translations['ADDITIONAL'],
          route: `/legal-entity/${partyId}/additional`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        }
      ];
      if (this.checkWorkflowLE()) {
        tasklist.push({
          id: 'qualifications',
          text: this.bcMessagesManager.translations['SPECIFIC_SITUATIONS'],
          route: `/legal-entity/${partyId}/qualifications`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        });
      }
      if (this.originOfAssetsEnabled(partyId)) {
        tasklist.push({
          id: 'originOfAssets',
          text: this.bcMessagesManager.translations['ORIGIN_OF_ASSETS'],
          route: `/legal-entity/${partyId}/origin-of-assets`,
          status$: new BehaviorSubject(ValidationStatuses.NotStarted)
        });
      }
      this.lePageStructures.set(partyId, tasklist);

    }

    return this.lePageStructures.get(partyId);
  }

  setActivePage(structure: Array<NavItem>, pageId: string) {
    structure.forEach((page: NavItem) => {
      page.isCurrent = page.id === pageId;
    });
  }

  getPageName(structure: Array<NavItem>, pageId: string) {
    const activePage = structure.find((page: NavItem) => page.id === pageId);

    return activePage ? activePage.text : '';
  }

  getIdentification(partyId): MitekResult {
    return this.identifications && this.identifications[partyId] ? this.identifications[partyId] : {} as MitekResult;
  }

  hasLESubParties(registration?: any) {
    if (registration) {
      return registration.parties[0].parties.some((party: any) => party.legalEntity);
    }

    return this.root.parties[0].parties.some((party: any) => party.legalEntity);
  }

  updateLegalPageStructure(registration) {
    const isComplex = registration.isComplex || this.isComplex(registration);
    const isSimplifiedLegalStructure = this.workflow.simplifiedLegalStructure;

    const skipItemsList = (isComplex && !isSimplifiedLegalStructure) || this.hasLESubParties(registration);

    const items = skipItemsList ? [] : registration.parties[0].parties.map((party: Part, index: number) => {
      const name = `${party.naturalPerson.preferredName || ''} ${party.naturalPerson.lastName || ''}`.trim();

      return {
        id: party._id || index,
        text: name || `${this.translationsHelper.translations['PERSON_NAME_PLACEHOLDER']} ${index + 1}`,
        isCurrent: false,
      };
    });

    this.checkLEStatus(registration);

    const text = registration.parties[0] && registration.parties[0].legalEntity && registration.parties[0].legalEntity.name ?
      registration.parties[0].legalEntity.name : this.translationsHelper.translations['EMPTY_BUSINESS_NAME'];

    this.legalStructure.next({
      id: 'root',
      text,
      isCurrent: true,
      isComplex,
      isSimplifiedLegalStructure,
      expanded: true,
      status$: ValidationStatuses.NotStarted,
      items
    });
  }

  checkLEStatus(registration) {
    const pageStructure = this.getLePageStructure(registration.parties[0]._id);

    const filledPartiesLength = pageStructure
      .filter(({status$}) => status$.value === ValidationStatuses.Done)
      .length;
    const inProgressPartiesLength = pageStructure
      .filter(({status$}) => status$.value === ValidationStatuses.InProgress)
      .length;

    const status = filledPartiesLength === pageStructure.length ?
      ValidationStatuses.Done : (inProgressPartiesLength > 0 || filledPartiesLength > 0) ?
        ValidationStatuses.InProgress : ValidationStatuses.NotStarted;

    this.leCompleted.next(status);
  }

  async updateIdentifications() {
    const fakerRegId = '12345';

    const registration = await this.gatewayService.get(`${baseUrlBCOS}/registrations/${fakerRegId}`);
    this.identifications = registration.identifications;
  }

  getRegistration() {
    const fakerRegId = '12345';

    return Promise.all([
      this.gatewayService.get(`${baseUrlBCOS}/registrations/${fakerRegId}`),
      this.gatewayService.get(`${baseUrlBCOS}/tenants/${this.tenantId}/settings`)
    ]).then(([registration, { workflow, internationalisation, businessData}]) => {
      this.workflow = workflow;
      this.internationalisation = internationalisation;
      this.dataProvider = businessData?.dataSource || BusinessDataProviders.Default;
      this.countryService.setPrioritizedCountries(internationalisation.allowedRegions);
      if (!registration.type) return Promise.reject('Missing registration type');

      this.init(registration);

      if (registration.type === RegistrationTypes.BUSINESS) this.updateLegalPageStructure(registration);

      // TODO make better solution
      if (this.surveyInProgress.value && registration.survey.token && registration.survey.token !== this.invalidateSurveyId) {
        this.invalidateSurveyId = undefined;
        this.surveyInProgress.next(false);
      }

      return Promise.resolve(this.root);
    });
  }

  setTenantId(tenantId: string) {
    this.tenantId = tenantId;
    this.tenantId$.next(tenantId);
    sessionStorage.setItem('tenant_id', this.tenantId);

    if (!environment.production) {
      console.warn('TENANT_ID: ', this.tenantId);
    }
  }

  setIdentificationProvider(identificationProvider: string) {
    this.identificationProvider = identificationProvider;
  }

  init(regObject: TreeRoot) {
    this.rootClear();

    this.root.type = this.registrationType = regObject.type;
    if (regObject.hasOwnProperty('subType')) this.root.subType = regObject.subType;
    this.root.status = this.registrationStatus = regObject.status;
    this.root.createdAt = regObject.createdAt;
    this.root.requestSentDateTime = regObject.requestSentDateTime;
    this.root._id = regObject._id;
    this.root.tenant_id = regObject.tenant_id;
    this.root.agent_id = regObject.agent_id || '';
    this.root.channel = regObject.channel;
    this.root.documents = regObject.documents || [];
    this.root.parties = regObject.parties.map(this.initPart.bind(this));
    this.root.isComplex = regObject.isComplex;
    this.root.originOfAssets = regObject.originOfAssets;

    if (this.root.tenant_id !== this.tenantId) this.setTenantId(this.root.tenant_id);
    if (regObject.hasOwnProperty('kycSurvey')) this.saveKYC(regObject.kycSurvey);
    if (regObject.hasOwnProperty('survey')) this.survey = regObject.survey;
    if (regObject.hasOwnProperty('signing')) this.signing = regObject.signing;
    if (regObject.hasOwnProperty('identifications')) this.identifications = regObject.identifications;

    if (this.registrationType === 'BUSINESS') this.checkRepresentatives(this.root.parties[0]);
  }

  initPart(part?: Part) {
    const p = new Part();
    if (part.naturalPerson) p.naturalPerson = new NaturalPerson(true, part.naturalPerson);
    if (part.legalEntity) p.legalEntity = new LegalEntity(true, part.legalEntity);
    p.documents = part.documents || [];
    p._id = part._id;
    p.filled = part.filled;
    p.ownershipPercentage = part.ownershipPercentage;

    if (part.hasOwnProperty('parties') && part.parties.length) {
      p.parties = part.parties.map(this.initPart.bind(this));
    }

    return p;
  }

  setActiveParty(partyId: string) {
    this.party = this.findPartiesById(this.root, partyId);
  }

  // TODO Volodymyr Logvinov remove this function
  updatingRegistration(part: Part, index = 0) {
    return this.update().then((response) => {
      if (part.naturalPerson && response[0] && response[0].parties[index] !== undefined) {
        part._id = response[0].parties[index]._id;
      }

      return response;
    });
  }

  update(params?: any): Promise<any> {
    const clone = this.cloneRoot(this.root);

    if (this.identifications) {
      clone.identifications = this.identifications;
    }

    const queryParams = params ? params : {};

    return this.gatewayService.put(`${baseUrlBCOS}/registrations/${clone._id}`, queryParams, clone)
      .then((parties: Array<Part>) => {

        this.updateAllTaskStatuses();

        return parties;
      });
  }

  updateOriginOfAssets(origins: any): Promise<any> {
    return this.gatewayService.put(`${baseUrlBCOS}/registrations/${this.root._id}/originOfAssets`, null, origins)
      .then((result: Array<Part>) => {
        return result;
      });
  }

  setComplexityForBusinessRegistration(value: boolean): Promise<any> {
    const clone = this.cloneRoot(this.root);
    clone.isComplex = typeof value === 'boolean' ? value : undefined;

    return this.gatewayService.put(`${baseUrlBCOS}/registrations/${clone._id}`, {legal_subcompanies: value ? 'yes' : 'no'}, clone)
      .then((parties: Array<Part>) => {
        let registration;

        if (value) {
          registration = clone;
          registration.parties = parties;
        } else {
          // TODO Volodymyr Logvinov fix response for NO complex structure
          registration = clone;
          registration.parties = (<TreeRoot> <unknown> parties).parties;
        }

        this.updateLegalPageStructure(registration);

        this.init(registration);
      });
  }

  updateWithLegalStructure(): Promise<any> {
    const clone = this.cloneRoot(this.root);

    return this.gatewayService.put(`${baseUrlBCOS}/registrations/${clone._id}`, {}, clone)
      .then((parties: Array<Part>) => {
        const registration = clone;

        registration.parties = parties;

        this.updateLegalPageStructure(registration);

        this.init(registration);
      });
  }

  addParty(party) {
    const partiesList = this.isChildAccount ? this.root.parties : this.root.parties[0].parties;
    const partyIndex = partiesList.length;

    partiesList[partyIndex] = party;

    return this.update()
      .then((parties) => {
        const p = this.isChildAccount ? parties[partyIndex] : parties[0].parties[partyIndex];
        partiesList[partyIndex]._id = p._id;

        this.updateAllTaskStatuses();

        return party;
      });
  }

  removeParty(partyId) {
    const parties = this.isChildAccount ? this.root.parties : this.root.parties[0].parties;
    const partyIndex = parties.findIndex((party) => party._id === partyId);
    let params: any = {};

    parties.splice(partyIndex, 1);

    if (this.identifications && this.identifications[partyId]) {
      params = { deletePartyId: partyId };
      this.deleteMITEKResult(partyId);
    }

    return this.update(params)
      .then(() => {
        this.updateAllTaskStatuses();

        this.npCompleted.delete(partyId);
      });
  }

  deleteMITEKResult(partyId: any): any {
    if (this.identifications && this.identifications[partyId]) {
      delete this.identifications[partyId];
    }
  }

  startRegistrationKYC() {
    const params: any = {
      lang: this.localeId,
      action: 'url'
    };

    return this.gatewayService.post(`${baseUrlBCOS}/registrations/kyc/${this.root._id}/start`, params)
      .then((response: any) => {
        this.saveKYC(response);
      });
  }

  startRegistrationSurvey() {
    const params = { action: 'url', registration_id: this.root._id };

    return this.gatewayService.post(`${baseUrlBSUR}surveys/bcos/start`, params);
  }

  getPrevRepresentativeParty(currentRepresentativeId) {
    const repParties = this.root.parties[0].parties;
    this.numberOfRepresentatives = repParties.length;

    for (let i = 0; i < this.numberOfRepresentatives; i++) {
      if (repParties[i]._id === currentRepresentativeId && repParties[i - 1]) {
        return repParties[i - 1];
      }
    }

    return null;
  }

  getNextRepresentativeParty(currentRepresentativeId) {
    const repParties = this.root.parties[0].parties;
    this.numberOfRepresentatives = repParties.length;

    for (let i = 0; i < this.numberOfRepresentatives; i++) {
      if (repParties[i]._id === currentRepresentativeId && repParties[i + 1]) {
        return repParties[i + 1];
      }
    }

    return null;
  }

  findPartiesById(registration: TreeRoot | Part, partyId: string): any {
    for (const party of registration.parties) {
      if (party._id === partyId) {
        return party;
      } else if (party.parties) {
        const p = this.findPartiesById(party, partyId);

        if (p) return p;
      }
    }

    return null;
  }

  findParentPartyById(childPartyId: string, registration: TreeRoot | Part = this.root): Part {
    const parties = registration.parties.filter((party: Part) => party.parties.length);

    let foundParty;
    for (const party of parties) {
      foundParty = party.parties.map((part: Part) => part._id).indexOf(childPartyId) > -1 ?
         party : this.findParentPartyById(childPartyId, party);
      if (foundParty) break;
    }

    return foundParty;
  }

  getMaxOwnershipPercentage(partyId) {
    const party = this.findParentPartyById(partyId);
    if (!party || !party.parties) return 1;
    const currentPercentage = party.parties
      .map((part: Part) => part._id === partyId ? 0 : (part.ownershipPercentage || 0))
      .reduce((accumulator, ownershipPercentage) => accumulator += ownershipPercentage, 0);

    // eslint-disable-next-line no-magic-numbers
    return Math.round((1 - currentPercentage) * 10000) / 10000;
  }

  getAllNaturalPersons(parties: Array<Part>): Array<NaturalPerson> {
    const flatNaturalPersons = parties => parties.reduce((acc, item) =>
      item.parties.length ? [...acc, ...flatNaturalPersons(item.parties)] : [...acc, {...item.naturalPerson, partyId: item._id}], []);

    return flatNaturalPersons(parties);
  }

  getAllLegalRepresentatives(parties: Array<Part>): Array<NaturalPerson> {
    return this.getAllNaturalPersons(parties).filter(({signer, ubo}) => signer || ubo);
  }

  setPartyToRoot(party: Part) {
    return this.setParty(this.root, party);
  }

  setParty(registration: TreeRoot | Part, party: Part): boolean {
    const partyId = party._id;

    for (let i = 0; i < registration.parties.length; i++) {
      if (registration.parties[i]._id === partyId) {

        registration.parties[i] = party;

        return true;
      } else if (party.parties) {
        const success = this.setParty(registration.parties[i], party);

        if (success) return true;
      }
    }

    return false;
  }

  rootClear() {
    this.root = {
      _id: '',
      createdAt: '',
      requestSentDateTime: '',
      updatedAt: '',
      tenant_id: this.tenantId || '',
      agent_id: '',
      type: '',
      status: '',
      parties: [],
      channel: '',
      identifications: {},
      originOfAssets: null
    };

    if (this.root.hasOwnProperty('subType')) {
      delete this.root.subType;
    }

    this.registrationType = '';
    this.registrationStatus = '';

    this.kyc = null;
    this.kycURLs = null;
    this.survey = null;
    this.signing = null;
    this.numberOfRepresentatives = null;
  }

  isRegistrationDoneInUI() {
    const partiesFilled = Array.from(this.npCompleted.values())
      .filter((status) => status !== ValidationStatuses.Done)
      .length === 0;

    if (this.registrationType === <RegistrationType> 'BUSINESS') {
      const isComplex = this.isComplex(this.root);
      const hasSubParties = this.root.parties[0].parties.length > 0;

      return isComplex || hasSubParties && partiesFilled;
    }

    return partiesFilled;
  }

  isAnyPartyStarted(): boolean {
    let partiesValidation: Array<{[name: string]: ValidationStatuses}> = [];
    if (this.root.type === 'BUSINESS') {
      partiesValidation.push(this.validateParty(this.root.parties[0], true));

      this.root.parties[0].parties.forEach((party) => {
        partiesValidation.push(this.validateParty(party, false));
      });
    } else {
      partiesValidation = this.root.parties.map((party, index) => {
        return this.validateParty(party, (index === 0));
      });
    }

    return partiesValidation.some((validation: {[name: string]: ValidationStatuses}): boolean => {
      for (const key in validation) {
        if (validation.hasOwnProperty(key) && validation[key] === ValidationStatuses.NotStarted) return false;
      }

      return true;
    });
  }

  updateAllTaskStatuses() {
    if (this.root.type === 'BUSINESS') {
      const rootParty = this.root.parties[0];
      this.updateTaskStatus(this.getLePageStructure(rootParty._id), rootParty, true);

      const parties = this.root.parties[0].parties;

      parties.forEach((party, index) => {
        if (party.legalEntity) return;
        this.updateTaskStatus(this.getNpPageStructure(party._id || `${  String(index)}`), party, false);
      });

      this.updateLegalPageStructure(this.root);
    } else {
      this.root.parties.forEach((party, index) => {
        this.updateTaskStatus(this.getNpPageStructure(party._id || `${  String(index)}`), party, index === 0);
      });
    }
  }

  updateTaskStatus(tasklist, party, isFirstParty) {
    const statuses = this.validateParty(party, isFirstParty);

    if (tasklist) {
      tasklist.forEach((task) => {
        task.status$.next(statuses[task.id]);
      });
    }

    if (party._id) {
      this.npCompleted.set(party._id, (() => {
        const keys = Object.keys(statuses);
        const filledPartiesLength = Object.keys(statuses).filter(key =>
          statuses[key] === ValidationStatuses.Done).length;
        const inProgressPartiesLength = Object.keys(statuses).filter(key =>
          statuses[key] === ValidationStatuses.InProgress).length;

        if (filledPartiesLength === keys.length) return ValidationStatuses.Done;
        if (inProgressPartiesLength > 0 || filledPartiesLength > 0) return ValidationStatuses.InProgress;

        return ValidationStatuses.NotStarted;
      })());
    }
  }

  isComplex(registration = this.root) {
    if (registration.type !== 'BUSINESS') return false;
    if (registration.isComplex) return true;

    for (const party of registration.parties[0].parties) {
      if (party.legalEntity) return true;
    }

    return false;
  }

  sendMobileVerifyInvite(data: any, id: string): Promise<any> {
    return this.gatewayService.put(
      `${baseUrlBCOS}/registrations/${id}/mitek/${data.mitekPartyId}`,
      { mitekPartyId: data.mitekPartyId, emailLanguage: data.lang },
      data
    );
  }

  startUploadedDocumentIdentificationProcess(data: any, id: string): Promise<any> {
    return this.gatewayService.put(`${baseUrlBCOS}/registrations/${id}/mitek/${data.mitekPartyId}`,
      { mitekPartyId: data.mitekPartyId, documentId1: data.documentId1, documentId2: data.documentId2 }, data);
  }


  getMitekProgress(caseId: string) {
    return webSocket(
      {
        url: `${baseUrlSocket}?caseId=${caseId}&authorization=${encodeURIComponent(TokenHelper.getAccessToken())}`
      });
  }

  private validateParty(party: Part, isFirstParty: boolean): {[name: string]: ValidationStatuses} {
    const type = party.naturalPerson ? 'naturalPerson' : party.legalEntity ? 'legalEntity' : null;

    if (!type) throw new Error('Invalid party structure');

    return {
      basic: type === 'naturalPerson' ? this.validateNaturalPersonBasicDetails(party) : this.validateLegalEntityBasicDetails(party),
      identification: type === 'naturalPerson' ? this.validateNaturalPersonIdentityDetails(party) : ValidationStatuses.Done,
      address: this.validateAddress(party, type),
      additional: this.validateAdditionalDetails(party, type, isFirstParty),
      qualifications: this.validateQualifications(party, type),
      originOfAssets: this.validateOriginOfAssets()
    };
  }

  private validateOriginOfAssets(): ValidationStatuses {

    let status: ValidationStatuses = ValidationStatuses.NotStarted;

    if (this.workflow.originOfAssets && this.workflow.originOfAssets.enabled) {
      if (this.root.originOfAssets && this.root.originOfAssets.length) {
        status = ValidationStatuses.Done;
      }
    } else {
      status = ValidationStatuses.Done;
    }

    return status;
  }

  private validateNaturalPersonBasicDetails(party: Part): ValidationStatuses {
    const keys = ['preferredName', 'lastName', 'locale', 'region', 'phone', 'email'];

    const inValidFieldsKeysLength = keys.filter((key) =>
      (typeof party.naturalPerson[key] !== 'string' || party.naturalPerson[key].length === 0)
    ).length;

    return inValidFieldsKeysLength === keys.length ? ValidationStatuses.NotStarted :
      inValidFieldsKeysLength === 0 ? ValidationStatuses.Done : ValidationStatuses.Done;
  }

  private validateNaturalPersonIdentityDetails(party: Part): ValidationStatuses {
    const documents = party.documents.filter(({type}) => type === 'I' || type === 'P');

    let fields = [];
    const invalidFields = [];

    fields.push('identifications');
    if (!this.identificationSkippedOrManual) {
      if ((this.identifications && this.identifications[party._id] && !this.identifications[party._id].caseId)
        && (!Array.isArray(documents) || documents.length === 0)) {
        invalidFields.push('identifications');
      } else {
        const documentKeys = ['expireAt', 'fileName', 'name', 'number', 'type'];

        for (const doc of documents) {
          fields = fields.concat(documentKeys);

          for (const key of documentKeys) {
            if (typeof doc[key] !== 'string' || doc[key].length === 0) invalidFields.push(key);
          }
        }
      }
    }

    const keys = ['sex', 'initials', 'givenNames', 'lastName', 'locale', 'region',
      'preferredName', 'personalNumber', 'dateOfBirth'];

    fields = fields.concat(keys);
    for (const key of keys) {
      if (typeof party.naturalPerson[key] !== 'string' || party.naturalPerson[key].length === 0) invalidFields.push(key);
    }

    return invalidFields.length ?
      (fields === invalidFields ? ValidationStatuses.NotStarted : ValidationStatuses.InProgress) :
      ValidationStatuses.Done;
  }

  private validateLegalEntityBasicDetails(party: Part): ValidationStatuses {
    const keys = ['chamberOfCommerceNo', 'name', 'locale', 'region'];

    const inValidFieldsKeysLength = keys.filter((key) =>
      (typeof party.legalEntity[key] !== 'string' || party.legalEntity[key].length === 0)
    ).length;

    return inValidFieldsKeysLength === keys.length ? ValidationStatuses.NotStarted :
      inValidFieldsKeysLength === 0 ? ValidationStatuses.Done : ValidationStatuses.Done;
  }

  private validateAddress(party: Part, partyType: string): ValidationStatuses {
    let fields = [];
    const invalidFields = [];

    const addressKeys = ['city', 'country', 'line1', 'postalCode'];

    fields = fields.concat(addressKeys);

    const address = party[partyType].address;

    for (const key of addressKeys) {
      if (typeof address[key] !== 'string' || address[key].length === 0) invalidFields.push(key);
    }

    const connectKeys = ['phone', 'email'];

    fields = fields.concat(connectKeys);

    for (const key of connectKeys) {
      if (typeof party[partyType][key] !== 'string' || party[partyType][key].length === 0) invalidFields.push(key);
    }

    return invalidFields.length ?
      (fields.length === invalidFields.length ? ValidationStatuses.NotStarted : ValidationStatuses.InProgress) :
      ValidationStatuses.Done;
  }

  private validateAdditionalDetails(party: Part, partyType: string, isFirstParty): ValidationStatuses {
    let fields = [];
    const invalidFields = [];

    const taxResidencies = party[partyType].taxResidencies;

    fields.push('taxResidencies');
    if (!Array.isArray(taxResidencies) || taxResidencies.length === 0) {
      invalidFields.push('taxResidencies');
    } else {
      const taxResidenciesKeys = ['country', 'taxIdentificationNumber'];

      for (const tr of taxResidencies) {
        fields = fields.concat(taxResidenciesKeys);

        for (const key of taxResidenciesKeys) {
          if (typeof tr[key] !== 'string' || tr[key].length === 0) invalidFields.push(key);
        }
      }
    }

    if (!isFirstParty) {
      return invalidFields.length ?
        (fields.length === invalidFields.length ? ValidationStatuses.NotStarted : ValidationStatuses.InProgress) :
        ValidationStatuses.Done;
    }

    const keys = ['bankAccount', 'accountHolderName'];
    fields = fields.concat(keys);

    for (const key of keys) {
      if (typeof party[partyType][key] !== 'string' || party[partyType][key].length === 0) invalidFields.push(key);
    }

    return invalidFields.length ?
      (fields.length === invalidFields.length ? ValidationStatuses.NotStarted : ValidationStatuses.InProgress) :
      ValidationStatuses.Done;
  }

  private validateQualifications(party: Part, partyType: string): ValidationStatuses {
    let fields = [];
    const invalidFields = [];

    if (this.workflow.questions.USPersonStatus) fields.push('USPerson');
    if (this.workflow.questions.ShareholderStatus) fields.push('ownMoreThan1Pshare');

    if (partyType === 'naturalPerson' && this.workflow.questions.InsiderStatus) {
      fields.push('insider');
    }

    for (const key of fields) {
      if (typeof party[partyType][key] !== 'boolean') invalidFields.push(key);
    }

    const additionalKeys = [];
    fields = fields.concat(additionalKeys);

    if (party[partyType].insider) additionalKeys.push('companyName');
    if (party[partyType].ownMoreThan1Pshare) additionalKeys.push('shareCompanyName');

    for (const key of additionalKeys) {
      if (typeof party[partyType][key] !== 'string' || party[partyType][key].length === 0) invalidFields.push(key);
    }

    return invalidFields.length ?
      (fields.length === invalidFields.length ? ValidationStatuses.NotStarted : ValidationStatuses.InProgress) :
      ValidationStatuses.Done;
  }

  private cloneRoot(root: any): any {
    const clone =  {
      _id: root._id,
      createdAt: root.createdAt,
      tenant_id: root.tenant_id,
      agent_id: root.agent_id,
      isComplex: root.isComplex,
      status: root.status,
      type: root.type,
      documents: root.documents,
      parties: this.cloneParties(root.parties),
      channel: root.channel
    };

    if (root.hasOwnProperty('subType')) clone['subType'] = root.subType;

    return clone;
  }

  private cloneParties(parties: Array<Part>): Array<any> {
    return parties.map((part: Part) => {
      return {
        _id: part._id,
        naturalPerson: part.naturalPerson,
        legalEntity: part.legalEntity,
        ownershipPercentage: part.ownershipPercentage,
        documents: part.documents,
        parties: this.cloneParties(part.parties)
      };
    });
  }

  private saveKYC(kyc: KYCObject) {
    this.kyc = kyc;

    if (this.kyc.hasOwnProperty('integrationSettings') &&
      this.kyc.integrationSettings.hasOwnProperty('urlsParty') &&
      this.kyc.integrationSettings.urlsParty.length) {
      this.kycURLs = this.kyc.integrationSettings.urlsParty;
    }
  }

  private checkRepresentatives(rootParty: Part) {
    if (rootParty.hasOwnProperty('parties') && rootParty.parties.length) {
      this.numberOfRepresentatives = rootParty.parties.length;
    }
  }

  private checkWorkflowLE() {
    const questions = this.workflow.questions;

    return questions.ShareholderStatus || questions.USPersonStatus;
  }

  private checkWorkflowNP() {
    const questions = this.workflow.questions;

    return questions.ShareholderStatus || questions.USPersonStatus || questions.InsiderStatus;
  }

  private isFirstPartyCheck(partyId): boolean {
    return partyId === this.root.parties[0]['_id'];
  }

  private originOfAssetsEnabled(partyId) {
    return this.isFirstPartyCheck(partyId) && ((this.workflow.originOfAssets && this.workflow.originOfAssets.enabled)
     || (this.root.originOfAssets && this.root.originOfAssets.length));
  }
}
