import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { CustomValidators } from '../../shared/validators/custom.validators';
import { Logger, TitleService } from '../../core/services';

import { RestorePassService } from './restorepass.service';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BcMessagesManager } from '../../widgets/bc.catch-message/bc.messages-manager.service';

@Component({
  selector: 'bc-restorepass2-component',
  templateUrl: 'restorepass2.component.html'
})
export class RestorePass2Component implements OnInit, AfterViewInit {
  verificationCode: string;
  username: string;
  password: string;
  currentYear: number;

  restorePasswordForm: UntypedFormGroup = new UntypedFormGroup({});

  codeDelivery: any = {
    phone: null
  };

  viewChangePass: boolean;
  viewChangeConfPass: boolean;
  @ViewChild('firstCursorField', { static: true }) firstCursorField: ElementRef;

  constructor(public bcMessagesManager: BcMessagesManager,
              private titleService: TitleService,
              public router: Router,
              private usersGateway: UsersGatewayService,
              private toastr: ToastrService,
              public route: ActivatedRoute,
              private logger: Logger) {
    this.currentYear = new Date().getFullYear();

    if (RestorePassService.codeDeliveryDetails.AttributeName === 'phone_number') {
      this.codeDelivery.phone = {
        number: RestorePassService.codeDeliveryDetails.Destination,
        via: RestorePassService.codeDeliveryDetails.DeliveryMedium
      };
    }

    const minPassLength = 8, maxPassLength = 99, mfaLength = 6;
    this.restorePasswordForm = new UntypedFormGroup({
      verificationCode: new UntypedFormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(mfaLength),
      ])),
      newPassword: new UntypedFormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(minPassLength),
        Validators.maxLength(maxPassLength),
        CustomValidators.reqNumber(),
        CustomValidators.reqSpecial(),
        CustomValidators.reqUpperCase(),
        CustomValidators.reqLowerCase()
      ])),
      confirmPassword: new UntypedFormControl(null, Validators.required)
    }, this.checkEquality.bind(this));
  }

  ngAfterViewInit() {
    this.firstCursorField.nativeElement.focus();
  }

  ngOnInit() {
    this.titleService.setTitle('login');
    this.route.params.subscribe((params: any) => {
      this.username = params['username'];
    });
  }

  onSubmit(form: UntypedFormGroup) {
    sessionStorage.setItem('username', this.username);
    this.usersGateway.forgotPasswordConfirm({
      userName: this.username,
      verificationCode: form.value.verificationCode,
      newPassword: form.value.newPassword
    }).pipe(catchError((response: HttpErrorResponse) => {
        this.restorePasswordForm.controls['verificationCode'].setErrors({restoreConfirmCodeWrong: true});
        this.logger.showError(response.error.message);

        return throwError(response);
    }))
    .subscribe(() => {
      this.toastr.success(
        this.bcMessagesManager.translations[`SUCCESS_PASSWORD_UPDATE`], this.bcMessagesManager.translations[`SUCCESS_TITLE`]);
      this.router.navigate(['/login']);
    });
  }

  checkEquality(form: UntypedFormGroup) {
    if (form.controls['newPassword'].value !== form.controls['confirmPassword'].value) {
      this.restorePasswordForm.controls['confirmPassword'].setErrors({confirmPassword: true});

      return {mismatch: true};
    }

    if (this.restorePasswordForm.controls['confirmPassword'] &&
      this.restorePasswordForm.controls['confirmPassword'].hasError('confirmPassword')) {
      this.restorePasswordForm.controls['confirmPassword'].reset(
        this.restorePasswordForm.controls['confirmPassword'].value
      );
    }

    return null;
  }
}
