import { Injectable, Inject } from '@angular/core';
import { BcMessagesManager } from '../../widgets';
import { TRANSLATIONS_HELPER_MANAGER } from '../../translations/translation.injectors';

@Injectable({
  providedIn: 'root'
})
export class TranslationsHelper {

  private _booleans: Array<{ 'name': string, 'value': boolean }> =
    [
      { value: true, name: 'Yes' },
      { value: false, name: 'No' }
    ];

  constructor(@Inject(TRANSLATIONS_HELPER_MANAGER) public bcTranslationsMessagesManager: BcMessagesManager) { }

  setTranslations() {
    const translations = this.bcTranslationsMessagesManager.translations;
    this._booleans[0].name = translations['YES'];
    this._booleans[1].name = translations['NO'];
  }

  get Booleans() { return this._booleans; }

  get translations() { return this.bcTranslationsMessagesManager.translations; }
}
