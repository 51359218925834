import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FocusDirective } from './bc.focus.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FocusDirective
  ],
  exports: [
    FocusDirective
  ]
})
export class BcFocusModule {  }
