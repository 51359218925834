import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsSettingsService {

  constructor(private http: HttpClient) {  }

  get(tenantId: string): Observable<any> {
    if (!tenantId) return of({});

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=integrations`);
  }
}
