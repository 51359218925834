import { Inject, Injectable } from '@angular/core';


import { GatewayService, Logger, RegistrationService, SettingsService } from '../core/services';

import { environment } from '@env/environment';

import { BcMessagesManager } from '../widgets';

import { KYCStatusCodes } from '../shared/models/kycStatus.enum';
import { BINCKBANK_TRANSLATIONS_MANAGER } from '../translations/translation.injectors';
import { RegistrationSubTypes, RegistrationTypes } from '../shared/models/registrationType.enum';
import { getProcessorPageStructure } from './processors.helper';

export const baseUrlBCUS = environment.BASE_URL_BCUS;

export enum CustodianStatuses {
  NOT_SELECTED = 'NOT_SELECTED',
  SELECTED = 'SELECTED',
  EXPORTED = 'EXPORTED',
  SENT = 'SENT',
  PREPARED = 'PREPARED'
}

const StaticProcessors = ['BINCKBANK', 'FRANX', 'SAXO'];
const SurveyProcessors = ['OTHER', 'BINCKBANK_BELGIUM']; // Delete if it won't be needed in future

export enum Modules {
  BINCKBANK = 'BINCKBANK',
  FRANX = 'FRANX',
  SAXO = 'SAXO'
}

@Injectable({
  providedIn: 'root'
})
export class ProcessorsService  {
  processes: Array<ProcessorDetails>;
  surveyProcessors: Array<ProcessorDetails>;

  get kycSubmitted() {
    let kyc = this.registrationService.kyc;
    if (!kyc) kyc = { status: { code: null } };
    // eslint-disable-next-line no-prototype-builtins
    if (!kyc.hasOwnProperty('status')) kyc.status = { code: null };
    const kycCode = kyc.status.code;

    return (kycCode === KYCStatusCodes.Submitted ||
      kycCode === KYCStatusCodes.SubmittedWithWarnings ||
      kycCode === KYCStatusCodes.Rejected);
  }

  get kycFinished() {
    return (!this.settingsService.kycSurveySettingEnable ||
      this.settingsService.kycSurveySettingEnable && this.kycSubmitted);
  }

  constructor(
    @Inject(BINCKBANK_TRANSLATIONS_MANAGER) public binckBankManager: BcMessagesManager,
    private gatewayService: GatewayService,
    private settingsService: SettingsService,
    public bcMessagesManager: BcMessagesManager,
    public registrationService: RegistrationService,
    private logger: Logger
  ) {}

  static getValue(data, field, index): any {
    return data && Array.isArray(data[field]) ? data[field][index] : '';
  }

  resolve(): Promise<any> {
    if (this.processes?.length) {
      return Promise.resolve(this.processes);
    }

    return this.getProcessorsInfo();
  }

  getProcessorsInfo(): Promise<any> {
    const fakeId = '12345';

    return this.gatewayService.get(`${baseUrlBCUS}/products/${fakeId}/processes`)
      .then((res) => {
        this.processes = this.parseStaticProcessors(res);
        this.surveyProcessors = this.parseSurveyProcessors(res);
      })
      .catch(err => this.logger.error(err));
  }

  parseStaticProcessors(response: Array<ProcessorDetails>): Array<ProcessorDetails> {
    const registrationType = <RegistrationSubTypes> this.registrationService.root.subType || <RegistrationTypes> this.registrationService.registrationType;

    return response
      .filter(processor => !!StaticProcessors.find(processorType => processor.processorVendor === processorType))
      .filter((processor: ProcessorDetails) => {
        processor.fields = this.parseCustodianFields(processor.processorVendor, processor.fields);
        processor.pageStructure = getProcessorPageStructure(processor, registrationType);

        return processor.pageStructure.length;
      });
  }

  parseSurveyProcessors(response: Array<ProcessorDetails>): Array<ProcessorDetails> {
    return response
      .filter(processor => processor.surveyDetails && processor.surveyDetails.length)
      .map(processor => {
        processor.surveys = processor.surveyDetails;

        return processor;
      });
  }

  prefillBinckbankForm(surveyId, processId, registrationType) {
    return this.gatewayService.get(`${baseUrlBCUS}/merge/bkyc/${surveyId}/${processId}`)
      .then((res: any) => {
        const fields = {
          planningAmountInvest: res.investmentInitial,
          originOfAssets: []
        };

        if (res.assetOrigin && res.assetOrigin.mappedOrigins && res.assetOrigin.mappedOrigins.length) {
          res.assetOrigin.mappedOrigins.forEach((origin: string) => fields.originOfAssets.push(origin));
        }

        switch (registrationType) {
          case 'JOINT':
            fields['annualGross'] = [res.income, res.incomePartner];
            fields['freeAssets'] = [res.freeToInvest];
            break;
          case 'INDIVIDUAL':
            fields['annualGross'] = [res.income];
            fields['freeAssets'] = [res.freeToInvest];
            break;
          default:
            break;
        }

        return this.updateCustodian(processId, fields);
    });
  }

  isProcessorFinished(processor: ProcessorDetails) {
    return processor.status === CustodianStatuses.EXPORTED ||
      processor.status === CustodianStatuses.SENT ||
      processor.status === CustodianStatuses.PREPARED;
  }

  updateCustodian(processId, fields): Promise<any> {
    const processorIdx = this.processes.findIndex(process => process.processor_id === processId);
    const processor = this.processes[processorIdx];

    const originalFields = {...processor.fields};

    processor.fields = {
      ...processor.fields,
      ...fields
    };

    if (processor.processorVendor === Modules.SAXO) {
      processor.fields = {
        ...processor.fields,
        ...(fields.relationship && { relationship: fields.relationship }),
        ...(fields.investIn12Month && { investIn12Month: fields.investIn12Month }),
        ...(fields.parties && { parties: fields.parties.map((party, i) => {
          return { ...(originalFields?.parties && originalFields.parties[i]), ...party};
        })}),
        ...(fields.company && { company: { ...(originalFields?.company && originalFields.company), ...fields.company } }),
      };
    }

    const rc = {...processor};
    delete rc.settings;
    delete rc.pageStructure;

    if (processor.processorVendor === Modules.BINCKBANK) {
      // eslint-disable-next-line no-prototype-builtins
      const originOfAssets = fields.hasOwnProperty('originOfAssets') ? fields.originOfAssets : originalFields.originOfAssets;

      rc.fields.originOfAssets = Object.keys(originOfAssets).filter(key => originOfAssets[key]);
    }

    return this.gatewayService.put(`${baseUrlBCUS}/products/${processor.product_id}/processes/${processor.processor_id}`, {}, rc)
      .then((res) => {
        this.processes[processorIdx].fields = this.parseCustodianFields(res.processorVendor, res.fields);
      });
  }

  getProcessorById(id: string) {
    return this.processes.find(process => process.processor_id === id);
  }

  getUpdatedProcessorById(processorId: string) {
    const fakeId = '12345';

    return this.gatewayService.get(`${baseUrlBCUS}/products/${fakeId}/processes/${processorId}`);
  }

  getNextPageRoute(pageStructure: Array<NavItem>, currentRoute: string) {
    const currentRouteIdx = pageStructure.findIndex((item) => item.id === currentRoute);

    if (currentRouteIdx === -1 || currentRouteIdx === pageStructure.length - 1) return '/dashboard';

    return pageStructure[currentRouteIdx + 1].route;
  }

  getPrevPageRoute(pageStructure: Array<NavItem>, currentRoute: string) {
    const currentRouteIdx = pageStructure.findIndex((item) => item.id === currentRoute);

    if (currentRouteIdx === -1 || currentRouteIdx === 0) return '/dashboard';

    return pageStructure[currentRouteIdx - 1].route;
  }

  getArrayTranslated(array: Array<any>): Array<any> {
    return array.map(question => {
      return { ...question, name: this.binckBankManager.translations[question.key] };
    });
  }

  private parseCustodianFields(type: string, fields: any = {}) {
    if (type === 'BINCKBANK') {
      if (!Array.isArray(fields.originOfAssets)) {
        fields.originOfAssets = [];
      }

      const originOfAssets = {};

      fields.originOfAssets.forEach((field: any) => {
        if (field) originOfAssets[field] = true;
      });

      fields.originOfAssets = originOfAssets;
    }

    return fields;
  }
}
