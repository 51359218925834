import { Component, EventEmitter } from '@angular/core';
import { BcModalComponent } from '../../../../widgets/bc.modal/bc.modal.component';

@Component({
  selector: 'bc-software-mfa-question-modal',
  templateUrl: './software-mfa-question-modal.component.html',
  styleUrls: [ './software-mfa-question-modal.component.scss' ]
})
export class SoftwareMfaQuestionModalComponent extends BcModalComponent {
  agree = new EventEmitter();
  message: string;
  icon: String;
  constructor() {
    super();
  }

  submit() {
    this.agree.emit('yes');
  }
}
