export const FranxAvailableFields = {
  transactions: ['productType', 'yearlyEstimatedVolume', 'transferCountries', 'marketingChannel'],
  users: ['usersForThePlatform']
};

export const FranxPages = (processorId: string): Array<NavItem> => ([
  {
    id: 'transactions',
    text: $localize `:@@franxTransactions:Transactions details`,
    route: `/processor/${processorId}/franx/transactions`,
  },
  {
    id: 'users',
    text: $localize `:@@franxUsers:Platform users`,
    route: `/processor/${processorId}/franx/users`,
  }
]);

