import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { BcModalService, RegistrationService } from './core/services';
import { IntegrationsService } from './core/services/integrations.service';

import { BcSpinnerService } from './widgets';
import { findGetParameter } from './shared/helpers/helpers';

import { BehaviorSubject } from 'rxjs';

import { environment } from '@env/environment';


@Component({
  selector: 'bc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  spinner: BehaviorSubject<boolean>;
  themeUrl: SafeUrl;

  optionalAuthSetupAvailable = false;

  @ViewChild('modalOutlet', { read: ViewContainerRef, static: true }) bcModalOutlet: ViewContainerRef;
  loadingRouter = true;

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
              private bcModalService: BcModalService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private bcSpinnerService: BcSpinnerService,
              private integrationsService: IntegrationsService,
              private registrationService: RegistrationService) {
    angulartics2GoogleAnalytics.startTracking();

    this.spinner = this.bcSpinnerService.loadChange;
    this.checkTenantDetails();

    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingRouter = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          // this.checkOptionalMFA();  TODO: Hiding optional MFA setup temporary, improve flow later
          this.loadingRouter = false;
          break;
        }
        default: {
          break;
        }
      }

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit() {
    this.bcModalService.set(this.bcModalOutlet);
  }

  checkTenantDetails() {
    const tenantId = findGetParameter('tenant_id');
    const storedTenantId = sessionStorage.getItem('tenant_id');

    if (storedTenantId || tenantId) {
      if ((tenantId && storedTenantId) && (tenantId !== storedTenantId)) {
        localStorage.clear();
      }

      this.registrationService.setTenantId(tenantId || storedTenantId);
      this.themeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.THEME_URL}/${tenantId || storedTenantId}/bcos-web-frontend.css`);
    } else {
      this.router.navigate(['/error'], { queryParams: { error: 'tenant_error' } });
    }

    const redirectLink = findGetParameter('referralUrl') || sessionStorage.getItem('referralUrl') || null;
    if (redirectLink) {
      sessionStorage.setItem('referralUrl', redirectLink);
      this.registrationService.redirectLink = redirectLink;
    }
    this.integrationsService.start();
  }

  checkOptionalMFA() {
    if (sessionStorage.getItem('first-login')) {
      sessionStorage.removeItem('first-login');
      this.optionalAuthSetupAvailable = true;
    }
  }
}
