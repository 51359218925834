import { Injectable } from '@angular/core';


import { GatewayService } from './gateway.service';
import { RegistrationService } from './registration.service';
import { TitleService } from './title.service';

import { environment } from '@env/environment';
import { from } from 'rxjs';

export const baseUrlBCOS = environment.BASE_URL_BCOS;
export const baseUrlBGC = environment.BASE_URL_BGC;

@Injectable({
  providedIn: 'root'
})
export class SettingsService  {
  settings: Settings;

  get signingSettingEnable(): boolean {
    return this.settings.signing.enabled;
  }
  get kycSurveySettingEnable(): boolean {
    return this.settings.kycSurvey.enabled && this.settings.kycSurvey.enabledOnCreateRegistration;
  }

  constructor(private titleService: TitleService,
              private gatewayService: GatewayService,
              private registrationService: RegistrationService) {  }

  resolve(): Promise<Settings> {
    if (this.settings) {
      this.registrationService
        .setIdentificationProvider(this.settings.identification ? this.settings.identification.defaultIdentifier : '');

      return Promise.resolve(this.settings);
    }

    return this.getSettings(this.registrationService.tenantId);
  }

  getAllowedFileTypesForAccept(): string {
    if (!this.settings) return '';

    return this.settings.general.fileTypes.allowed
      .filter(el => el.portal)
      .map(el => el.mimeType)
      .join(',');
  }

  getSettings(tenantId: string): Promise<any> {
    return this.gatewayService.get(`${baseUrlBCOS}/tenants/${tenantId}/settings`).then((settings) => {
      this.settings = settings;

      this.titleService.setTitle(this.settings.general.businessName);
      this.registrationService
        .setIdentificationProvider(this.settings.identification ? this.settings.identification.defaultIdentifier : '');

      return this.settings;
    });
  }

  getRegionSettings(tenantId: string) {
    return from(this.gatewayService.get(`${baseUrlBGC}/tenants/settings/${tenantId}/?type=internationalisation`, {}, {}));
  }
}
