import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SettingsService } from '../services';

@Injectable()
export class SettingsGuard  {

  constructor(private settingsService: SettingsService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return this.settingsService.resolve().then((settings: Settings) => {
        if (JSON.stringify(settings) !== '{}') {

          return true;
        }
        this.router.navigate(['/error']);

        return false;
      }).catch((message: string) => {
        console.error(message);
        this.router.navigate(['/error']);

        return false;
      });
  }
}
