import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RegistrationService } from './registration.service';
import { findGetParameter } from '../../shared/helpers/helpers';

import { environment } from '@env/environment';
import { RegistrationSubTypes, RegistrationTypes } from '../../shared/models/registrationType.enum';

export const baseUrlBgc = environment.BASE_URL_BGC;

export interface PublicTenantInfo {
  tenantId: string;
  tenantName: string;
  allowedRegistrationTypes: Record<RegistrationTypes | RegistrationSubTypes, boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class TenantService  {
  tenantData: PublicTenantInfo;

  constructor(private http: HttpClient, private registrationService: RegistrationService) { }

  resolve(): Promise<PublicTenantInfo> {
    if (this.tenantData) {
      return Promise.resolve(this.tenantData);
    }

    return this.getTenant(this.registrationService.tenantId).toPromise();
  }

  getTenant(tenantId?: string): Observable<PublicTenantInfo> {
    if (!tenantId) {
      tenantId = this.registrationService.tenantId;
    }
    if (!tenantId) {
      const tenantIdFromUri = findGetParameter('tenant_id');
      const storedTenantId = sessionStorage.getItem('tenant_id');
      tenantId = tenantIdFromUri || storedTenantId;
    }

    return this.http.get(`${baseUrlBgc}/tenants/settings/${tenantId}/?type=general&subtype=nameAndRegistrationTypes`)
      .pipe(tap((response: any) => this.tenantData = { tenantId, ...response }));
  }

  getConsentType(tenantId?: any): Observable<any> {
    return this.http.get(`${baseUrlBgc}/tenants/settings/${tenantId}/?type=general&subtype=ppAndGtcUrl`);
  }
}
