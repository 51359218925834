import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationService } from '../services/';


@Injectable({
  providedIn: 'root'
})
export class RegIncompleteGuard  {

  constructor(private registrationService: RegistrationService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.registrationService.isRegistrationComplete) {
        this.router.navigate(['/dashboard']);

        reject(false);
      } else {
        resolve(true);
      }
    });
  }
}
