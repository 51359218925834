<div class="bc-new-modal modal-dialog modal-dialog_small">
	<div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button"
              class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 *ngIf="title" class="modal-title">{{ title }}</h3>
      <h3 *ngIf="!title" i18n="@@areYouSure" class="modal-title">Are you sure?</h3>
    </div>
    <div class="modal-body">
      <p *ngFor="let question of questions">{{ question }}</p>
    </div>

    <div class="modal-footer buttons-flex-end">
      <button (click)="onNo($event)"
              [class]="'btn bc-btn bc-btn_small ' + buttonsSetup?.rejectionBtnStyle">{{ buttonsSetup?.rejectionText }}</button>
      <button (click)="onYes($event)"
              [class]="'btn bc-btn bc-btn_small ' + buttonsSetup?.confirmationBtnStyle">{{ buttonsSetup?.confirmationText }}</button>
    </div>
  </div>
</div>
