import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BcLocalizedNumbersService } from '../services';
import parsePhoneNumber, { isPossiblePhoneNumber } from 'libphonenumber-js/max';
const IBAN = require('iban');
const LEI = require('lei-code-validator');

@Injectable({
  providedIn: 'root',
})
export class CustomValidators {

  constructor(private localizeNumbersService: BcLocalizedNumbersService) { }

  static minNumber(min: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return (value >= min) ? null : {minNumber: {min}};
    };
  }

  static maxNumber(max: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return (value <= max) ? null : {maxNumber: {max}};
    };
  }

  static reqNumber(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return /\d/.test(value) ? null : {reqNumber: control.value};
    };
  }

  static reqSpecial(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const regex = new RegExp('[\\^\\$\\*\\.\\[\\]\\{\\}\\(\\)\\?\\-\\“\\!\\@\\#\\%\\&\\/\\,\\>\\<\\‘\\:\\;\\|\\_\\~\\`]');

      return regex.test(value) ? null : {reqSpecial: control.value};
    };
  }

  static reqUpperCase(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return /[A-Z]/.test(value) ? null : {reqUpperCase: control.value};
    };
  }

  static reqLowerCase(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return /[a-z]/.test(value) ? null : {reqLowerCase: control.value};
    };
  }

  static wrongPassword(value: boolean): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      return value ? null : {confirmPassword: control.value};
    };
  }

  static reqEmail(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      /* eslint-disable */
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable */

      return emailRegex.test(value) ? null : {reqEmail: control.value};
    };
  }

  static validateMobilePhone(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const length = value ? value.length : 0;
      const mobileStringLength = 6;

      if (!isRequired && length === 0) {
        return null;
      }

      if ((!value || value.length < mobileStringLength) && isRequired) {
        return {reqMobilePhone: control.value};
      }

      const phoneNumber = parsePhoneNumber(value);

      if (!phoneNumber) return {reqMobilePhone: control.value};

      const numberType = phoneNumber.getType();
      const isPossible = isPossiblePhoneNumber(value);

      return (isPossible && (numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE')) ? null : {reqMobilePhone: control.value};
    };
  }

  static validateLandOrMobilePhone(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;
      const mobileStringLength = 6;

      if (!isRequired && length === 0) {
        return null;
      }

      if (value === null || value.length < mobileStringLength) {
        return {reqMobilePhone: control.value};
      }

      const phoneNumber = parsePhoneNumber(value);

      if (!phoneNumber) return {reqLandOrMobilePhone: control.value};

      const numberType = phoneNumber.getType();
      const isPossible = isPossiblePhoneNumber(value);

      return (numberType === 'FIXED_LINE' ||
        numberType === 'FIXED_LINE_OR_MOBILE' ||
        numberType === 'MOBILE' ||
        numberType === 'VOIP') &&
      isPossible ? null : {reqLandOrMobilePhone: control.value};
    };
  }

  static optionalDate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

      return ((value && dateRegex.test(value)) || (!value)) ? null : {optionalDate: control.value};
    };
  }

  static validateAlphanumeric(controlName?: string, isRequired: boolean = true, allowSpaces: boolean = false): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      const alphanumericRegex = allowSpaces
        ? /^[a-z0-9 ]+$/i
        : /^[a-z0-9]+$/i;

      let errorObject: any =  {alphanumeric: control.value};

      if (controlName === 'TIN_NP') errorObject = {alphanumericTINNP: control.value};
      if (controlName === 'TIN_LE') errorObject = {alphanumericTINLE: control.value};
      if (controlName === 'BAN') errorObject = {alphanumericBAN: control.value};

      return alphanumericRegex.test(value) ? null : errorObject;
    };
  }

  static requiredOptional(checkboxState: boolean): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      return (value && checkboxState) ? null : {required: control.value};
    };
  }

  static validateIBAN(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      return IBAN.isValid(value) ? null : {invalidIBAN: control.value};
    };
  }

  static optionalLEI(isRequired: boolean = false): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      return LEI.isValidLei(value) ? null : {invalidLEI: control.value};
    };
  }

  static validateSignerOrUbo(ubo: AbstractControl, signer: AbstractControl) {
    if (!ubo.value && !signer.value) {
      signer.setErrors({ signerOrUbo: true });
    } else {
      signer.setErrors(null);
    }
  }

  maxPercent(max: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      /* eslint-disable-next-line no-magic-numbers */
      const value = this.containValue(control.value) ? this.localizeNumbersService.fetchValueFromPercentView(control.value, 4) : 0;

      // eslint-disable-next-line no-magic-numbers
      return value <= max ? null : {maxNumber: {max: `${this.localizeNumbersService.convertValueToPercentView(max, 2)}%`}};
    };
  }

  private containValue(value: string | null) {
    return value !== null && (typeof value === 'string' && value.length > 0);
  }
}
