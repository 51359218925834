import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';

import { EMPTY, from, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MFAType, TokenHelper, UsersGatewayService } from '@blancoservices/bc-auth-web';

import { SettingsService, UserService, UserLoginService, Logger } from '../../../services';
import { BcSpinnerService } from '../../../../widgets';

@Component({
  selector: 'bc-obligatory-mfa-setup',
  templateUrl: './obligatory-mfa-setup.component.html',
  styleUrls: ['./obligatory-mfa-setup.component.scss']
})
export class ObligatoryMfaSetupComponent implements OnInit {
  qrCode$: Observable<string>;
  confirmMFACode = new FormControl(null, Validators.compose([
    Validators.required,
    Validators.maxLength(6)
  ]));

  constructor(
    public router: Router,
    private usersGatewayService: UsersGatewayService,
    private toastr: ToastrService,
    private logger: Logger,
    private settingsService: SettingsService,
    private userService: UserService,
    private bcSpinnerService: BcSpinnerService,
  ) { }

  ngOnInit(): void {
    if (!TokenHelper.isAccesTokenValid()) this.router.navigate(['login']);

    this.qrCode$ = this.getMFACodeSettings();
  }

  getMFACodeSettings(): Observable<string> {
    return this.usersGatewayService.associateSoftwareMFA().pipe(
      map(({ SecretCode }) => {
        const business = this.settingsService.settings.general.businessName;
        const username = this.userService.user.value.username;

        return `otpauth://totp/${username}?secret=${SecretCode}&digits=6&issuer=${encodeURI(business)}`;
      })
    );
  }

  submit() {
    if (this.confirmMFACode.invalid) return;

    this.bcSpinnerService.set(true);

    const userCode = this.confirmMFACode.value;

    this.usersGatewayService.verifySoftwareMFA(userCode).pipe(
      catchError((response: HttpErrorResponse) => {
        const errorType = response.error.errorType;
        const message = response.error.errorMessage;

        switch (errorType) {
          case 'EnableSoftwareTokenMFAException':
            this.confirmMFACode.setErrors({ invalidConfirmCode: true });
            break;
          default:
            this.logger.showError(message);
        }

        this.bcSpinnerService.set(false);

        return EMPTY;
      }),
      switchMap(() => this.usersGatewayService.setMFAPreference(MFAType.SOFTWARE)),
      switchMap(() => from(this.userService.updateUser()))
    ).subscribe(() => {
      this.toastr.success($localize `:@@success:Success!`);
      this.bcSpinnerService.set(false);
      this.router.navigate([UserLoginService.redirectUser()]);
    });
  }
}
