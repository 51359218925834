import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';

import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  constructor(public http: HttpClient) { }

  get(path: string, params = {}, body = {}): Promise<any> {
    return this.request('Get', path, params, body);
  }

  post(path: string, params = {}, body = {}): Promise<any> {
    return this.request('Post', path, params, body);
  }

  put(path: string, params = {}, body = {}): Promise<any> {
    return this.request('Put', path, params, body);
  }

  delete(path: string, params = {}, body = {}): Promise<any> {
    return this.request('Delete', path, params, body);
  }

  private request(verb: string, url: string, params: any, body: any): Promise<any> {
    const request = new HttpRequest(verb.toUpperCase(), url, body,
      {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
        }),
        params: new HttpParams({fromObject: params})
      });

    return this.http.request(request)
      .pipe(pluck('body'))
      .toPromise();
  }
}
