import { Component, Inject } from '@angular/core';
import { BcMessagesManager } from '../../widgets';
import { Countries } from './country.service';
import { COUNTRIES_MESSAGES_MANAGER } from '../translation.injectors';

@Component({
  selector: 'bc-countries-translations',
  templateUrl: 'countries-translations.component.html'
})
export class CountriesTranslationsComponent {
  constructor(
    private countries: Countries,
    @Inject(COUNTRIES_MESSAGES_MANAGER) public bcCountriesMessagesManager: BcMessagesManager
  ) { }

  setupCountries() {
    this.countries.setCountries(this.bcCountriesMessagesManager.translations);
  }
}
