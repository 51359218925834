import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SignupService } from '../signup.service';


@Injectable()
export class SignupGuard  {

  constructor(private router: Router,
              private signupService: SignupService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.signupService.registrationType) {
      this.router.navigate(['/account-type']);

      return false;
    }

    return true;
  }
}
