import { Component } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';

import { findGetParameter } from '../../../shared/helpers/helpers';

import { timer as observableTimer, Observable } from 'rxjs';

import { tap, take, map } from 'rxjs/operators';

@Component({
  selector: 'bc-thankyou',
  templateUrl: './thankyou.component.html'
})
export class ThankYouComponent {
  redirectLink: string;
  timerValue: Observable<number>;

  constructor(public registrationService: RegistrationService) {
    this.redirectLink = findGetParameter('referralUrl') || sessionStorage.getItem('referralUrl') || null;
    const timerLength = 10;
    this.timerValue = this.createTimer(timerLength);
  }

  private createTimer(start: number) {
    const timerValue = 1000;

    return observableTimer(0, timerValue).pipe(
      map(i => start - i),
      take(start + 1),
      tap(i => {
        if (i === 0) {
          window.location.href = this.redirectLink;
        }
      })
    );
  }
}
