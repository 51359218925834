<div class="container">
  <div class="row heading_row content_row"></div>

  <div class="row content_row">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <div class="error__wrap text-center">
        <svg class="error__icon" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 100 100" width="100" height="100">
          <style>
            .st0{fill:#fff;stroke:currentColor;stroke-miterlimit:10}
          </style>
          <defs>
            <path id="SVGID_1_" d="M31.1 168.1h34.1"/>
            <path id="SVGID_2_" d="M65.2 174H31.1"/>
            <path id="SVGID_5_" d="M-200.7-14.2h492.9v259.4h-492.9z"/>
            <path id="SVGID_7_" d="M31.1 156.3h34.1"/>
            <path id="SVGID_8_" d="M65.2 162.1H31.1"/>
            <path id="SVGID_11_" d="M-200.7-22.1h492.9v259.4h-492.9z"/>
            <path id="SVGID_13_" d="M31.1 144.4h34.1"/>
            <path id="SVGID_14_" d="M65.2 150.3H31.1"/>
            <path id="SVGID_17_" d="M-200.7-29.9h492.9v259.4h-492.9z"/>
            <path id="SVGID_19_" d="M31.1 132.6h34.1"/>
            <path id="SVGID_20_" d="M65.2 138.5H31.1"/>
            <path id="SVGID_23_" d="M-200.7-37.7h492.9v259.4h-492.9z"/>
          </defs>
          <path class="st0" d="M50.1 10c-2 0-4 1.2-5.1 3.1L10.7 80.9c-.9 1.9-.9 4.3 0 6 1.1 1.9 2.9 3.1 4.9 3.1h68.7c2 0 4-1.2 4.9-3.1 1.1-1.9 1.1-4.3 0-6L54.8 13.1c-1.1-1.8-2.4-3.1-4.7-3.1z"/>
          <path class="st0" d="M52.5 76c0 1-.7 1.9-1.8 1.9h-3.1c-.9 0-1.8-.7-1.8-1.9v-3.1c0-1 .7-1.9 1.8-1.9h3.1c.9 0 1.8.7 1.8 1.9V76zm.2-11.7c0 1-.9 1.9-1.8 1.9h-3.3c-.9 0-1.8-.7-1.8-1.9l-.9-22.9c0-1 .7-1.9 1.6-1.9h5.1c.9 0 1.8.7 1.6 1.9l-.5 22.9z"/>
          <clipPath id="SVGID_3_">
            <use xlink:href="#SVGID_1_" overflow="visible"/>
          </clipPath>
          <clipPath id="SVGID_4_" clip-path="url(#SVGID_3_)">
            <use xlink:href="#SVGID_2_" overflow="visible"/>
          </clipPath>
          <g clip-path="url(#SVGID_4_)">
            <clipPath id="SVGID_6_">
              <use xlink:href="#SVGID_5_" overflow="visible"/>
            </clipPath>
          </g>
          <use xlink:href="#SVGID_1_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_2_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>
          <clipPath id="SVGID_9_">
            <use xlink:href="#SVGID_7_" overflow="visible"/>
          </clipPath>
          <clipPath id="SVGID_10_" clip-path="url(#SVGID_9_)">
            <use xlink:href="#SVGID_8_" overflow="visible"/>
          </clipPath>
          <g clip-path="url(#SVGID_10_)">
            <clipPath id="SVGID_12_">
              <use xlink:href="#SVGID_11_" overflow="visible"/>
            </clipPath>
          </g>
          <use xlink:href="#SVGID_7_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_8_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_13_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/>
          <use xlink:href="#SVGID_14_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/>
          <clipPath id="SVGID_15_">
            <use xlink:href="#SVGID_13_" overflow="visible"/>
          </clipPath>
          <clipPath id="SVGID_16_" clip-path="url(#SVGID_15_)">
            <use xlink:href="#SVGID_14_" overflow="visible"/>
          </clipPath>
          <g clip-path="url(#SVGID_16_)">
            <use xlink:href="#SVGID_17_" overflow="visible" fill="#fff"/>
            <clipPath id="SVGID_18_">
              <use xlink:href="#SVGID_17_" overflow="visible"/>
            </clipPath>
            <use xlink:href="#SVGID_17_" overflow="visible" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
          </g>
          <use xlink:href="#SVGID_13_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_14_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_19_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/>
          <use xlink:href="#SVGID_20_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/>
          <clipPath id="SVGID_21_">
            <use xlink:href="#SVGID_19_" overflow="visible"/>
          </clipPath>
          <clipPath id="SVGID_22_" clip-path="url(#SVGID_21_)">
            <use xlink:href="#SVGID_20_" overflow="visible"/>
          </clipPath>
          <g clip-path="url(#SVGID_22_)">
            <use xlink:href="#SVGID_23_" overflow="visible" fill="#fff"/>
            <clipPath id="SVGID_24_">
              <use xlink:href="#SVGID_23_" overflow="visible"/>
            </clipPath>
            <use xlink:href="#SVGID_23_" overflow="visible" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
          </g>
          <use xlink:href="#SVGID_19_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
          <use xlink:href="#SVGID_20_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="currentColor" stroke-miterlimit="10"/>
        </svg>

        <h2 class="error__header h2-header h2-header_charcoal" i18n>
          Something went wrong.
        </h2>

        <ng-container [ngSwitch]="errorType">
          <p class="error__text main-text main-text_warm-grey"
             i18n="@@tenantError" *ngSwitchCase="'tenant_error'">It seems that you are using a broken link.</p>
          <p class="error__text main-text main-text_warm-grey"
             i18n="@@regError" *ngSwitchCase="'reg_error'">The registration contains errors.</p>
          <!--TODO: [yana] add proper message for not client role-->
          <p class="error__text main-text main-text_warm-grey"
             i18n="@@profileError" *ngSwitchCase="'profile_error'">Profile error</p>
        </ng-container>

        <p class="error__text main-text main-text_warm-grey"
           i18n="@@contactManager" *ngIf="errorType !== 'profile_error'">Please contact us for further assistance.</p>
      </div>
    </div>
  </div>
</div>
