import { Component, OnInit } from '@angular/core';
import { SoftwareMfaQuestionModalComponent } from '../software-mfa-question/software-mfa-question-modal.component';
import { SoftwareMfaRegisterModalComponent } from '../software-mfa-register/software-mfa-register-modal.component';

import { BcModalService } from '../../../services';
import { MFAType, UsersGatewayService } from '@blancoservices/bc-auth-web';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'bc-optional-mfa-setup',
  template: ''
})
export class OptionalMfaSetupComponent implements OnInit {

  constructor(
    private usersGatewayService: UsersGatewayService,
    private bcModalService: BcModalService
  ) {}

  ngOnInit(): void {
    const modal = this.bcModalService.openModalBox(SoftwareMfaQuestionModalComponent);

    modal.instance.agree.subscribe(() => {
      modal.destroy();
      const registerModal = this.bcModalService.openModalBox(SoftwareMfaRegisterModalComponent);

      registerModal.instance.agree.pipe(
        switchMap(() => this.usersGatewayService.setMFAPreference(MFAType.SOFTWARE))
      ).subscribe(() => { registerModal.destroy(); });

      registerModal.instance.close.subscribe(() => {
        registerModal.destroy();
      });
    });

    modal.instance.close.subscribe(() => {
      modal.destroy();
    });
  }
}
