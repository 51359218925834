import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterGuard, GUARDS } from './core/guards';
import { ThankYouComponent } from './core/components/thankyou/thankyou.component';

import { ErrorComponent } from './core/components/error-page/error-page.component';
import { AccountDeletedComponent } from './core/components/account-deleted/account-deleted.component';
import { LoggedOutComponent } from './core/components/logged-out-page/logged-out-page.component';
import {
  ObligatoryMfaSetupComponent
} from './core/components/software-mfa/obligatory-mfa-setup/obligatory-mfa-setup.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'natural-person/:partyId',
    loadChildren:  () => import('./natural-person/natural-person.module').then(m => m.NaturalPersonModule)
  },
  {
    path: 'representatives',
    loadChildren:  () => import('./representatives/representatives.module').then(m => m.RepresentativesModule),
  },
  {
    path: 'legal-entity/:partyId',
    loadChildren: () => import('./legal-entity/legal-entity.module').then(m => m.LegalEntityModule),
  },
  {
    path: 'structure',
    loadChildren:  () => import('./legal-structure/legal-structure.module').then(m => m.LegalStructureModule),
  },
  {
    path: 'kyc', loadChildren: () => import('./kyc/kyc.module').then(m => m.KycModule)
  },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.MFA_APP_SETUP,
        GUARDS.REGGUARD,
        GUARDS.SURVEYGUARD
      ]
    },
    path: 'survey', loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule)
  },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.MFA_APP_SETUP,
        GUARDS.REGGUARD,
        GUARDS.PROCESSOR_SURVEY
      ],
      pageId: 'processor'
    },
    path: 'processor-survey/:id',
    loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'signing', loadChildren: () => import('./signing/signing.module').then(m => m.SigningModule),
  },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.MFA_APP_SETUP,
        GUARDS.REGGUARD,
      ]
    },
    path: 'thank-you', component: ThankYouComponent
  },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.MFA_APP_SETUP,
        GUARDS.REGGUARD,
      ]
    },
    path: 'account-inactive', component: AccountDeletedComponent
  },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.MFA_APP_SETUP,
        GUARDS.REGGUARD,
        GUARDS.PROCESSOR
      ]
    },
    path: 'processor/:processorId',
    children: [
      {path: '', redirectTo: 'binckbank', pathMatch: 'full'},
      {
        path: 'binckbank',
        loadChildren: () => import('./custodian/binckbank/binckbank.module').then(m => m.BinckbankModule)
      },
      {
        path: 'nnek',
        loadChildren: () => import('./custodian/nnek/nnek.module').then(m => m.NnekModule)
      },
      {
        path: 'franx',
        loadChildren: () => import('./custodian/franx/franx.module').then(m => m.FranxModule)
      },
      {
        path: 'saxo',
        loadChildren: () => import('./custodian/saxo/saxo.module').then(m => m.SaxoModule)
      }
    ]
  },
  { path: 'profile-settings', loadChildren: () => import('./profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule) },
  { path: 'error', component: ErrorComponent },
  { path: 'logged-out', component: LoggedOutComponent },
  {
    canActivate: [MasterGuard],
    data: {
      guards: [
        GUARDS.AUTHGUARD,
        GUARDS.SETTINGS,
        GUARDS.ENFORCED_MFA_SETUP
      ]
    },
    path: 'mfa-setup',
    component: ObligatoryMfaSetupComponent
  },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
