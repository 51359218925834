/* eslint-disable */
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { BcErrorHandler } from '../common/bugsnag/bc-error-handler.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class Logger {

  constructor(private toastr: ToastrService, private bcErrorHandler: BcErrorHandler) {  }

  log(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst') {
      const msg = title ? title + ': ' + message : message;
      console.log(msg);
    }
  }

  info(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst') {
      const msg = title ? title + ': ' + message : message;
      console.info(msg);
    }
  }

  warn(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst') {
      const msg = title ? title + ': ' + message : message;
      console.warn(msg);
    }
  }

  error(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst') {
      const msg = title ? title + ': ' + message : message;
      console.error(msg);
    }
    this.bcErrorHandler.reportHandledError(message);
  }

  showError(error: any) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst') {
      console.log('-------------------------------------------------');
      console.error('error: ', error);
      console.log('-------------------------------------------------');
      this.toastr.error(error ? error.message || error.errorMessage || error : null, 'Error!');
    } else {
      this.toastr.error(error ? error.message || error.errorMessage || error : 'Something went wrong', 'Error!');
    }
    this.bcErrorHandler.reportHandledError(error ? error.message || error.errorMessage || error : null);
  }
}
